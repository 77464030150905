import { merge } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./ui-settings-api.service";
import * as i2 from "../+state/ui-settings-grid-cache.store";
import * as i3 from "../+state/ui-settings-grid-cache.query";
export let UiSettingsQueueService = /*@__PURE__*/ (() => {
    class UiSettingsQueueService {
        constructor(_uiSettingsApiService, _uiSettingsGridCacheStore, _uiSettingsGridCacheQuery) {
            this._uiSettingsApiService = _uiSettingsApiService;
            this._uiSettingsGridCacheStore = _uiSettingsGridCacheStore;
            this._uiSettingsGridCacheQuery = _uiSettingsGridCacheQuery;
            this._start();
        }
        invoke(data) {
            this._uiSettingsGridCacheStore.addCache({ [data.grid_id]: data.grid_settings });
            this._startRequest();
        }
        _startRequest() {
            if (this._inProgress) {
                return;
            }
            if (this._timer) {
                clearTimeout(this._timer);
            }
            this._timer = setTimeout(() => {
                this._start();
            }, 15000);
        }
        _start() {
            const cache = this._uiSettingsGridCacheQuery.getCache();
            const payload = this._uiSettingsGridCacheQuery.getPayload();
            const dataPayload = this._prepareDataPayload(cache, payload);
            if (!dataPayload || !Object.keys(dataPayload).length) {
                return;
            }
            this._inProgress = true;
            this._uiSettingsGridCacheStore.addPayload(cache);
            this._uiSettingsGridCacheStore.removeCache();
            const body = Object.keys(dataPayload).map((key) => {
                return {
                    grid_id: key,
                    ui_settings: dataPayload[key],
                };
            });
            this._uiSettingsApiService
                .saveGridSettings(body)
                .pipe(finalize(() => {
                this._inProgress = false;
            }))
                .subscribe(() => {
                this._uiSettingsGridCacheStore.removePayload();
                this._startRequest();
            }, () => {
                this._uiSettingsGridCacheStore.addCache(dataPayload);
                this._uiSettingsGridCacheStore.removePayload();
                this._startRequest();
            });
        }
        _prepareDataPayload(cache, payload) {
            const data = merge(cache, payload);
            return Object.keys(data).reduce((acc, item) => {
                if (Object.keys(data[item] || {}).length) {
                    acc[item] = data[item];
                }
                return acc;
            }, {});
        }
    }
    UiSettingsQueueService.ɵfac = function UiSettingsQueueService_Factory(t) { return new (t || UiSettingsQueueService)(i0.ɵɵinject(i1.UiSettingsApiService), i0.ɵɵinject(i2.UiSettingsGridCacheStore), i0.ɵɵinject(i3.UiSettingsGridCacheQuery)); };
    UiSettingsQueueService.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsQueueService, factory: UiSettingsQueueService.ɵfac });
    return UiSettingsQueueService;
})();

