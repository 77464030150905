import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import * as timeZonesList from '@ho/mocks/time-zones.json';
import { DateTime } from 'luxon';
import * as i0 from "@angular/core";
import * as i1 from "@ho/shared/data-access/session";
export let TimezonesService = /*@__PURE__*/ (() => {
    class TimezonesService {
        constructor(environment, _sessionUserTypeService, _sessionQuery) {
            this.environment = environment;
            this._sessionUserTypeService = _sessionUserTypeService;
            this._sessionQuery = _sessionQuery;
            this.timeZonesList = timeZonesList.default;
            this.timeZonesList.map((zone) => {
                //actualize offset
                zone.offset = DateTime.local().setZone(zone.name).toFormat('ZZ');
                zone.label = `${zone.name} (${zone.offset})`;
                return zone;
            });
        }
        getTimeZoneOffset() {
            return this.timeZonesList.find((x) => {
                var _a;
                if (localStorage.getItem('session_timezone')) {
                    return x.name === localStorage.getItem('session_timezone');
                }
                else {
                    return x.name === (((_a = this._sessionQuery.userInfo()) === null || _a === void 0 ? void 0 : _a.time_zone) || 'UTC');
                }
            }).offset;
        }
        getTimeZoneName() {
            return this.timeZonesList.find((x) => {
                var _a;
                if (localStorage.getItem('session_timezone')) {
                    return x.name === localStorage.getItem('session_timezone');
                }
                else {
                    return x.name === (((_a = this._sessionQuery.userInfo()) === null || _a === void 0 ? void 0 : _a.time_zone) || 'UTC');
                }
            }).name;
        }
    }
    TimezonesService.ɵfac = function TimezonesService_Factory(t) { return new (t || TimezonesService)(i0.ɵɵinject(AppEnvironment), i0.ɵɵinject(i1.SessionUserTypeService), i0.ɵɵinject(i1.SessionQuery)); };
    TimezonesService.ɵprov = i0.ɵɵdefineInjectable({ token: TimezonesService, factory: TimezonesService.ɵfac, providedIn: 'root' });
    return TimezonesService;
})();

