import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var BounceReason = /*@__PURE__*/ (function (BounceReason) {
    BounceReason[BounceReason["InvalidBankDetails"] = 0] = "InvalidBankDetails";
    BounceReason[BounceReason["IncorrectAccountDetails"] = 1] = "IncorrectAccountDetails";
    BounceReason[BounceReason["IncorrectBeneficiaryDetails"] = 2] = "IncorrectBeneficiaryDetails";
    BounceReason[BounceReason["IncorrectAmount"] = 3] = "IncorrectAmount";
    BounceReason[BounceReason["Fraud"] = 4] = "Fraud";
    BounceReason[BounceReason["Other"] = 5] = "Other";
    return BounceReason;
})({});
export const BounceReasonMap = new Map([
    [BounceReason.InvalidBankDetails, 'Global.Options.BounceReason.InvalidBankDetails'],
    [BounceReason.IncorrectAccountDetails, 'Global.Options.BounceReason.IncorrectAccountDetails'],
    [BounceReason.IncorrectBeneficiaryDetails, 'Global.Options.BounceReason.IncorrectBeneficiaryDetails'],
    [BounceReason.IncorrectAmount, 'Global.Options.BounceReason.IncorrectAmount'],
    [BounceReason.Fraud, 'Global.Options.BounceReason.Fraud'],
    [BounceReason.Other, 'Global.Options.BounceReason.Other'],
]);
export let BounceReasonService = /*@__PURE__*/ (() => {
    class BounceReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = BounceReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    BounceReasonService.ɵfac = function BounceReasonService_Factory(t) { return new (t || BounceReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    BounceReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: BounceReasonService, factory: BounceReasonService.ɵfac });
    return BounceReasonService;
})();

