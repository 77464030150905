import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var DrillDownReportType = /*@__PURE__*/ (function (DrillDownReportType) {
    DrillDownReportType["UniqueClicks"] = "unique clicks";
    DrillDownReportType["Clicks"] = "clicks";
    DrillDownReportType["Leads"] = "leads";
    DrillDownReportType["Sales"] = "sales";
    DrillDownReportType["OptIns"] = "opt-in";
    DrillDownReportType["Impressions"] = "impression_count";
    DrillDownReportType["Visits"] = "visits";
    DrillDownReportType["BadQualityTraffic"] = "bad_quality_traffic";
    return DrillDownReportType;
})({});
export const DrillDownReportTypeMap = new Map([
    [DrillDownReportType.UniqueClicks, 'Global.Options.DrillDownReportType.UniqueClicks'],
    [DrillDownReportType.Clicks, 'Global.Options.DrillDownReportType.Clicks'],
    [DrillDownReportType.Leads, 'Global.Options.DrillDownReportType.Leads'],
    [DrillDownReportType.Sales, 'Global.Options.DrillDownReportType.Sales'],
    [DrillDownReportType.OptIns, 'Global.Options.DrillDownReportType.OptIns'],
    [DrillDownReportType.Impressions, 'Global.Options.DrillDownReportType.Impressions'],
    [DrillDownReportType.BadQualityTraffic, 'Global.Options.DrillDownReportType.BadQualityTraffic'],
]);
export let DrillDownReportTypeService = /*@__PURE__*/ (() => {
    class DrillDownReportTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = DrillDownReportTypeMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    DrillDownReportTypeService.ɵfac = function DrillDownReportTypeService_Factory(t) { return new (t || DrillDownReportTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    DrillDownReportTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: DrillDownReportTypeService, factory: DrillDownReportTypeService.ɵfac });
    return DrillDownReportTypeService;
})();

