import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./settings-api.service";
import * as i2 from "ngx-permissions";
export var RegistrationType = /*@__PURE__*/ (function (RegistrationType) {
    RegistrationType[RegistrationType["Open"] = 1] = "Open";
    RegistrationType[RegistrationType["Moderated"] = 2] = "Moderated";
    RegistrationType[RegistrationType["Closed"] = 3] = "Closed";
    return RegistrationType;
})({});
export var SystemSettingsConfirm = /*@__PURE__*/ (function (SystemSettingsConfirm) {
    SystemSettingsConfirm[SystemSettingsConfirm["Confirm"] = 1] = "Confirm";
    return SystemSettingsConfirm;
})({});
export const RegistrationTypeMap = new Map([
    [RegistrationType.Open, 'Global.Options.RegistrationType.Open'],
    [RegistrationType.Moderated, 'Global.Options.RegistrationType.Moderated'],
    [RegistrationType.Closed, 'Global.Options.RegistrationType.Closed'],
]);
export var RegistrationTypePermissions = /*@__PURE__*/ (function (RegistrationTypePermissions) {
    RegistrationTypePermissions["Open"] = "UI_Registration_Type_Open";
    RegistrationTypePermissions["Moderated"] = "UI_Registration_Type_Moderated";
    RegistrationTypePermissions["Closed"] = "UI_Registration_Type_Closed";
    return RegistrationTypePermissions;
})({});
export const RegistrationTypePermissionsMap = new Map([
    [RegistrationType.Open, RegistrationTypePermissions.Open],
    [RegistrationType.Moderated, RegistrationTypePermissions.Moderated],
    [RegistrationType.Closed, RegistrationTypePermissions.Closed],
]);
export const GENERAL_SETTINGS_SECTION = 'general-settings';
export const DEFAULT_REFERRAL_PROGRAM_SECTION = 'default-referral-program';
export let SettingsService = /*@__PURE__*/ (() => {
    class SettingsService {
        constructor(_settingsApiService, _ngxPermissionsService) {
            this._settingsApiService = _settingsApiService;
            this._ngxPermissionsService = _ngxPermissionsService;
            this.system$ = new BehaviorSubject({});
        }
        get settings() {
            return this._system;
        }
        system() {
            return this._settingsApiService.system().pipe(tap((r) => {
                this._system = r;
                this.system$.next(this._system);
                this._ngxPermissionsService.addPermission(RegistrationTypePermissionsMap.get(this._system.registration_type_id), () => true);
            }));
        }
        list(params = {}) {
            return this._settingsApiService.list(params);
        }
        batchUpdate(section, body) {
            return this._settingsApiService.batchUpdate(section, body);
        }
        updateRegistrationType(body) {
            return this._settingsApiService.updateRegistrationType(body).pipe(tap(() => {
                this._system = Object.assign(Object.assign(Object.assign({}, this._system), body), { default_affiliate_manager_id: body.default_affiliate_manager_id || null });
                this.system$.next(this._system);
                RegistrationTypePermissionsMap.forEach((permission) => {
                    this._ngxPermissionsService.removePermission(permission);
                });
                this._ngxPermissionsService.addPermission(RegistrationTypePermissionsMap.get(this._system.registration_type_id), () => true);
            }));
        }
    }
    SettingsService.ɵfac = function SettingsService_Factory(t) { return new (t || SettingsService)(i0.ɵɵinject(i1.SettingsApiService), i0.ɵɵinject(i2.NgxPermissionsService)); };
    SettingsService.ɵprov = i0.ɵɵdefineInjectable({ token: SettingsService, factory: SettingsService.ɵfac, providedIn: 'root' });
    return SettingsService;
})();

