import { InternalDynamicParameters, } from '../../../../dynamic-params/src/lib/models/dynamic-parameters';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export const dynamicParameters = new Map([
    [InternalDynamicParameters.CampaignId, 'Global.Options.InternalDynamicParametersConfig.CampaignId'],
    [
        InternalDynamicParameters.CampaignLanguage,
        'Global.Options.InternalDynamicParametersConfig.CampaignLanguage',
    ],
    [InternalDynamicParameters.Country, 'Global.Options.InternalDynamicParametersConfig.Country'],
    [InternalDynamicParameters.OS, 'Global.Options.InternalDynamicParametersConfig.OS'],
    [InternalDynamicParameters.Platform, 'Global.Options.InternalDynamicParametersConfig.Platform'],
    [InternalDynamicParameters.Browser, 'Global.Options.InternalDynamicParametersConfig.Browser'],
    [InternalDynamicParameters.IP, 'Global.Options.InternalDynamicParametersConfig.IP'],
    [InternalDynamicParameters.ISP, 'Global.Options.InternalDynamicParametersConfig.ISP'],
    [InternalDynamicParameters.Commission, 'Global.Options.InternalDynamicParametersConfig.Commission'],
    [InternalDynamicParameters.CommissionType, 'Global.Options.InternalDynamicParametersConfig.CommissionType'],
    [InternalDynamicParameters.AffUsername, 'Global.Options.InternalDynamicParametersConfig.AffUsername'],
    [InternalDynamicParameters.AffAlias, 'Global.Options.InternalDynamicParametersConfig.AffAlias'],
    [InternalDynamicParameters.OrderSum, 'Global.Options.InternalDynamicParametersConfig.OrderSum'],
    [InternalDynamicParameters.OrderCurrency, 'Global.Options.InternalDynamicParametersConfig.OrderCurrency'],
    [InternalDynamicParameters.PromoCode, 'Global.Options.InternalDynamicParametersConfig.PromoCode'],
]);
export let DynamicParametersService = /*@__PURE__*/ (() => {
    class DynamicParametersService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = dynamicParameters;
        }
        list() {
            return this._data;
        }
        get(param) {
            const p = this._data.get(param);
            return p ? this._translate.instant(p) : p;
        }
    }
    DynamicParametersService.ɵfac = function DynamicParametersService_Factory(t) { return new (t || DynamicParametersService)(i0.ɵɵinject(i1.TranslateService)); };
    DynamicParametersService.ɵprov = i0.ɵɵdefineInjectable({ token: DynamicParametersService, factory: DynamicParametersService.ɵfac });
    return DynamicParametersService;
})();

