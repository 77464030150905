import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/material/button";
import * as i5 from "@ngx-translate/core";
function ConfirmActionModalContainer_div_8_button_1_Template(rf, ctx) {
    if (rf & 1) {
        const _r4 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "button", 9);
        i0.ɵɵlistener("click", function ConfirmActionModalContainer_div_8_button_1_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r4); const action_r2 = ctx.$implicit; const ctx_r3 = i0.ɵɵnextContext(2); return ctx_r3.onSubmit(action_r2.value); });
        i0.ɵɵtext(1);
        i0.ɵɵpipe(2, "translate");
        i0.ɵɵelementEnd();
    }
    if (rf & 2) {
        const action_r2 = ctx.$implicit;
        i0.ɵɵclassMapInterpolate1("btn ", action_r2.styleClass ? action_r2.styleClass : "btn_success", "");
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 4, action_r2.value), " ");
    }
}
function ConfirmActionModalContainer_div_8_Template(rf, ctx) {
    if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 7);
        i0.ɵɵtemplate(1, ConfirmActionModalContainer_div_8_button_1_Template, 3, 6, "button", 8);
        i0.ɵɵelementEnd();
    }
    if (rf & 2) {
        const ctx_r0 = i0.ɵɵnextContext();
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx_r0.actions);
    }
}
export let ConfirmActionModalContainer = /*@__PURE__*/ (() => {
    class ConfirmActionModalContainer {
        constructor(_dialogRef, modalData) {
            this._dialogRef = _dialogRef;
            this.modalData = modalData;
        }
        ngOnInit() {
            this.actions = this.modalData.actions.map((item) => {
                if (typeof item === 'string') {
                    return {
                        value: item,
                    };
                }
                else {
                    return item;
                }
            });
        }
        onSubmit(key) {
            this._dialogRef.close(key);
        }
    }
    ConfirmActionModalContainer.ɵfac = function ConfirmActionModalContainer_Factory(t) { return new (t || ConfirmActionModalContainer)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA)); };
    ConfirmActionModalContainer.ɵcmp = i0.ɵɵdefineComponent({ type: ConfirmActionModalContainer, selectors: [["ho-confirm-modal-container"]], decls: 9, vars: 5, consts: [[1, "top-modal-panel"], ["mat-dialog-title", ""], ["mat-dialog-close", "", 1, "modal-dialog__close-btn"], [1, "icon-close"], ["mat-dialog-content", ""], [3, "innerHTML"], ["fxLayout", "row", "fxLayoutAlign", "flex-end", "class", "mt-15", "fxLayoutGap", "10px", 4, "ngIf"], ["fxLayout", "row", "fxLayoutAlign", "flex-end", "fxLayoutGap", "10px", 1, "mt-15"], ["mat-button", "", 3, "class", "click", 4, "ngFor", "ngForOf"], ["mat-button", "", 3, "click"]], template: function ConfirmActionModalContainer_Template(rf, ctx) {
            if (rf & 1) {
                i0.ɵɵelementStart(0, "div", 0);
                i0.ɵɵelementStart(1, "h1", 1);
                i0.ɵɵtext(2);
                i0.ɵɵpipe(3, "translate");
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(4, "button", 2);
                i0.ɵɵelement(5, "span", 3);
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(6, "div", 4);
                i0.ɵɵelement(7, "div", 5);
                i0.ɵɵelementEnd();
                i0.ɵɵtemplate(8, ConfirmActionModalContainer_div_8_Template, 2, 1, "div", 6);
            }
            if (rf & 2) {
                i0.ɵɵadvance(2);
                i0.ɵɵtextInterpolate1(" ", (ctx.modalData == null ? null : ctx.modalData.hideTitleText) ? " " : (ctx.modalData == null ? null : ctx.modalData.title) || i0.ɵɵpipeBind1(3, 3, "ConfirmModal.Title.AreYouSure"), " ");
                i0.ɵɵadvance(5);
                i0.ɵɵproperty("innerHTML", ctx.modalData == null ? null : ctx.modalData.body, i0.ɵɵsanitizeHtml);
                i0.ɵɵadvance(1);
                i0.ɵɵproperty("ngIf", ctx.modalData == null ? null : ctx.modalData.actions == null ? null : ctx.modalData.actions.length);
            }
        }, directives: [i1.MatDialogTitle, i1.MatDialogClose, i1.MatDialogContent, i2.NgIf, i3.DefaultLayoutDirective, i3.DefaultLayoutAlignDirective, i3.DefaultLayoutGapDirective, i2.NgForOf, i4.MatButton], pipes: [i5.TranslatePipe], styles: [".mat-dialog-title[_ngcontent-%COMP%]{padding-bottom:25px;margin-bottom:0!important}"] });
    return ConfirmActionModalContainer;
})();

