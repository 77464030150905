import { CommonModule } from '@angular/common';
import { GoogleAnalyticsService } from './service/google-analytics.service';
import { RouterModule } from '@angular/router';
import * as i0 from "@angular/core";
export let GoogleAnalyticsModule = /*@__PURE__*/ (() => {
    class GoogleAnalyticsModule {
    }
    GoogleAnalyticsModule.ɵmod = i0.ɵɵdefineNgModule({ type: GoogleAnalyticsModule });
    GoogleAnalyticsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function GoogleAnalyticsModule_Factory(t) { return new (t || GoogleAnalyticsModule)(); }, providers: [GoogleAnalyticsService], imports: [[CommonModule, RouterModule]] });
    return GoogleAnalyticsModule;
})();

