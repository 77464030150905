import { CommonModule } from '@angular/common';
import { RegistrationService } from './+state/registration.service';
import { RegistrationApiService } from './+state/registration-api.service';
import * as i0 from "@angular/core";
export let AuthDataAccessModule = /*@__PURE__*/ (() => {
    class AuthDataAccessModule {
    }
    AuthDataAccessModule.ɵmod = i0.ɵɵdefineNgModule({ type: AuthDataAccessModule });
    AuthDataAccessModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AuthDataAccessModule_Factory(t) { return new (t || AuthDataAccessModule)(); }, providers: [RegistrationService, RegistrationApiService], imports: [[CommonModule]] });
    return AuthDataAccessModule;
})();

