export var WlCurrency = /*@__PURE__*/ (function (WlCurrency) {
    WlCurrency["USD"] = "USD";
    WlCurrency["EUR"] = "EUR";
    return WlCurrency;
})({});
export const WlCurrencySymbolMap = new Map([
    [WlCurrency.USD, '$'],
    [WlCurrency.EUR, '€'],
]);

