import { InjectionToken } from '@angular/core';
export var InternalDynamicParameters = /*@__PURE__*/ (function (InternalDynamicParameters) {
    InternalDynamicParameters["CampaignId"] = "campaign_id";
    InternalDynamicParameters["CampaignName"] = "campaign_name";
    InternalDynamicParameters["CampaignLanguage"] = "campaign_language";
    InternalDynamicParameters["Country"] = "country";
    InternalDynamicParameters["OS"] = "os";
    InternalDynamicParameters["Platform"] = "platform";
    InternalDynamicParameters["Browser"] = "browser";
    InternalDynamicParameters["IP"] = "ip";
    InternalDynamicParameters["ISP"] = "isp";
    InternalDynamicParameters["Commission"] = "commission";
    InternalDynamicParameters["CommissionType"] = "commission_type";
    InternalDynamicParameters["AffUsername"] = "affiliate_name";
    InternalDynamicParameters["AffAlias"] = "affiliate_alias";
    InternalDynamicParameters["OrderSum"] = "order_sum";
    InternalDynamicParameters["OrderCurrency"] = "order_currency";
    InternalDynamicParameters["PromoCode"] = "promo_code";
    return InternalDynamicParameters;
})({});
export const IDynamicParametersConfigToken = 'IDynamicParametersConfigToken';
export const InternalDynamicParametersConfig = new InjectionToken(IDynamicParametersConfigToken);

