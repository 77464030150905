import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CampaignType = /*@__PURE__*/ (function (CampaignType) {
    CampaignType[CampaignType["OfferGroup"] = 1] = "OfferGroup";
    CampaignType[CampaignType["Offer"] = 2] = "Offer";
    CampaignType[CampaignType["Affiliate"] = 3] = "Affiliate";
    return CampaignType;
})({});
export const CampaignTypeLabels = new Map([
    [CampaignType.OfferGroup, 'Global.Options.CampaignTypeLabels.OfferGroup'],
    [CampaignType.Offer, 'Global.Options.Offer'],
    [CampaignType.Affiliate, 'Global.Options.CampaignTypeLabels.Affiliate'],
]);
export let CampaignTypeService = /*@__PURE__*/ (() => {
    class CampaignTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CampaignTypeLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    CampaignTypeService.ɵfac = function CampaignTypeService_Factory(t) { return new (t || CampaignTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    CampaignTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: CampaignTypeService, factory: CampaignTypeService.ɵfac });
    return CampaignTypeService;
})();

