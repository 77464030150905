import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export let PermissionSettingsService = /*@__PURE__*/ (() => {
    class PermissionSettingsService {
        constructor(_http) {
            this._http = _http;
            this._endpointUrl = `/rbac/ui-permission/`;
        }
        allowedUserPermissions(id) {
            return this._http.get(`${this._endpointUrl}allowed/${id}`);
        }
        activeUserPermissions(id) {
            return this._http.get(`${this._endpointUrl}active/${id}`);
        }
        setUserPermissions(body) {
            const model = { user_id: body.user_id, permissions: body.permissions };
            return this._http.post(`${this._endpointUrl}set-state`, model);
        }
    }
    PermissionSettingsService.ɵfac = function PermissionSettingsService_Factory(t) { return new (t || PermissionSettingsService)(i0.ɵɵinject(i1.HttpClient)); };
    PermissionSettingsService.ɵprov = i0.ɵɵdefineInjectable({ token: PermissionSettingsService, factory: PermissionSettingsService.ɵfac });
    return PermissionSettingsService;
})();

