import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CancelInvoiceReason = /*@__PURE__*/ (function (CancelInvoiceReason) {
    CancelInvoiceReason[CancelInvoiceReason["InvalidFile"] = 0] = "InvalidFile";
    CancelInvoiceReason[CancelInvoiceReason["IncompleteData"] = 1] = "IncompleteData";
    CancelInvoiceReason[CancelInvoiceReason["InvalidBankDetails"] = 2] = "InvalidBankDetails";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectAccountDetails"] = 3] = "IncorrectAccountDetails";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectBeneficiaryDetails"] = 4] = "IncorrectBeneficiaryDetails";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectAmount"] = 5] = "IncorrectAmount";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectCurrency"] = 6] = "IncorrectCurrency";
    CancelInvoiceReason[CancelInvoiceReason["InvalidDate"] = 7] = "InvalidDate";
    CancelInvoiceReason[CancelInvoiceReason["Other"] = 8] = "Other";
    return CancelInvoiceReason;
})({});
export const CancelInvoiceReasonMap = new Map([
    [CancelInvoiceReason.InvalidFile, 'Global.Options.CancelInvoiceReason.InvalidFile'],
    [CancelInvoiceReason.IncompleteData, 'Global.Options.CancelInvoiceReason.IncompleteData'],
    [CancelInvoiceReason.InvalidBankDetails, 'Global.Options.CancelInvoiceReason.InvalidBankDetails'],
    [CancelInvoiceReason.IncorrectAccountDetails, 'Global.Options.CancelInvoiceReason.IncorrectAccountDetails'],
    [
        CancelInvoiceReason.IncorrectBeneficiaryDetails,
        'Global.Options.CancelInvoiceReason.IncorrectBeneficiaryDetails',
    ],
    [CancelInvoiceReason.IncorrectAmount, 'Global.Options.CancelInvoiceReason.IncorrectAmount'],
    [CancelInvoiceReason.IncorrectCurrency, 'Global.Options.CancelInvoiceReason.IncorrectCurrency'],
    [CancelInvoiceReason.InvalidDate, 'Global.Options.CancelInvoiceReason.InvalidDate'],
    [CancelInvoiceReason.Other, 'Global.Options.CancelInvoiceReason.Other'],
]);
export let CancelInvoiceReasonService = /*@__PURE__*/ (() => {
    class CancelInvoiceReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CancelInvoiceReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    CancelInvoiceReasonService.ɵfac = function CancelInvoiceReasonService_Factory(t) { return new (t || CancelInvoiceReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    CancelInvoiceReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: CancelInvoiceReasonService, factory: CancelInvoiceReasonService.ɵfac });
    return CancelInvoiceReasonService;
})();

