import { DEFAULT_DASHBOARD_SETTINGS } from '../constants/dashboard-default-config';
import { DashboardWidget } from '../constants/dashboard-widget.enum';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { cloneDeep, merge } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "./ui-settings-api.service";
import * as i2 from "./ui-settings.service";
import * as i3 from "../+state/ui-settings.query";
import * as i4 from "../+state/ui-settings.store";
export let DashboardSettingsService = /*@__PURE__*/ (() => {
    class DashboardSettingsService {
        constructor(_uiSettingsApiService, _uiSettingsService, _uiSettingsQuery, _uiSettingsStore) {
            this._uiSettingsApiService = _uiSettingsApiService;
            this._uiSettingsService = _uiSettingsService;
            this._uiSettingsQuery = _uiSettingsQuery;
            this._uiSettingsStore = _uiSettingsStore;
            this._userViewType = localStorage.getItem('user_view_type');
        }
        getDashboardSettingsFromApi() {
            return this._userViewType
                ? of({})
                : this._uiSettingsService.getUiSettings().pipe(tap((res) => this._uiSettingsStore.updateDashboardSettings(res.dashboard_settings || {})), map((r) => r.dashboard_settings || {}));
        }
        getDashboardSettings() {
            return new Observable((observer) => {
                const queryDashboardSettings = this._uiSettingsQuery.queryDashboardSettings;
                if (Object.keys(queryDashboardSettings).length) {
                    observer.next(cloneDeep(Object.assign(Object.assign({}, DEFAULT_DASHBOARD_SETTINGS), queryDashboardSettings)));
                    observer.complete();
                }
                else {
                    this.getDashboardSettingsFromApi().subscribe((r) => {
                        observer.next(cloneDeep(r ? merge(DEFAULT_DASHBOARD_SETTINGS, r) : DEFAULT_DASHBOARD_SETTINGS));
                        observer.complete();
                    });
                }
            });
        }
        updateDashboardConfig(id, dashboardConfig) {
            return (this._userViewType
                ? of({})
                : this._uiSettingsApiService.saveDashboardSettings({
                    dashboard_id: id,
                    ui_settings: id === DashboardWidget.Visible
                        ? dashboardConfig
                        : merge(DEFAULT_DASHBOARD_SETTINGS[id], dashboardConfig),
                })).pipe(tap(() => {
                const queryDashboardSettings = this._uiSettingsQuery.queryDashboardSettings[id];
                this._uiSettingsStore.updateDashboardSettings({
                    [id]: id === DashboardWidget.Visible
                        ? dashboardConfig
                        : Object.assign(Object.assign(Object.assign({}, (queryDashboardSettings[id] || {})), dashboardConfig), (queryDashboardSettings[id] && {
                            version: queryDashboardSettings[id].version,
                        })),
                });
            }));
        }
    }
    DashboardSettingsService.ɵfac = function DashboardSettingsService_Factory(t) { return new (t || DashboardSettingsService)(i0.ɵɵinject(i1.UiSettingsApiService), i0.ɵɵinject(i2.UiSettingsService), i0.ɵɵinject(i3.UiSettingsQuery), i0.ɵɵinject(i4.UiSettingsStore)); };
    DashboardSettingsService.ɵprov = i0.ɵɵdefineInjectable({ token: DashboardSettingsService, factory: DashboardSettingsService.ɵfac });
    return DashboardSettingsService;
})();

