import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var UserRegistrationStatus = /*@__PURE__*/ (function (UserRegistrationStatus) {
    UserRegistrationStatus[UserRegistrationStatus["Pending"] = 0] = "Pending";
    UserRegistrationStatus[UserRegistrationStatus["Confirmed"] = 1] = "Confirmed";
    UserRegistrationStatus[UserRegistrationStatus["Rejected"] = 2] = "Rejected";
    UserRegistrationStatus[UserRegistrationStatus["Completed"] = 10] = "Completed";
    return UserRegistrationStatus;
})({});
export const UserRegistrationStatusMap = new Map([
    [UserRegistrationStatus.Pending, 'Global.Status.Pending'],
    [UserRegistrationStatus.Confirmed, 'Global.Options.UserRegistrationStatus.Confirmed'],
    [UserRegistrationStatus.Rejected, 'Global.Status.Rejected'],
    [UserRegistrationStatus.Completed, 'Global.Options.UserRegistrationStatus.Completed'],
]);
export let UserRegistrationStatusService = /*@__PURE__*/ (() => {
    class UserRegistrationStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = UserRegistrationStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    UserRegistrationStatusService.ɵfac = function UserRegistrationStatusService_Factory(t) { return new (t || UserRegistrationStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    UserRegistrationStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: UserRegistrationStatusService, factory: UserRegistrationStatusService.ɵfac });
    return UserRegistrationStatusService;
})();

