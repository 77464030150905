import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ReportVisibility = /*@__PURE__*/ (function (ReportVisibility) {
    ReportVisibility["Active"] = "active";
    ReportVisibility["Hidden"] = "hidden";
    return ReportVisibility;
})({});
export const ReportVisibilityMap = new Map([
    [ReportVisibility.Active, 'Global.Select.ReportVisibility.Option.ShowActive'],
    [ReportVisibility.Hidden, 'Global.Select.ReportVisibility.Option.ShowHidden'],
]);
export let ReportVisibilityService = /*@__PURE__*/ (() => {
    class ReportVisibilityService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ReportVisibilityMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    ReportVisibilityService.ɵfac = function ReportVisibilityService_Factory(t) { return new (t || ReportVisibilityService)(i0.ɵɵinject(i1.TranslateService)); };
    ReportVisibilityService.ɵprov = i0.ɵɵdefineInjectable({ token: ReportVisibilityService, factory: ReportVisibilityService.ɵfac });
    return ReportVisibilityService;
})();

