import * as i0 from "@angular/core";
export var DeepLinkStatus = /*@__PURE__*/ (function (DeepLinkStatus) {
    DeepLinkStatus[DeepLinkStatus["NotAllowed"] = 0] = "NotAllowed";
    DeepLinkStatus[DeepLinkStatus["Allowed"] = 1] = "Allowed";
    return DeepLinkStatus;
})({});
export const DeepLinkStatusStringMap = new Map([
    [DeepLinkStatus.NotAllowed, 'Global.Options.DeepLinkStatusString.NotAllowed'],
    [DeepLinkStatus.Allowed, 'Global.Options.DeepLinkStatusString.Allowed'],
]);
export let DeepLinkService = /*@__PURE__*/ (() => {
    class DeepLinkService {
        setStatusClass(status) {
            switch (status) {
                case DeepLinkStatus.Allowed:
                    return 'text-success';
                case DeepLinkStatus.NotAllowed:
                    return 'text-danger';
            }
        }
    }
    DeepLinkService.ɵfac = function DeepLinkService_Factory(t) { return new (t || DeepLinkService)(); };
    DeepLinkService.ɵprov = i0.ɵɵdefineInjectable({ token: DeepLinkService, factory: DeepLinkService.ɵfac });
    return DeepLinkService;
})();

