import { SessionQuery } from './state/session.query';
import { SessionStore } from './state/session.store';
import { SessionService } from './state/session.service';
import { SessionApiService } from './state/session-api.service';
import { CommonModule } from '@angular/common';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SessionUserTypeService } from './state/session-user-type-service.service';
import * as i0 from "@angular/core";
export let SessionDataAccessModule = /*@__PURE__*/ (() => {
    class SessionDataAccessModule {
        constructor(parentModule) {
            if (parentModule) {
                throw new Error('CoreModule is already loaded. Import it in the AppModule only');
            }
        }
        static forRoot() {
            return {
                ngModule: SessionDataAccessModule,
                providers: [
                    SessionApiService,
                    SessionService,
                    SessionStore,
                    SessionQuery,
                    SessionUserTypeService,
                    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
                    JwtHelperService,
                ],
            };
        }
    }
    SessionDataAccessModule.ɵmod = i0.ɵɵdefineNgModule({ type: SessionDataAccessModule });
    SessionDataAccessModule.ɵinj = i0.ɵɵdefineInjector({ factory: function SessionDataAccessModule_Factory(t) { return new (t || SessionDataAccessModule)(i0.ɵɵinject(SessionDataAccessModule, 12)); }, imports: [[CommonModule]] });
    return SessionDataAccessModule;
})();

