import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ReferralStatus = /*@__PURE__*/ (function (ReferralStatus) {
    ReferralStatus[ReferralStatus["Inactive"] = -1] = "Inactive";
    ReferralStatus[ReferralStatus["Active"] = 1] = "Active";
    return ReferralStatus;
})({});
export const ReferralStatusMap = new Map([
    [ReferralStatus.Inactive, 'Global.Options.ReferralStatus.Inactive'],
    [ReferralStatus.Active, 'Global.Status.Active'],
]);
export let ReferralStatusService = /*@__PURE__*/ (() => {
    class ReferralStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ReferralStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case ReferralStatus.Active:
                    return 'text-success';
                case ReferralStatus.Inactive:
                    return 'text-danger';
                default:
                    return '';
            }
        }
    }
    ReferralStatusService.ɵfac = function ReferralStatusService_Factory(t) { return new (t || ReferralStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    ReferralStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: ReferralStatusService, factory: ReferralStatusService.ɵfac });
    return ReferralStatusService;
})();

