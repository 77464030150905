import { Query, toBoolean } from '@datorama/akita';
import * as i0 from "@angular/core";
import * as i1 from "./session.store";
import * as i2 from "@auth0/angular-jwt";
export let SessionQuery = /*@__PURE__*/ (() => {
    class SessionQuery extends Query {
        constructor(store, _jwtHelper) {
            super(store);
            this.store = store;
            this._jwtHelper = _jwtHelper;
            this.userInfo$ = this.select((state) => state.userInfo);
            this.isLoggedIn$ = this.select((state) => toBoolean(state.token));
            this.token$ = this.select((state) => state.token);
        }
        isLoggedIn() {
            const token = this.getValue().token;
            return toBoolean(token) && !this._jwtHelper.isTokenExpired(token);
        }
        token() {
            return this.getValue().token;
        }
        userInfo() {
            return this.getValue().userInfo;
        }
        get user() {
            // return this.getValue().user;
            return {};
        }
    }
    SessionQuery.ɵfac = function SessionQuery_Factory(t) { return new (t || SessionQuery)(i0.ɵɵinject(i1.SessionStore), i0.ɵɵinject(i2.JwtHelperService)); };
    SessionQuery.ɵprov = i0.ɵɵdefineInjectable({ token: SessionQuery, factory: SessionQuery.ɵfac });
    return SessionQuery;
})();

