import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var AffiliateWidgetTab = /*@__PURE__*/ (function (AffiliateWidgetTab) {
    AffiliateWidgetTab[AffiliateWidgetTab["New"] = 0] = "New";
    AffiliateWidgetTab[AffiliateWidgetTab["Pending"] = 1] = "Pending";
    AffiliateWidgetTab[AffiliateWidgetTab["Approved"] = 2] = "Approved";
    AffiliateWidgetTab[AffiliateWidgetTab["Active"] = 3] = "Active";
    return AffiliateWidgetTab;
})({});
export let ChartService = /*@__PURE__*/ (() => {
    class ChartService {
        constructor(_httpClient) {
            this._httpClient = _httpClient;
            this._endpointUrl = `/dashboard`;
        }
        commission(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/commission-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        click(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/click-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        lead(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/lead-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        sale(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/sale-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        clRate(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/cl-rate-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        cr(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/cr-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        epc(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/epc-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        profit(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/profit-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        revenue(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/revenue-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        roi(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/measure/roi-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        affiliates(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/affiliate/linear`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        newAffiliateRequests(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/new-user-request-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        pendingAffiliateRequests(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/pending-user-request-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        approvedAffiliates(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/approved-affiliate-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        activeAffiliates(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/active-affiliate-chart`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        users(type, body) {
            return this._httpClient.post(`${this._endpointUrl}/user/${type}`, body).pipe(pluck('data'));
        }
        offers(type, body) {
            return this._httpClient
                .post(`${this._endpointUrl}/offer/${type}`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        affiliateCampaigns(type, body) {
            return this._httpClient
                .post(`${this._endpointUrl}/affiliate-campaign/${type}`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        affiliateManagersTable(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/affiliate-manager/table`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        affiliateManagers(type, body) {
            return this._httpClient
                .post(`${this._endpointUrl}/affiliate-manager/${type}`, body, {
                observe: 'response',
            })
                .pipe(pluck('body', 'data'));
        }
        metricByAffiliateChart(type, body) {
            return this._httpClient
                .post(`${this._endpointUrl}/affiliate/pie-${type.toLowerCase()}`, body)
                .pipe(pluck('data'));
        }
    }
    ChartService.ɵfac = function ChartService_Factory(t) { return new (t || ChartService)(i0.ɵɵinject(i1.HttpClient)); };
    ChartService.ɵprov = i0.ɵɵdefineInjectable({ token: ChartService, factory: ChartService.ɵfac });
    return ChartService;
})();

