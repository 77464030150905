import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var RewardTypes = /*@__PURE__*/ (function (RewardTypes) {
    RewardTypes[RewardTypes["PercentageOfFriendsPayment"] = 1] = "PercentageOfFriendsPayment";
    RewardTypes[RewardTypes["FixedAmountPerCPL"] = 3] = "FixedAmountPerCPL";
    RewardTypes[RewardTypes["FixedAmountPerCPA"] = 4] = "FixedAmountPerCPA";
    RewardTypes[RewardTypes["FixedAmountPerRevshare"] = 5] = "FixedAmountPerRevshare";
    return RewardTypes;
})({});
export const RewardTypesLabels = new Map([
    [RewardTypes.PercentageOfFriendsPayment, 'Global.Options.RewardTypesLabels.PercentageOfFriendsPayment'],
    [RewardTypes.FixedAmountPerCPL, 'Global.Options.RewardTypesLabels.FixedAmountPerCPL'],
    [RewardTypes.FixedAmountPerCPA, 'Global.Options.RewardTypesLabels.FixedAmountPerCPA'],
    [RewardTypes.FixedAmountPerRevshare, 'Global.Options.RewardTypesLabels.FixedAmountPerRevshare'],
]);
export let RewardTypesLabelsService = /*@__PURE__*/ (() => {
    class RewardTypesLabelsService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = new Map(RewardTypesLabels);
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    RewardTypesLabelsService.ɵfac = function RewardTypesLabelsService_Factory(t) { return new (t || RewardTypesLabelsService)(i0.ɵɵinject(i1.TranslateService)); };
    RewardTypesLabelsService.ɵprov = i0.ɵɵdefineInjectable({ token: RewardTypesLabelsService, factory: RewardTypesLabelsService.ɵfac });
    return RewardTypesLabelsService;
})();

