export var DashboardWidget = /*@__PURE__*/ (function (DashboardWidget) {
    DashboardWidget["Visible"] = "visible";
    DashboardWidget["AdminAtlProfitWidget"] = "admin-atl-profit-widget";
    DashboardWidget["AdminAtlClicksByAffWidget"] = "admin-atl-clicks-by-aff-widget";
    DashboardWidget["AdminAtlLeadsByAffWidget"] = "admin-atl-leads-by-aff-widget";
    DashboardWidget["AdminAtlSalesByAffWidget"] = "admin-atl-sales-by-aff-widget";
    DashboardWidget["AdminAtlRevenueWidget"] = "admin-atl-revenue-widget";
    DashboardWidget["AdminAtlCommissionWidget"] = "admin-atl-commission-widget";
    DashboardWidget["AdminAtlClicksWidget"] = "admin-atl-clicks-widget";
    DashboardWidget["AdminAtlLeadsWidget"] = "admin-atl-leads-widget";
    DashboardWidget["AdminAtlSaleWidget"] = "admin-atl-sale-widget";
    DashboardWidget["AdminAtlCRWidget"] = "admin-atl-cr-widget";
    DashboardWidget["AdminAtlROIWidget"] = "admin-atl-roi-widget";
    DashboardWidget["AdminAtlEPCWidget"] = "admin-atl-epc-widget";
    DashboardWidget["AdminAtlAffiliatesWidget"] = "admin-atl-affiliates-widget";
    DashboardWidget["AdminAtlManagersWidget"] = "admin-atl-managers-widget";
    DashboardWidget["AdminAmAtlOfferGroupsAffiliateLanguagesWidget"] = "admin-am-atl-offer-groups-affiliates-languages-widget";
    DashboardWidget["AdminAmAtlCountriesWidget"] = "admin-am-atl-countries-widget";
    DashboardWidget["AmProfitWidget"] = "am-profit-widget";
    DashboardWidget["AmRevenueWidget"] = "am-revenue-widget";
    DashboardWidget["AmCommissionWidget"] = "am-commission-widget";
    DashboardWidget["AmClicksWidget"] = "am-clicks-widget";
    DashboardWidget["AmLeadsWidget"] = "am-leads-widget";
    DashboardWidget["AmSaleWidget"] = "am-sale-widget";
    DashboardWidget["AmCRWidget"] = "am-cr-widget";
    DashboardWidget["AmROIWidget"] = "am-roi-widget";
    DashboardWidget["AmEPCWidget"] = "am-epc-widget";
    DashboardWidget["AmAffiliatesWidget"] = "am-affiliates-widget";
    DashboardWidget["AffCommissionWidget"] = "aff-commission-widget";
    DashboardWidget["AffClicksWidget"] = "aff-clicks-widget";
    DashboardWidget["AffLeadsWidget"] = "aff-leads-widget";
    DashboardWidget["AffSaleWidget"] = "aff-sale-widget";
    DashboardWidget["AffCRWidget"] = "aff-cr-widget";
    DashboardWidget["AffCLWidget"] = "aff-cl-widget";
    DashboardWidget["AffEPCWidget"] = "aff-epc-widget";
    DashboardWidget["AffPersonalAffiliateManagerWidget"] = "aff-personal-affiliate-manager-widget";
    DashboardWidget["AffProfitWidget"] = "aff-profit-widget";
    DashboardWidget["AffPendingPayoutsWidget"] = "aff-pending-payouts-widget";
    DashboardWidget["AffBillingInfoWidget"] = "aff-billing-info-widget";
    return DashboardWidget;
})({});
export const DashboardWidgetIdsForAdminAtl = [
    DashboardWidget.AdminAtlProfitWidget,
    DashboardWidget.AdminAtlRevenueWidget,
    DashboardWidget.AdminAtlCommissionWidget,
    DashboardWidget.AdminAtlClicksWidget,
    DashboardWidget.AdminAtlLeadsWidget,
    DashboardWidget.AdminAtlSaleWidget,
    DashboardWidget.AdminAtlCRWidget,
    DashboardWidget.AdminAtlROIWidget,
    DashboardWidget.AdminAtlEPCWidget,
    // DashboardWidget.AdminAtlAffiliatesWidget, // hide AFS-5611
    DashboardWidget.AdminAmAtlCountriesWidget,
    DashboardWidget.AdminAtlManagersWidget,
    DashboardWidget.AdminAmAtlOfferGroupsAffiliateLanguagesWidget,
    DashboardWidget.AdminAtlClicksByAffWidget,
    DashboardWidget.AdminAtlLeadsByAffWidget,
    DashboardWidget.AdminAtlSalesByAffWidget,
];
export const DashboardWidgetIdsForAm = [
    DashboardWidget.AmProfitWidget,
    DashboardWidget.AmRevenueWidget,
    DashboardWidget.AmCommissionWidget,
    DashboardWidget.AmClicksWidget,
    DashboardWidget.AmLeadsWidget,
    DashboardWidget.AmSaleWidget,
    DashboardWidget.AmCRWidget,
    DashboardWidget.AmROIWidget,
    DashboardWidget.AmEPCWidget,
    DashboardWidget.AmAffiliatesWidget,
    DashboardWidget.AdminAmAtlCountriesWidget,
    DashboardWidget.AdminAmAtlOfferGroupsAffiliateLanguagesWidget,
];
export const DashboardWidgetIdsForAff = [
    DashboardWidget.AffCommissionWidget,
    DashboardWidget.AffPersonalAffiliateManagerWidget,
    DashboardWidget.AffClicksWidget,
    DashboardWidget.AffLeadsWidget,
    DashboardWidget.AffSaleWidget,
    DashboardWidget.AffCLWidget,
    DashboardWidget.AffCRWidget,
    DashboardWidget.AffEPCWidget,
    DashboardWidget.AffProfitWidget,
    DashboardWidget.AffBillingInfoWidget,
];

