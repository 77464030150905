import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ho/shared/data-access/core/lib/+state/profile-api.service";
export let ProfileService = /*@__PURE__*/ (() => {
    class ProfileService {
        constructor(_profileApiService) {
            this._profileApiService = _profileApiService;
            this.timeZoneChange$$ = new BehaviorSubject('+00:00');
        }
        get(section) {
            return this._profileApiService.get(section);
        }
        getMyAccount() {
            return this._profileApiService.getMyAccount();
        }
        patchSection(section, params) {
            return this._profileApiService.patchSection(section, params);
        }
        post(body) {
            return this._profileApiService.post(body);
        }
        getTimeZoneOffset() {
            return localStorage.getItem('timeZone') || '+00:00';
        }
        changeSecretKey(body) {
            return this._profileApiService.changeSecretKey(body);
        }
        uploadAvatar(image) {
            return this._profileApiService.uploadAvatar(image);
        }
        getAvatar() {
            return this._profileApiService.getAvatar();
        }
        removeAvatar() {
            return this._profileApiService.removeAvatar();
        }
    }
    ProfileService.ɵfac = function ProfileService_Factory(t) { return new (t || ProfileService)(i0.ɵɵinject(i1.ProfileApiService)); };
    ProfileService.ɵprov = i0.ɵɵdefineInjectable({ token: ProfileService, factory: ProfileService.ɵfac, providedIn: 'root' });
    return ProfileService;
})();

