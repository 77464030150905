import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export let SessionApiService = /*@__PURE__*/ (() => {
    class SessionApiService {
        constructor(_http, _environment) {
            this._http = _http;
            this._environment = _environment;
            this.endpointUrl = '/auth';
        }
        login(credentials) {
            return this._http.post(`/auth/login?th-lang=${localStorage.getItem('language') || 'en'}&ws=${location.origin}`, Object.assign(Object.assign({}, credentials), { ws: location.origin }), {
                withCredentials: true,
            });
        }
        currentUserInfo() {
            return this._http.get(`/me`);
        }
        myPermissions() {
            return this._http.get(`/rbac/my-permissions`);
        }
    }
    SessionApiService.ɵfac = function SessionApiService_Factory(t) { return new (t || SessionApiService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(AppEnvironment)); };
    SessionApiService.ɵprov = i0.ɵɵdefineInjectable({ token: SessionApiService, factory: SessionApiService.ɵfac });
    return SessionApiService;
})();

