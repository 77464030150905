import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CommissionThresholdReachedFilter = /*@__PURE__*/ (function (CommissionThresholdReachedFilter) {
    CommissionThresholdReachedFilter["NotReached"] = "below";
    CommissionThresholdReachedFilter["Reached"] = "above";
    return CommissionThresholdReachedFilter;
})({});
export const CommissionThresholdReachedFilterMap = new Map([
    [CommissionThresholdReachedFilter.NotReached, 'Global.Options.CommissionThresholdReachedFilter.NotReached'],
    [CommissionThresholdReachedFilter.Reached, 'Global.Options.CommissionThresholdReachedFilter.Reached'],
]);
export var CommissionThresholdReachedType = /*@__PURE__*/ (function (CommissionThresholdReachedType) {
    CommissionThresholdReachedType[CommissionThresholdReachedType["NotReached"] = 0] = "NotReached";
    CommissionThresholdReachedType[CommissionThresholdReachedType["Reached"] = 1] = "Reached";
    return CommissionThresholdReachedType;
})({});
export const CommissionThresholdReachedTypesMap = new Map([
    [CommissionThresholdReachedType.NotReached, 'Global.Options.CommissionThresholdReachedTypes.NotReached'],
    [CommissionThresholdReachedType.Reached, 'Global.Options.CommissionThresholdReachedTypes.Reached'],
]);
export let CommissionThresholdReachedTypesService = /*@__PURE__*/ (() => {
    class CommissionThresholdReachedTypesService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CommissionThresholdReachedTypesMap;
        }
        list() {
            return this._data;
        }
        get(type) {
            const t = this._data.get(type);
            return t ? this._translate.instant(t) : t;
        }
    }
    CommissionThresholdReachedTypesService.ɵfac = function CommissionThresholdReachedTypesService_Factory(t) { return new (t || CommissionThresholdReachedTypesService)(i0.ɵɵinject(i1.TranslateService)); };
    CommissionThresholdReachedTypesService.ɵprov = i0.ɵɵdefineInjectable({ token: CommissionThresholdReachedTypesService, factory: CommissionThresholdReachedTypesService.ɵfac });
    return CommissionThresholdReachedTypesService;
})();

