import { CommonModule } from '@angular/common';
import * as i0 from "@angular/core";
export let QaAttributeWidgetModule = /*@__PURE__*/ (() => {
    class QaAttributeWidgetModule {
    }
    QaAttributeWidgetModule.ɵmod = i0.ɵɵdefineNgModule({ type: QaAttributeWidgetModule });
    QaAttributeWidgetModule.ɵinj = i0.ɵɵdefineInjector({ factory: function QaAttributeWidgetModule_Factory(t) { return new (t || QaAttributeWidgetModule)(); }, imports: [[CommonModule]] });
    return QaAttributeWidgetModule;
})();

