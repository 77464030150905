import { __decorate, __metadata } from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import * as i0 from "@angular/core";
let UiSettingsGridCacheStore = /*@__PURE__*/ (() => {
    let UiSettingsGridCacheStore = class UiSettingsGridCacheStore extends Store {
        constructor() {
            super({ cache: {}, payload: {} });
        }
        addCache(data) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { cache: Object.assign(Object.assign({}, cloneDeep(state.cache)), data) });
            });
        }
        removeCache() {
            this.update((state) => (Object.assign(Object.assign({}, state), { cache: {} })));
        }
        addPayload(data) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { payload: Object.assign(Object.assign({}, cloneDeep(state.payload)), data) });
            });
        }
        removePayload() {
            this.update((state) => (Object.assign(Object.assign({}, state), { payload: {} })));
        }
    };
    UiSettingsGridCacheStore.ɵfac = function UiSettingsGridCacheStore_Factory(t) { return new (t || UiSettingsGridCacheStore)(); };
    UiSettingsGridCacheStore.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsGridCacheStore, factory: UiSettingsGridCacheStore.ɵfac });
    UiSettingsGridCacheStore = __decorate([
        StoreConfig({ name: StoreNames.UiSettingsGridCache }),
        __metadata("design:paramtypes", [])
    ], UiSettingsGridCacheStore);
    return UiSettingsGridCacheStore;
})();
export { UiSettingsGridCacheStore };

