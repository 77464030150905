import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmModalService } from './services/confirm-modal.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
import * as i0 from "@angular/core";
export let ConfirmModalModule = /*@__PURE__*/ (() => {
    class ConfirmModalModule {
    }
    ConfirmModalModule.ɵmod = i0.ɵɵdefineNgModule({ type: ConfirmModalModule });
    ConfirmModalModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ConfirmModalModule_Factory(t) { return new (t || ConfirmModalModule)(); }, providers: [ConfirmModalService], imports: [[
                CommonModule,
                MatButtonModule,
                MatDialogModule,
                FlexLayoutModule,
                QaAttributeWidgetModule,
                TranslateModule,
            ]] });
    return ConfirmModalModule;
})();

