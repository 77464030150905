import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var VoidReason = /*@__PURE__*/ (function (VoidReason) {
    VoidReason["None"] = "none";
    VoidReason["Fraud"] = "fraud";
    VoidReason["DuplicateTransaction"] = "duplicate_transaction";
    VoidReason["TestSale"] = "test_sale";
    VoidReason["MisfiredPostback"] = "misfired_pixel";
    VoidReason["Other"] = "other";
    return VoidReason;
})({});
export const VoidReasonMap = new Map([
    [VoidReason.None, 'Global.Options.VoidReason.None'],
    [VoidReason.Fraud, 'Global.Options.VoidReason.Fraud'],
    [VoidReason.DuplicateTransaction, 'Global.Options.VoidReason.DuplicateTransaction'],
    [VoidReason.TestSale, 'Global.Options.VoidReason.TestSale'],
    [VoidReason.MisfiredPostback, 'Global.Options.VoidReason.MisfiredPostback'],
    [VoidReason.Other, 'Global.Options.VoidReason.Other'],
]);
export let VoidReasonService = /*@__PURE__*/ (() => {
    class VoidReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = VoidReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        has(key) {
            return this._data.has(key);
        }
    }
    VoidReasonService.ɵfac = function VoidReasonService_Factory(t) { return new (t || VoidReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    VoidReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: VoidReasonService, factory: VoidReasonService.ɵfac });
    return VoidReasonService;
})();

