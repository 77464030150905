import { __decorate, __metadata } from "tslib";
import { EntityStore, StoreConfig } from '@datorama/akita';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import * as i0 from "@angular/core";
let DictionariesStore = /*@__PURE__*/ (() => {
    let DictionariesStore = class DictionariesStore extends EntityStore {
        constructor() {
            super({ query: [] });
        }
    };
    DictionariesStore.ɵfac = function DictionariesStore_Factory(t) { return new (t || DictionariesStore)(); };
    DictionariesStore.ɵprov = i0.ɵɵdefineInjectable({ token: DictionariesStore, factory: DictionariesStore.ɵfac });
    DictionariesStore = __decorate([
        StoreConfig({
            name: StoreNames.Dictionaries,
        }),
        __metadata("design:paramtypes", [])
    ], DictionariesStore);
    return DictionariesStore;
})();
export { DictionariesStore };

