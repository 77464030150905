import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../services/ui-settings-api.service";
import * as i2 from "../+state/ui-settings.store";
import * as i3 from "../+state/ui-settings.query";
export let UiSettingsService = /*@__PURE__*/ (() => {
    class UiSettingsService {
        constructor(_uiSettingsApiService, _uiSettingsStore, _uiSettingsQuery) {
            this._uiSettingsApiService = _uiSettingsApiService;
            this._uiSettingsStore = _uiSettingsStore;
            this._uiSettingsQuery = _uiSettingsQuery;
        }
        getUiSettings() {
            const observable = localStorage.getItem('user_view_type')
                ? of({
                    grid_settings: null,
                    dashboard_settings: null,
                    modal_settings: null,
                    config_settings: null,
                    theme_settings: {
                        selected_color_scheme: localStorage.getItem('selected_color_scheme') || 'Light',
                    },
                    tutorial_settings: { skip_tutorial: !!localStorage.getItem('skip_tutorial') },
                })
                : this._uiSettingsApiService.getUiSettings();
            return observable.pipe(tap((res) => this._uiSettingsStore.updateUiSettings(res)), map((r) => r));
        }
        updateConfigSettings(configSettings) {
            const observable = localStorage.getItem('user_view_type')
                ? of(configSettings)
                : this._uiSettingsApiService.updateConfigSettings(configSettings);
            return observable.pipe(tap((res) => this._uiSettingsStore.updateConfigSettings(configSettings)));
        }
    }
    UiSettingsService.ɵfac = function UiSettingsService_Factory(t) { return new (t || UiSettingsService)(i0.ɵɵinject(i1.UiSettingsApiService), i0.ɵɵinject(i2.UiSettingsStore), i0.ɵɵinject(i3.UiSettingsQuery)); };
    UiSettingsService.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsService, factory: UiSettingsService.ɵfac });
    return UiSettingsService;
})();

