import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var InvoiceStatus = /*@__PURE__*/ (function (InvoiceStatus) {
    InvoiceStatus[InvoiceStatus["Moderation"] = 0] = "Moderation";
    InvoiceStatus[InvoiceStatus["Approved"] = 1] = "Approved";
    InvoiceStatus[InvoiceStatus["Rejected"] = 2] = "Rejected";
    return InvoiceStatus;
})({});
export const InvoiceStatusMap = new Map([
    [InvoiceStatus.Moderation, 'Global.Options.InvoiceStatus.Moderation'],
    [InvoiceStatus.Approved, 'Global.Status.Approved'],
    [InvoiceStatus.Rejected, 'Global.Status.Rejected'],
]);
export let InvoiceStatusService = /*@__PURE__*/ (() => {
    class InvoiceStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = InvoiceStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    InvoiceStatusService.ɵfac = function InvoiceStatusService_Factory(t) { return new (t || InvoiceStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    InvoiceStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: InvoiceStatusService, factory: InvoiceStatusService.ɵfac });
    return InvoiceStatusService;
})();

