import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CampaignUserRuleStatuses = /*@__PURE__*/ (function (CampaignUserRuleStatuses) {
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Deleted"] = -1] = "Deleted";
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Pending"] = 0] = "Pending";
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Rejected"] = 1] = "Rejected";
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Approved"] = 2] = "Approved";
    return CampaignUserRuleStatuses;
})({});
export const CampaignUserRuleStatusLabels = new Map([
    [CampaignUserRuleStatuses.Deleted, 'Global.Status.Deleted'],
    [CampaignUserRuleStatuses.Pending, 'Global.Status.Pending'],
    [CampaignUserRuleStatuses.Rejected, 'Global.Status.Rejected'],
    [CampaignUserRuleStatuses.Approved, 'Global.Status.Approved'],
]);
export let CampaignUserRuleStatusService = /*@__PURE__*/ (() => {
    class CampaignUserRuleStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CampaignUserRuleStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case CampaignUserRuleStatuses.Approved:
                    return 'text-success';
                case CampaignUserRuleStatuses.Rejected:
                    return 'text-danger';
                case CampaignUserRuleStatuses.Pending:
                    return 'text-warning';
            }
        }
    }
    CampaignUserRuleStatusService.ɵfac = function CampaignUserRuleStatusService_Factory(t) { return new (t || CampaignUserRuleStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    CampaignUserRuleStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: CampaignUserRuleStatusService, factory: CampaignUserRuleStatusService.ɵfac });
    return CampaignUserRuleStatusService;
})();

