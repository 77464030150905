import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PerformancesByProfit = /*@__PURE__*/ (function (PerformancesByProfit) {
    PerformancesByProfit["EPC"] = "epc";
    PerformancesByProfit["Clicks"] = "click_count";
    PerformancesByProfit["FTDs"] = "ftd_count";
    PerformancesByProfit["Revshare"] = "revshare_count";
    PerformancesByProfit["SystemProfit"] = "system_profit";
    PerformancesByProfit["IncomeBenchmark"] = "income_benchmark";
    PerformancesByProfit["CR"] = "cr";
    PerformancesByProfit["ROI"] = "roi";
    PerformancesByProfit["CLRate"] = "cl_rate";
    return PerformancesByProfit;
})({});
export const PerformancesByProfitMap = new Map([
    [PerformancesByProfit.EPC, 'Global.Options.PerformancesByProfit.EPC'],
    [PerformancesByProfit.Clicks, 'Global.Options.PerformancesByProfit.Clicks'],
    [PerformancesByProfit.FTDs, 'Global.Options.PerformancesByProfit.FTDs'],
    [PerformancesByProfit.Revshare, 'Global.Options.Revshare'],
    [PerformancesByProfit.SystemProfit, 'Global.Options.PerformancesByProfit.SystemProfit'],
    [PerformancesByProfit.IncomeBenchmark, 'Global.Options.PerformancesByProfit.IncomeBenchmark'],
    [PerformancesByProfit.CR, 'Global.Options.PerformancesByProfit.CR'],
    [PerformancesByProfit.ROI, 'Global.Options.PerformancesByProfit.ROI'],
    [PerformancesByProfit.CLRate, 'Global.Options.PerformancesByProfit.CLRate'],
]);
export let PerformanceByProfitService = /*@__PURE__*/ (() => {
    class PerformanceByProfitService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PerformancesByProfitMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    PerformanceByProfitService.ɵfac = function PerformanceByProfitService_Factory(t) { return new (t || PerformanceByProfitService)(i0.ɵɵinject(i1.TranslateService)); };
    PerformanceByProfitService.ɵprov = i0.ɵɵdefineInjectable({ token: PerformanceByProfitService, factory: PerformanceByProfitService.ɵfac });
    return PerformanceByProfitService;
})();

