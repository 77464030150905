import { UserWorkspaceRole } from '@ho/shared/data-access/session';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export const WorkspaceRoleTypesMap = new Map([
    [UserWorkspaceRole.Admin, 'Global.UserRole.Admin'],
    /* ho:start */
    [UserWorkspaceRole.ATL, 'Global.UserRole.AffiliateTeamLead'],
    /* ho:end */
    [UserWorkspaceRole.AM, 'Global.UserRole.Manager'],
    [UserWorkspaceRole.Aff, 'Global.UserRole.Affiliate'],
    [UserWorkspaceRole.AD, 'Global.UserRole.Advertiser'],
]);
export let WorkspaceRoleTypeservice = /*@__PURE__*/ (() => {
    class WorkspaceRoleTypeservice {
        constructor(_translate) {
            this._translate = _translate;
            this._data = WorkspaceRoleTypesMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    WorkspaceRoleTypeservice.ɵfac = function WorkspaceRoleTypeservice_Factory(t) { return new (t || WorkspaceRoleTypeservice)(i0.ɵɵinject(i1.TranslateService)); };
    WorkspaceRoleTypeservice.ɵprov = i0.ɵɵdefineInjectable({ token: WorkspaceRoleTypeservice, factory: WorkspaceRoleTypeservice.ɵfac });
    return WorkspaceRoleTypeservice;
})();

