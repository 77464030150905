import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/flex-layout/flex";
export let ErrorSnackBarComponent = /*@__PURE__*/ (() => {
    class ErrorSnackBarComponent {
        constructor(data, snackBarRef) {
            this.data = data;
            this.snackBarRef = snackBarRef;
            this.duration = this.snackBarRef.containerInstance.snackBarConfig.duration;
        }
        ngOnInit() {
            this.snackBarRef.afterOpened().subscribe(() => {
                this.snackBarRef.containerInstance.snackBarConfig.duration = 300000;
                this.onCloseWithDuration();
            });
        }
        onClose() {
            this.snackBarRef.dismiss();
        }
        onCloseWithDuration() {
            this._timer = setTimeout(() => {
                this.snackBarRef.dismiss();
            }, this.duration);
        }
        onHover() {
            clearTimeout(this._timer);
        }
        onLeave() {
            this.onCloseWithDuration();
        }
    }
    ErrorSnackBarComponent.ɵfac = function ErrorSnackBarComponent_Factory(t) { return new (t || ErrorSnackBarComponent)(i0.ɵɵdirectiveInject(MAT_SNACK_BAR_DATA), i0.ɵɵdirectiveInject(i1.MatSnackBarRef)); };
    ErrorSnackBarComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ErrorSnackBarComponent, selectors: [["ho-error-snack-bar"]], decls: 11, vars: 2, consts: [["fxLayout", "row nowrap", "fxLayoutAlign", "flex-start center", "fxLayoutGap", "10px", 1, "snack-container", "toast-error", 3, "mouseenter", "mouseleave"], [1, "icon-times-circle", "snack-container__icon"], ["fxLayout", "column"], [1, "snack-container__icon", "snack-container__close-btn", 3, "click"], [1, "icon-close"]], template: function ErrorSnackBarComponent_Template(rf, ctx) {
            if (rf & 1) {
                i0.ɵɵelementStart(0, "div", 0);
                i0.ɵɵlistener("mouseenter", function ErrorSnackBarComponent_Template_div_mouseenter_0_listener() { return ctx.onHover(); })("mouseleave", function ErrorSnackBarComponent_Template_div_mouseleave_0_listener() { return ctx.onLeave(); });
                i0.ɵɵelement(1, "i", 1);
                i0.ɵɵelementStart(2, "div", 2);
                i0.ɵɵelementStart(3, "div");
                i0.ɵɵelementStart(4, "b");
                i0.ɵɵtext(5);
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(6, "div");
                i0.ɵɵelementStart(7, "span");
                i0.ɵɵtext(8);
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(9, "button", 3);
                i0.ɵɵlistener("click", function ErrorSnackBarComponent_Template_button_click_9_listener() { return ctx.onClose(); });
                i0.ɵɵelement(10, "span", 4);
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
            }
            if (rf & 2) {
                i0.ɵɵadvance(5);
                i0.ɵɵtextInterpolate(ctx.data == null ? null : ctx.data.title);
                i0.ɵɵadvance(3);
                i0.ɵɵtextInterpolate(ctx.data == null ? null : ctx.data.message);
            }
        }, directives: [i2.DefaultLayoutDirective, i2.DefaultLayoutAlignDirective, i2.DefaultLayoutGapDirective], styles: [".toast-info[_ngcontent-%COMP%]{background-color:var(--primary)}.toast-error[_ngcontent-%COMP%]{background-color:var(--danger)}.toast-warning[_ngcontent-%COMP%]{background-color:var(--warning);background-image:none}.toast-success[_ngcontent-%COMP%]{background-color:var(--success)}.toast-accent[_ngcontent-%COMP%]{background-color:var(--accent)}.toast-remove[_ngcontent-%COMP%]{background-color:var(--info)}"] });
    return ErrorSnackBarComponent;
})();

