import { NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Settings } from 'luxon';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ho/shared/data-access/session";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../libs/shared/widgets/google-analytics/src/lib/components/google-analytics/google-analytics.component";
export let AppComponent = /*@__PURE__*/ (() => {
    class AppComponent {
        constructor(_router, _authService, _translate) {
            this._router = _router;
            this._authService = _authService;
            this._translate = _translate;
            this._showAppLoader = true;
            this._dBlock = true;
        }
        ngOnInit() {
            // Set UTC as default local timezone (Luxon)
            Settings.defaultZoneName = 'utc';
            this._initAppLoader();
            // this._translate.addLangs(['en', 'ru']);
            // this._translate.currentLoader.getTranslation('ru');
            // this._translate.use('ru');
        }
        _initAppLoader() {
            this._router.events.subscribe((evt) => {
                if (evt instanceof NavigationStart) {
                    this._showAppLoader = this._dBlock = true;
                }
                if (evt instanceof NavigationEnd || evt instanceof NavigationCancel) {
                    this._showAppLoader = this._dBlock = false;
                }
            });
        }
    }
    AppComponent.ɵfac = function AppComponent_Factory(t) { return new (t || AppComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.SessionService), i0.ɵɵdirectiveInject(i3.TranslateService)); };
    AppComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AppComponent, selectors: [["ho-root"]], hostVars: 4, hostBindings: function AppComponent_HostBindings(rf, ctx) {
            if (rf & 2) {
                i0.ɵɵclassProp("loading", ctx._showAppLoader)("d-block", ctx._dBlock);
            }
        }, decls: 2, vars: 0, template: function AppComponent_Template(rf, ctx) {
            if (rf & 1) {
                i0.ɵɵelement(0, "router-outlet");
                i0.ɵɵelement(1, "ho-google-analytics");
            }
        }, directives: [i1.RouterOutlet, i4.GoogleAnalyticsComponent], styles: [""] });
    return AppComponent;
})();

