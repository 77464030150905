import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CampaignRequestStatuses = /*@__PURE__*/ (function (CampaignRequestStatuses) {
    CampaignRequestStatuses[CampaignRequestStatuses["Pending"] = 0] = "Pending";
    CampaignRequestStatuses[CampaignRequestStatuses["Rejected"] = 1] = "Rejected";
    CampaignRequestStatuses[CampaignRequestStatuses["Approved"] = 2] = "Approved";
    return CampaignRequestStatuses;
})({});
export const CampaignRequestStatusesMap = new Map([
    [CampaignRequestStatuses.Pending, 'Global.Status.Pending'],
    [CampaignRequestStatuses.Rejected, 'Global.Status.Rejected'],
    [CampaignRequestStatuses.Approved, 'Global.Status.Approved'],
]);
export let CampaignRequestStatusesService = /*@__PURE__*/ (() => {
    class CampaignRequestStatusesService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CampaignRequestStatusesMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    CampaignRequestStatusesService.ɵfac = function CampaignRequestStatusesService_Factory(t) { return new (t || CampaignRequestStatusesService)(i0.ɵɵinject(i1.TranslateService)); };
    CampaignRequestStatusesService.ɵprov = i0.ɵɵdefineInjectable({ token: CampaignRequestStatusesService, factory: CampaignRequestStatusesService.ɵfac });
    return CampaignRequestStatusesService;
})();

