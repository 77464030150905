import { DashboardWidgetView, SortDirection } from '../enums/dashboard-settings.enum';
import { DashboardWidget, DashboardWidgetIdsForAff } from './dashboard-widget.enum';
import { AffiliateWidgetTab } from '@ho/shared/data-access/chart';
export const DEFAULT_DASHBOARD_SETTINGS = {
    [DashboardWidget.Visible]: DashboardWidgetIdsForAff.reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {}),
    [DashboardWidget.AdminAtlProfitWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Profit.Label',
    },
    [DashboardWidget.AdminAtlRevenueWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Revenue.Label',
    },
    [DashboardWidget.AdminAtlCommissionWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Commission.Label',
    },
    [DashboardWidget.AdminAtlClicksWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.MetricWidget.Clicks.Label',
    },
    [DashboardWidget.AdminAtlLeadsWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Leads.Label',
    },
    [DashboardWidget.AdminAtlSaleWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Sales.Label',
    },
    [DashboardWidget.AdminAtlCRWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.CR.Label',
    },
    [DashboardWidget.AdminAtlROIWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.ROI.Label',
    },
    [DashboardWidget.AdminAtlEPCWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.EPC.Label',
    },
    [DashboardWidget.AdminAtlAffiliatesWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.MetricWidget.CL.Label',
        tab: AffiliateWidgetTab.New,
    },
    [DashboardWidget.AdminAmAtlCountriesWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.CountriesWidget.Label',
        type: DashboardWidgetView.Table,
        performance_by: 'epc',
    },
    [DashboardWidget.AdminAtlManagersWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.ManagersWidget.Label',
        sort: 'sale_count',
        sort_direction: SortDirection.Top,
        performance_by: 'sale_count',
        type: DashboardWidgetView.Table,
        in_favorite: false,
    },
    [DashboardWidget.AdminAmAtlOfferGroupsAffiliateLanguagesWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.OffersAffiliatesLanguagesWidget.Label',
        sort_direction: SortDirection.Top,
        type: DashboardWidgetView.Table,
        in_favorite: false,
        performance_by: 'epc',
        sort: 'sale_count',
    },
    [DashboardWidget.AmProfitWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Profit.Label',
    },
    [DashboardWidget.AmRevenueWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Revenue.Label',
    },
    [DashboardWidget.AmCommissionWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Commission.Label',
    },
    [DashboardWidget.AmClicksWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.MetricWidget.Clicks.Label',
    },
    [DashboardWidget.AmLeadsWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Leads.Label',
    },
    [DashboardWidget.AmSaleWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Sales.Label',
    },
    [DashboardWidget.AmCRWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.CR.Label',
    },
    [DashboardWidget.AmROIWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.ROI.Label',
    },
    [DashboardWidget.AmEPCWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.EPC.Label',
    },
    [DashboardWidget.AmAffiliatesWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.AffiliateWidget.Title',
    },
    [DashboardWidget.AdminAmAtlCountriesWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.CountriesWidget.Label',
        type: DashboardWidgetView.Table,
        performance_by: 'epc',
    },
    [DashboardWidget.AdminAmAtlOfferGroupsAffiliateLanguagesWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.OffersAffiliatesLanguagesWidget.Label',
        sort_direction: SortDirection.Top,
        type: DashboardWidgetView.Table,
        in_favorite: false,
        performance_by: 'epc',
        sort: 'sale_count',
    },
    [DashboardWidget.AffCommissionWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.MetricWidget.Commission.Label',
    },
    [DashboardWidget.AffClicksWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Clicks.Label',
    },
    [DashboardWidget.AffLeadsWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Leads.Label',
    },
    [DashboardWidget.AffSaleWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.Sales.Label',
    },
    [DashboardWidget.AffCRWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.CR.Label',
    },
    [DashboardWidget.AffCLWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.CL.Label',
    },
    [DashboardWidget.AffEPCWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.MetricWidget.EPC.Label',
    },
    [DashboardWidget.AffPersonalAffiliateManagerWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.PersonalManager.Label',
    },
    [DashboardWidget.AffProfitWidget]: {
        version: 1,
        label: 'Dashboard.Widgets.ProfitWidget.Label',
        sort_direction: SortDirection.Top,
        type: DashboardWidgetView.Table,
        in_favorite: false,
        sort: 'sale_count',
    },
    [DashboardWidget.AffPendingPayoutsWidget]: {
        version: 2,
        label: 'Pending Payouts',
    },
    [DashboardWidget.AffBillingInfoWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.BillingInfo.Label',
    },
    [DashboardWidget.AdminAtlClicksByAffWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.PieChart.ClicksLabel',
    },
    [DashboardWidget.AdminAtlLeadsByAffWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.PieChart.LeadsLabel',
    },
    [DashboardWidget.AdminAtlSalesByAffWidget]: {
        version: 2,
        label: 'Dashboard.Widgets.PieChart.SalesLabel',
    },
};

