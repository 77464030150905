import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var IntegrationStatus = /*@__PURE__*/ (function (IntegrationStatus) {
    IntegrationStatus[IntegrationStatus["NoPostback"] = 0] = "NoPostback";
    IntegrationStatus[IntegrationStatus["ActivePostback"] = 1] = "ActivePostback";
    return IntegrationStatus;
})({});
export const IntegrationStatusMap = new Map([
    [IntegrationStatus.NoPostback, 'RegistrationsStats.IntegrationStatus.NoPostback'],
    [IntegrationStatus.ActivePostback, 'RegistrationsStats.IntegrationStatus.ActivePostback'],
]);
export let IntegrationStatusService = /*@__PURE__*/ (() => {
    class IntegrationStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = IntegrationStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case IntegrationStatus.ActivePostback:
                    return 'text-success';
                case IntegrationStatus.NoPostback:
                    return 'text-danger';
                default:
                    return 'text-warning';
            }
        }
    }
    IntegrationStatusService.ɵfac = function IntegrationStatusService_Factory(t) { return new (t || IntegrationStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    IntegrationStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: IntegrationStatusService, factory: IntegrationStatusService.ɵfac });
    return IntegrationStatusService;
})();

