import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var FlowStatus = /*@__PURE__*/ (function (FlowStatus) {
    FlowStatus[FlowStatus["Draft"] = -2] = "Draft";
    FlowStatus[FlowStatus["Deleted"] = -1] = "Deleted";
    FlowStatus[FlowStatus["Active"] = 1] = "Active";
    return FlowStatus;
})({});
export const FlowStatusLabels = new Map([
    [FlowStatus.Draft, 'Global.Options.FlowStatusLabels.Draft'],
    [FlowStatus.Deleted, 'Global.Status.Deleted'],
    [FlowStatus.Active, 'Global.Status.Active'],
]);
export let FlowStatusService = /*@__PURE__*/ (() => {
    class FlowStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = FlowStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    FlowStatusService.ɵfac = function FlowStatusService_Factory(t) { return new (t || FlowStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    FlowStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: FlowStatusService, factory: FlowStatusService.ɵfac });
    return FlowStatusService;
})();

