import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PaymentMethod = /*@__PURE__*/ (function (PaymentMethod) {
    // PayPal = 1,
    PaymentMethod[PaymentMethod["BankWire"] = 1] = "BankWire";
    return PaymentMethod;
})({});
export const PaymentMethodMap = new Map([
    [PaymentMethod.BankWire, 'Global.Options.PaymentMethod.BankWire'],
]);
export let PaymentMethodService = /*@__PURE__*/ (() => {
    class PaymentMethodService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PaymentMethodMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    PaymentMethodService.ɵfac = function PaymentMethodService_Factory(t) { return new (t || PaymentMethodService)(i0.ɵɵinject(i1.TranslateService)); };
    PaymentMethodService.ɵprov = i0.ɵɵdefineInjectable({ token: PaymentMethodService, factory: PaymentMethodService.ɵfac });
    return PaymentMethodService;
})();

