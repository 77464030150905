import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var LanguageStatus = /*@__PURE__*/ (function (LanguageStatus) {
    LanguageStatus[LanguageStatus["Deactivated"] = -1] = "Deactivated";
    LanguageStatus[LanguageStatus["Active"] = 1] = "Active";
    return LanguageStatus;
})({});
export const LanguageStatusMap = new Map([
    [LanguageStatus.Deactivated, 'Global.Options.LanguageStatus.Deactivated'],
    [LanguageStatus.Active, 'Global.Status.Active'],
]);
export let LanguageStatusService = /*@__PURE__*/ (() => {
    class LanguageStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = LanguageStatusMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        getStyleClass(status) {
            switch (status) {
                case LanguageStatus.Active:
                    return 'text-success';
                case LanguageStatus.Deactivated:
                    return 'text-danger';
                default:
                    return '';
            }
        }
    }
    LanguageStatusService.ɵfac = function LanguageStatusService_Factory(t) { return new (t || LanguageStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    LanguageStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: LanguageStatusService, factory: LanguageStatusService.ɵfac });
    return LanguageStatusService;
})();

