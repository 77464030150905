import { TranslateModule } from '@ngx-translate/core';
import { DashboardVisibleConfigModalService } from './services/dashboard-visible-config-modal.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
import { MobileTooltipHandlerModule } from '@ho/shared/ui/directives';
import * as i0 from "@angular/core";
export let DashboardVisibleConfigModalModule = /*@__PURE__*/ (() => {
    class DashboardVisibleConfigModalModule {
    }
    DashboardVisibleConfigModalModule.ɵmod = i0.ɵɵdefineNgModule({ type: DashboardVisibleConfigModalModule });
    DashboardVisibleConfigModalModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DashboardVisibleConfigModalModule_Factory(t) { return new (t || DashboardVisibleConfigModalModule)(); }, providers: [DashboardVisibleConfigModalService], imports: [[
                CommonModule,
                MatDialogModule,
                MatButtonModule,
                MatCheckboxModule,
                ReactiveFormsModule,
                MatListModule,
                MatTooltipModule,
                MobileTooltipHandlerModule,
                FlexLayoutModule,
                QaAttributeWidgetModule,
                TranslateModule,
            ]] });
    return DashboardVisibleConfigModalModule;
})();

