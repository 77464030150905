import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './services/snackbar.service';
import * as i0 from "@angular/core";
export let SnackBarModule = /*@__PURE__*/ (() => {
    class SnackBarModule {
    }
    SnackBarModule.ɵmod = i0.ɵɵdefineNgModule({ type: SnackBarModule });
    SnackBarModule.ɵinj = i0.ɵɵdefineInjector({ factory: function SnackBarModule_Factory(t) { return new (t || SnackBarModule)(); }, providers: [SnackbarService], imports: [[CommonModule, MatSnackBarModule, FlexLayoutModule]] });
    return SnackBarModule;
})();

