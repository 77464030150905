import { of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export let UiSettingsApiService = /*@__PURE__*/ (() => {
    class UiSettingsApiService {
        constructor(_httpClient) {
            this._httpClient = _httpClient;
            this._endpointUrl = '/me/ui-settings';
        }
        getUiSettings() {
            return this._httpClient.get(this._endpointUrl).pipe(pluck('data', 'ui_settings'));
        }
        saveGridSettings(body) {
            if (!body.length) {
                return of({});
            }
            return this._httpClient.put(`${this._endpointUrl}/grid`, body);
        }
        saveDashboardSettings(body) {
            if (!Object.keys(body.ui_settings).length) {
                return of({});
            }
            return this._httpClient.put(`${this._endpointUrl}/dashboard`, {
                dashboard_id: body.dashboard_id,
                ui_settings: body.ui_settings,
            });
        }
        saveModalSettings(body) {
            if (!Object.keys(body.modal_settings).length) {
                return of({});
            }
            return this._httpClient.put(`${this._endpointUrl}/modal`, {
                modal_id: body.modal_id,
                ui_settings: body.modal_settings,
            });
        }
        updateColorSchemes(schemes) {
            return this._httpClient.put(`${this._endpointUrl}/color-schemes`, schemes);
        }
        skipTutorial() {
            return this._httpClient.put(`${this._endpointUrl}/tutorial`, { skip_tutorial: true });
        }
        updateConfigSettings(configSettings) {
            if (!Object.keys(configSettings).length) {
                return of({});
            }
            const body = {
                config_settings: configSettings,
            };
            return this._httpClient.put(`${this._endpointUrl}/config`, body);
        }
    }
    UiSettingsApiService.ɵfac = function UiSettingsApiService_Factory(t) { return new (t || UiSettingsApiService)(i0.ɵɵinject(i1.HttpClient)); };
    UiSettingsApiService.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsApiService, factory: UiSettingsApiService.ɵfac });
    return UiSettingsApiService;
})();

