import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ContactTodayReason = /*@__PURE__*/ (function (ContactTodayReason) {
    ContactTodayReason["NoLeads"] = "no_lead_24h";
    ContactTodayReason["NoFTDs"] = "no_ftd_24h";
    ContactTodayReason["CTLIncrease"] = "ctl_increase_24h";
    ContactTodayReason["CRIncrease"] = "cr_increase_24h";
    ContactTodayReason["EPCIncrease"] = "epc_increase_24h";
    ContactTodayReason["CTLDecrease"] = "ctl_decrease_24h";
    ContactTodayReason["CRDecrease"] = "cr_decrease_24h";
    ContactTodayReason["EPCDecrease"] = "epc_decrease_24h";
    ContactTodayReason["TrafficHalt"] = "no_traffic_24h";
    ContactTodayReason["NewGeoStartedClick"] = "new_geo_started_click";
    ContactTodayReason["NewGeoStartedLead"] = "new_geo_started_lead";
    ContactTodayReason["NegativeLeadsIncrease"] = "lds_negative_status_increase_24h";
    ContactTodayReason["FirstCampaignStarted"] = "first_campaign_started";
    ContactTodayReason["TrafficOnTheWeekend"] = "traffic_on_weekend";
    ContactTodayReason["WeeklyFTDCompare"] = "weekly_ftd_compare";
    ContactTodayReason["WeeklyEPCCompare"] = "weekly_epc_compare";
    ContactTodayReason["MonthlyFTDCompare"] = "monthly_ftd_compare";
    ContactTodayReason["MonthlyEPCCompare"] = "monthly_epc_compare";
    return ContactTodayReason;
})({});
export const ContactTodayReasonMap = new Map([
    [ContactTodayReason.NoLeads, 'Global.Options.ContactTodayReason.NoLeads'],
    [ContactTodayReason.NoFTDs, 'Global.Options.ContactTodayReason.NoFTDs'],
    [ContactTodayReason.CTLIncrease, 'Global.Options.ContactTodayReason.CTLIncrease'],
    [ContactTodayReason.CRIncrease, 'Global.Options.ContactTodayReason.CRIncrease'],
    [ContactTodayReason.EPCIncrease, 'Global.Options.ContactTodayReason.EPCIncrease'],
    [ContactTodayReason.CTLDecrease, 'Global.Options.ContactTodayReason.CTLDecrease'],
    [ContactTodayReason.CRDecrease, 'Global.Options.ContactTodayReason.CRDecrease'],
    [ContactTodayReason.EPCDecrease, 'Global.Options.ContactTodayReason.EPCDecrease'],
    [ContactTodayReason.TrafficHalt, 'Global.Options.ContactTodayReason.TrafficHalt'],
    [ContactTodayReason.NewGeoStartedClick, 'Global.Options.ContactTodayReason.NewGeoStartedClick'],
    [ContactTodayReason.NewGeoStartedLead, 'Global.Options.ContactTodayReason.NewGeoStartedLead'],
    [ContactTodayReason.NegativeLeadsIncrease, 'Global.Options.ContactTodayReason.NegativeLeadsIncrease'],
    [ContactTodayReason.FirstCampaignStarted, 'Global.Options.ContactTodayReason.FirstCampaignStarted'],
    [ContactTodayReason.TrafficOnTheWeekend, 'Global.Options.ContactTodayReason.TrafficOnTheWeekend'],
    [ContactTodayReason.WeeklyFTDCompare, 'Global.Options.ContactTodayReason.WeeklyFTDCompare'],
    [ContactTodayReason.WeeklyEPCCompare, 'Global.Options.ContactTodayReason.WeeklyEPCCompare'],
    [ContactTodayReason.MonthlyFTDCompare, 'Global.Options.ContactTodayReason.MonthlyFTDCompare'],
    [ContactTodayReason.MonthlyEPCCompare, 'Global.Options.ContactTodayReason.MonthlyEPCCompare'],
]);
export let ContactTodayReasonService = /*@__PURE__*/ (() => {
    class ContactTodayReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ContactTodayReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        has(key) {
            return this._data.has(key);
        }
    }
    ContactTodayReasonService.ɵfac = function ContactTodayReasonService_Factory(t) { return new (t || ContactTodayReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    ContactTodayReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: ContactTodayReasonService, factory: ContactTodayReasonService.ɵfac });
    return ContactTodayReasonService;
})();

