import { DOCUMENT } from '@angular/common';
import { deviceInitializerProvider } from '@ho/libs/shared/initializers/device.initializer';
import { translationsInitializerProvider } from '@ho/libs/shared/initializers/translations.initializer';
import { CoreDataAccessModule } from '@ho/shared/data-access/core-data-access';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler } from '@angular/core';
import { ServiceWorkerInitializerProvider } from '@ho/libs/shared/initializers/service-worker.initializer';
import { MissingTranslationService, TranslateLoaderFactory, } from '@ho/shared/helpers/translate-loader-factory';
import { ConfirmModalModule } from '@ho/shared/ui/confirm-modal';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { GuestUserGuard } from '@ho/shared/data-access/core/lib/guards/guest-user.guard';
import { GoogleAnalyticsModule } from '@ho/shared/widgets/google-analytics';
import { SentryErrorHandler } from '@ho/shared/data-access/core/lib/services/sentry.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DataAccessUiSettingsModule } from '@ho/shared/data-access/ui-settings';
import { customAppConfigInitializerProvider } from '@ho/libs/shared/initializers/custom-app-config.initializer';
import { settingsInitializerProvider } from '@ho/libs/shared/initializers/settings.initializer';
import * as i0 from "@angular/core";
export const appRoutes = [
    { path: '', redirectTo: 'a', pathMatch: 'full' },
    {
        path: 'a',
        canActivate: [GuestUserGuard],
        loadChildren: () => import('@ho/auth/feature-shell').then((m) => m.AuthFeatureShellModule),
    },
    {
        path: '**',
        loadChildren: () => import('@ho/pages/error/feature-shell').then((m) => m.ErrorFeatureShellModule),
    },
];
export let AppModule = /*@__PURE__*/ (() => {
    class AppModule {
    }
    AppModule.ɵmod = i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] });
    AppModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AppModule_Factory(t) { return new (t || AppModule)(); }, providers: [
            { provide: AppEnvironment, useValue: environment },
            { provide: ErrorHandler, useClass: SentryErrorHandler },
            deviceInitializerProvider,
            ServiceWorkerInitializerProvider,
            customAppConfigInitializerProvider,
            settingsInitializerProvider,
            translationsInitializerProvider,
        ], imports: [[
                BrowserModule,
                BrowserAnimationsModule,
                HttpClientModule,
                RouterModule.forRoot(appRoutes, {
                    useHash: false,
                    scrollPositionRestoration: 'top',
                    relativeLinkResolution: 'legacy',
                }),
                CoreDataAccessModule,
                GoogleAnalyticsModule,
                ConfirmModalModule,
                ServiceWorkerModule.register('ngsw-worker.js', {
                    enabled: environment.production,
                    registrationStrategy: 'registerWithDelay:5000',
                }),
                DataAccessUiSettingsModule,
                TranslateModule.forRoot({
                    loader: {
                        provide: TranslateLoader,
                        useFactory: TranslateLoaderFactory,
                        deps: [HttpClient, DOCUMENT],
                    },
                    isolate: false,
                    missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
                }),
            ]] });
    return AppModule;
})();

