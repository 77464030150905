import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ContactTodayPriority = /*@__PURE__*/ (function (ContactTodayPriority) {
    ContactTodayPriority[ContactTodayPriority["Low"] = 1] = "Low";
    ContactTodayPriority[ContactTodayPriority["Medium"] = 2] = "Medium";
    ContactTodayPriority[ContactTodayPriority["High"] = 3] = "High";
    return ContactTodayPriority;
})({});
export const ContactTodayPriorityLabels = new Map([
    [ContactTodayPriority.Low, 'Global.Options.ContactTodayPriorityLabels.Low'],
    [ContactTodayPriority.Medium, 'Global.Options.ContactTodayPriorityLabels.Medium'],
    [ContactTodayPriority.High, 'Global.Options.ContactTodayPriorityLabels.High'],
]);
export let ContactTodayPriorityService = /*@__PURE__*/ (() => {
    class ContactTodayPriorityService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ContactTodayPriorityLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    ContactTodayPriorityService.ɵfac = function ContactTodayPriorityService_Factory(t) { return new (t || ContactTodayPriorityService)(i0.ɵɵinject(i1.TranslateService)); };
    ContactTodayPriorityService.ɵprov = i0.ɵɵdefineInjectable({ token: ContactTodayPriorityService, factory: ContactTodayPriorityService.ɵfac });
    return ContactTodayPriorityService;
})();

