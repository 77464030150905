import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ResponseStatus = /*@__PURE__*/ (function (ResponseStatus) {
    ResponseStatus[ResponseStatus["Error"] = 0] = "Error";
    ResponseStatus[ResponseStatus["Success"] = 1] = "Success";
    return ResponseStatus;
})({});
export const ResponseStatusMap = new Map([
    [ResponseStatus.Error, 'Global.Options.ResponseStatus.Error'],
    [ResponseStatus.Success, 'Global.Options.ResponseStatus.Success'],
]);
export let ResponseStatusService = /*@__PURE__*/ (() => {
    class ResponseStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ResponseStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    ResponseStatusService.ɵfac = function ResponseStatusService_Factory(t) { return new (t || ResponseStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    ResponseStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: ResponseStatusService, factory: ResponseStatusService.ɵfac });
    return ResponseStatusService;
})();

