import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PayoutStatus = /*@__PURE__*/ (function (PayoutStatus) {
    PayoutStatus[PayoutStatus["PENDING_ID"] = 0] = "PENDING_ID";
    PayoutStatus[PayoutStatus["AWAITING_INVOICE_ID"] = 1] = "AWAITING_INVOICE_ID";
    PayoutStatus[PayoutStatus["COMPLETED_ID"] = 2] = "COMPLETED_ID";
    PayoutStatus[PayoutStatus["BOUNCED_ID"] = 3] = "BOUNCED_ID";
    PayoutStatus[PayoutStatus["CANCELLED_ID"] = 4] = "CANCELLED_ID";
    PayoutStatus[PayoutStatus["INVOICE_RECEIVED_ID"] = 5] = "INVOICE_RECEIVED_ID";
    PayoutStatus[PayoutStatus["IN_PROGRESS_ID"] = 6] = "IN_PROGRESS_ID";
    PayoutStatus[PayoutStatus["INVOICE_CONFIRMED_iD"] = 7] = "INVOICE_CONFIRMED_iD";
    PayoutStatus[PayoutStatus["PAYMENT_SENT_ID"] = 8] = "PAYMENT_SENT_ID";
    PayoutStatus[PayoutStatus["MODERATED_ID"] = 9] = "MODERATED_ID";
    return PayoutStatus;
})({});
export const PayoutStatusesMap = new Map([
    [PayoutStatus.PENDING_ID, 'Global.Status.Pending'],
    [PayoutStatus.AWAITING_INVOICE_ID, 'Global.Options.PayoutStatuses.AwaitingInvoice'],
    [PayoutStatus.INVOICE_RECEIVED_ID, 'Global.Options.PayoutStatuses.InvoiceReceived'],
    [PayoutStatus.IN_PROGRESS_ID, 'Global.Options.PayoutStatuses.InProgress'],
    [PayoutStatus.COMPLETED_ID, 'Global.Options.PayoutStatuses.Completed'],
    [PayoutStatus.CANCELLED_ID, 'Global.Options.PayoutStatuses.Cancelled'],
    [PayoutStatus.BOUNCED_ID, 'Global.Options.PayoutStatuses.Bounced'],
    [PayoutStatus.INVOICE_CONFIRMED_iD, 'Global.Options.PayoutStatuses.InvoiceConfirmed'],
    [PayoutStatus.PAYMENT_SENT_ID, 'Global.Options.PayoutStatuses.PaymentSent'],
    [PayoutStatus.MODERATED_ID, 'Global.Options.PayoutStatuses.Moderated'],
]);
export let PayoutStatusService = /*@__PURE__*/ (() => {
    class PayoutStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PayoutStatusesMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    PayoutStatusService.ɵfac = function PayoutStatusService_Factory(t) { return new (t || PayoutStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    PayoutStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: PayoutStatusService, factory: PayoutStatusService.ɵfac });
    return PayoutStatusService;
})();

