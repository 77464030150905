import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var SalesStatus = /*@__PURE__*/ (function (SalesStatus) {
    SalesStatus[SalesStatus["NotPayable"] = 0] = "NotPayable";
    SalesStatus[SalesStatus["Payable"] = 1] = "Payable";
    return SalesStatus;
})({});
export const SalesStatusMap = new Map([
    [SalesStatus.NotPayable, 'Grid.Filter.Option.ShowNotPayable'],
    [SalesStatus.Payable, 'Grid.Filter.Option.ShowPayable'],
]);
export let SalesStatusService = /*@__PURE__*/ (() => {
    class SalesStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = SalesStatusMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    SalesStatusService.ɵfac = function SalesStatusService_Factory(t) { return new (t || SalesStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    SalesStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: SalesStatusService, factory: SalesStatusService.ɵfac });
    return SalesStatusService;
})();

