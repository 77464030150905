import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PixelProcessingLogStatus = /*@__PURE__*/ (function (PixelProcessingLogStatus) {
    PixelProcessingLogStatus[PixelProcessingLogStatus["Processed"] = 1] = "Processed";
    PixelProcessingLogStatus[PixelProcessingLogStatus["Failed"] = 2] = "Failed";
    return PixelProcessingLogStatus;
})({});
export const PixelProcessingLogStatusMap = new Map([
    [PixelProcessingLogStatus.Processed, 'Global.Options.PixelProcessingLogStatus.Processed'],
    [PixelProcessingLogStatus.Failed, 'Global.Options.PixelProcessingLogStatus.Failed'],
]);
export let PixelProcessingLogStatusService = /*@__PURE__*/ (() => {
    class PixelProcessingLogStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PixelProcessingLogStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    PixelProcessingLogStatusService.ɵfac = function PixelProcessingLogStatusService_Factory(t) { return new (t || PixelProcessingLogStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    PixelProcessingLogStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: PixelProcessingLogStatusService, factory: PixelProcessingLogStatusService.ɵfac });
    return PixelProcessingLogStatusService;
})();

