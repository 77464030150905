import { __decorate, __metadata } from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import { DEFAULT_DASHBOARD_SETTINGS } from '../constants/dashboard-default-config';
import { cloneDeep, merge } from 'lodash-es';
import * as i0 from "@angular/core";
let UiSettingsStore = /*@__PURE__*/ (() => {
    let UiSettingsStore = class UiSettingsStore extends Store {
        constructor() {
            super({});
        }
        updateUiSettings(settings) {
            const model = {
                grid_settings: settings.grid_settings || {},
                dashboard_settings: cloneDeep(merge(DEFAULT_DASHBOARD_SETTINGS, this._mergeDashboardSettingsWithDefaultConfig(DEFAULT_DASHBOARD_SETTINGS, settings.dashboard_settings || {})) || {}),
                modal_settings: settings.modal_settings || {},
                theme_settings: settings.theme_settings || { selected_color_scheme: 'Light' },
                tutorial_settings: settings.tutorial_settings || { skip_tutorial: false },
                config_settings: settings.config_settings || {},
            };
            this.update((state) => (Object.assign(Object.assign({}, state), model)));
        }
        updateGridSettings(gridSettings = {}) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { grid_settings: merge(Object.assign(Object.assign({}, state.grid_settings), gridSettings)) });
            });
        }
        updateDashboardSettings(dashboardSettings = {}) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { dashboard_settings: cloneDeep(merge(DEFAULT_DASHBOARD_SETTINGS, state.dashboard_settings, dashboardSettings)) });
            });
        }
        updateModalSettings(modalSettings = {}) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { modal_settings: Object.assign(Object.assign({}, cloneDeep(state.modal_settings)), modalSettings) });
            });
        }
        updateThemeSettings(themeSettings) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { theme_settings: Object.assign(Object.assign({}, state.theme_settings), themeSettings) });
            });
        }
        updateTutorialSettings(tutorialSettings) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { tutorial_settings: Object.assign(Object.assign({}, state.tutorial_settings), tutorialSettings) });
            });
            localStorage.setItem('skip_tutorial', tutorialSettings.skip_tutorial.toString());
        }
        updateConfigSettings(configSettings = {}) {
            this.update((state) => {
                return Object.assign(Object.assign({}, state), { config_settings: Object.assign(Object.assign({}, state.config_settings), configSettings) });
            });
        }
        _mergeDashboardSettingsWithDefaultConfig(defaultDashboardSettings, dashboardSettingsFromApi) {
            return Object.keys(defaultDashboardSettings).reduce((acc, key) => {
                acc[key] =
                    defaultDashboardSettings[key] &&
                        dashboardSettingsFromApi[key] &&
                        defaultDashboardSettings[key].version === dashboardSettingsFromApi[key].version
                        ? dashboardSettingsFromApi[key]
                        : defaultDashboardSettings[key];
                return acc;
            }, {});
        }
    };
    UiSettingsStore.ɵfac = function UiSettingsStore_Factory(t) { return new (t || UiSettingsStore)(); };
    UiSettingsStore.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsStore, factory: UiSettingsStore.ɵfac });
    UiSettingsStore = __decorate([
        StoreConfig({ name: StoreNames.UiSettings }),
        __metadata("design:paramtypes", [])
    ], UiSettingsStore);
    return UiSettingsStore;
})();
export { UiSettingsStore };

