import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
// On API used boolean type, only for select converting to enum. Use only for select
export var TestStatus = /*@__PURE__*/ (function (TestStatus) {
    TestStatus[TestStatus["True"] = 1] = "True";
    TestStatus[TestStatus["False"] = 0] = "False";
    return TestStatus;
})({});
// Use only for select
export const TestStatusMap = new Map([
    [TestStatus.True, 'Report.Text.True'],
    [TestStatus.False, 'Report.Text.False'],
]);
export let TestStatusService = /*@__PURE__*/ (() => {
    class TestStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TestStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(+status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            return status ? 'text-danger' : '';
        }
    }
    TestStatusService.ɵfac = function TestStatusService_Factory(t) { return new (t || TestStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    TestStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: TestStatusService, factory: TestStatusService.ɵfac });
    return TestStatusService;
})();

