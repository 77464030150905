import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Observable, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map as _map } from 'lodash-es';
import { parseQueryParams, prepareQueryParams } from '@ho/libs/shared/services/query-params.helper';
import * as i0 from "@angular/core";
import * as i1 from "@ho/shared/data-access/session";
import * as i2 from "@angular/router";
import * as i3 from "@ho/shared/ui/snack-bar";
export let AuthGuardService = /*@__PURE__*/ (() => {
    class AuthGuardService {
        constructor(_authQuery, _authService, _router, _snack, _sessionUserTypeService, environment, document) {
            this._authQuery = _authQuery;
            this._authService = _authService;
            this._router = _router;
            this._snack = _snack;
            this._sessionUserTypeService = _sessionUserTypeService;
            this.environment = environment;
            this.document = document;
        }
        canLoad(route, segments) {
            this.requestParams = parseQueryParams(this.document.location.search.replace('?', ''));
            const path = (this.requestUrl = _map(segments, 'path').join('/'));
            return Observable.create((observer) => {
                const idToken = localStorage.getItem(this.environment.jwtTokenName);
                if (!this._authQuery.isLoggedIn()) {
                    if (idToken) {
                        return this._autologin(path, observer, idToken);
                    }
                    else {
                        this._authService.logout();
                        return of(this._redirectToLogin(observer));
                    }
                }
                else {
                    if (!this._authQuery.userInfo()) {
                        return this._autologin(path, observer, idToken || this._authQuery.token());
                    }
                    else {
                        if (!this._appropriateRole()) {
                            return of(this._authService.switchApp(observer));
                        }
                        else if (idToken &&
                            this._authQuery.token() !== undefined &&
                            this._authQuery.token() !== idToken) {
                            return this._autologin(path, observer, idToken || this._authQuery.token());
                        }
                        else {
                            observer.next(true);
                            observer.complete();
                        }
                    }
                }
            });
        }
        _autologin(path, observer, idToken) {
            return this._authService.autologin(idToken).subscribe(() => {
                if (this._appropriateRole()) {
                    this._router.navigate([`/d/${path}`]);
                    observer.next(false);
                    observer.complete();
                }
                else {
                    this._authService.switchApp(observer);
                }
            });
        }
        _appropriateRole() {
            return this.environment.role.includes(this._authQuery.userInfo().workspace_role_id);
        }
        _redirectToLogin(observer) {
            if (!this.environment.production) {
                this._router.navigate(['/a/login'], {
                    queryParams: { requestUrl: this.requestUrl, requestParams: prepareQueryParams(this.requestParams) },
                });
            }
            else {
                this.document.location.href = `${location.origin}/v3/auth?requestUrl=${this.requestUrl}&requestParams=${prepareQueryParams(this.requestParams)}`;
            }
            observer.next(false);
            observer.complete();
        }
    }
    AuthGuardService.ɵfac = function AuthGuardService_Factory(t) { return new (t || AuthGuardService)(i0.ɵɵinject(i1.SessionQuery), i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SnackbarService), i0.ɵɵinject(i1.SessionUserTypeService), i0.ɵɵinject(AppEnvironment), i0.ɵɵinject(DOCUMENT)); };
    AuthGuardService.ɵprov = i0.ɵɵdefineInjectable({ token: AuthGuardService, factory: AuthGuardService.ɵfac });
    return AuthGuardService;
})();

