import { FormControl } from '@angular/forms';
import { UserWorkspaceRole } from '../../../../../../data-access/session/src/lib/state/session-user-type-service.service';
import { permissionsList } from '../../permission-settings.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { finalize, pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/permission-settings.service";
import * as i2 from "@angular/forms";
import * as i3 from "@ho/shared/ui/snack-bar";
import * as i4 from "@ho/libs/shared/services/permissions.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../../data-access/core/src/lib/+state/settings.service";
function PermissionsBlockListContainer_ng_container_2_ho_permissions_block_2_Template(rf, ctx) {
    if (rf & 1) {
        i0.ɵɵelement(0, "ho-permissions-block", 6);
    }
    if (rf & 2) {
        const ctx_r6 = i0.ɵɵnextContext();
        const permissionGroup_r2 = ctx_r6.$implicit;
        const i_r3 = ctx_r6.index;
        const ctx_r4 = i0.ɵɵnextContext();
        i0.ɵɵproperty("block", permissionGroup_r2)("permissionForm", ctx_r4.groupByIndex(i_r3));
    }
}
function PermissionsBlockListContainer_ng_container_2_p_3_Template(rf, ctx) {
    if (rf & 1) {
        i0.ɵɵelementStart(0, "p", 7);
        i0.ɵɵtext(1);
        i0.ɵɵpipe(2, "translate");
        i0.ɵɵelementEnd();
    }
    if (rf & 2) {
        const permissionGroup_r2 = i0.ɵɵnextContext().$implicit;
        i0.ɵɵproperty("hoQaAttributeWidget", "text_description");
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 2, permissionGroup_r2.description), " ");
    }
}
function PermissionsBlockListContainer_ng_container_2_Template(rf, ctx) {
    if (rf & 1) {
        i0.ɵɵelementContainerStart(0);
        i0.ɵɵelementStart(1, "div", 3);
        i0.ɵɵtemplate(2, PermissionsBlockListContainer_ng_container_2_ho_permissions_block_2_Template, 1, 2, "ho-permissions-block", 4);
        i0.ɵɵtemplate(3, PermissionsBlockListContainer_ng_container_2_p_3_Template, 3, 4, "p", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementContainerEnd();
    }
    if (rf & 2) {
        const permissionGroup_r2 = ctx.$implicit;
        const i_r3 = ctx.index;
        const ctx_r0 = i0.ɵɵnextContext();
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx_r0.groupByIndex(i_r3));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", permissionGroup_r2 == null ? null : permissionGroup_r2.description);
    }
}
function PermissionsBlockListContainer_div_3_Template(rf, ctx) {
    if (rf & 1) {
        const _r9 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 8);
        i0.ɵɵelementStart(1, "button", 9);
        i0.ɵɵlistener("click", function PermissionsBlockListContainer_div_3_Template_button_click_1_listener() { i0.ɵɵrestoreView(_r9); const ctx_r8 = i0.ɵɵnextContext(); return ctx_r8.onCancel(); });
        i0.ɵɵelement(2, "span", 10);
        i0.ɵɵtext(3);
        i0.ɵɵpipe(4, "translate");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "button", 11);
        i0.ɵɵlistener("click", function PermissionsBlockListContainer_div_3_Template_button_click_5_listener() { i0.ɵɵrestoreView(_r9); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.onSave(); });
        i0.ɵɵelement(6, "i", 12);
        i0.ɵɵtext(7);
        i0.ɵɵpipe(8, "translate");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    }
    if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("hoQaAttributeWidget", "button_cancel");
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 4, "Global.Btn.Cancel"), " ");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("hoQaAttributeWidget", "button_save");
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(8, 6, "Global.Btn.Save"), " ");
    }
}
const _c0 = [[["", "header-block", ""]]];
const _c1 = ["[header-block]"];
export let PermissionsBlockListContainer = /*@__PURE__*/ (() => {
    class PermissionsBlockListContainer {
        constructor(_service, _fb, _snack, _cdr, _permissionsService, _translate, _settings) {
            this._service = _service;
            this._fb = _fb;
            this._snack = _snack;
            this._cdr = _cdr;
            this._permissionsService = _permissionsService;
            this._translate = _translate;
            this._settings = _settings;
            this.form = this._fb.array([]);
            this._rolePermission = {
                [UserWorkspaceRole.Admin]: 'UI_PermissionSettings_ManageAdmin',
                [UserWorkspaceRole.ATL]: 'UI_PermissionSettings_ManageTL',
                [UserWorkspaceRole.AM]: 'UI_PermissionSettings_ManageManager',
                [UserWorkspaceRole.Aff]: 'UI_PermissionSettings_ManageAffiliate',
                [UserWorkspaceRole.AD]: 'UI_PermissionSettings_ManageAdvertiser',
            };
        }
        set role(value) {
            if (!!value) {
                this._role = value;
                const [hasPermission] = this._permissionsService.hasPermissions([this._rolePermission[value]]);
                this.hasPermissionToEdit = hasPermission;
            }
        }
        get role() {
            return this._role;
        }
        set userId(value) {
            if (!!value) {
                this._userId = value;
                this.setPermissionsForm();
            }
        }
        get userId() {
            return this._userId;
        }
        groupByIndex(index) {
            return this.form.get(index.toString());
        }
        onSave() {
            const activePermissions = [];
            this.loading = true;
            this.form.getRawValue().forEach((permissions) => {
                Object.keys(permissions).forEach((permissionName) => {
                    if (permissions[permissionName]) {
                        activePermissions.push(permissionName);
                    }
                });
            });
            if (this.role === UserWorkspaceRole.Aff) {
                activePermissions.push('UI_OfferCampaigns_View');
            }
            activePermissions.push('UI_FraudDetection_View');
            activePermissions.push('UI_FraudDetection_Explain');
            activePermissions.push('UI_Analyzer_View');
            activePermissions.push('UI_LeadStatus_View');
            activePermissions.push('UI_AffiliateCampaigns_Manage');
            activePermissions.push('UI_AffiliateCampaigns_Suspend');
            activePermissions.push('UI_AffiliateCampaigns_View');
            activePermissions.push('UI_AffiliatePixel_Manage');
            activePermissions.push('UI_ContactToday_Manage');
            activePermissions.push('UI_ContactToday_View');
            activePermissions.push('UI_ContactToday_Widget');
            activePermissions.push('UI_GlobalPixel_Manage');
            activePermissions.push('UI_GlobalPixel_View');
            activePermissions.push('UI_Logs_View');
            activePermissions.push('UI_OfferCampaigns_ManageBillingRules');
            activePermissions.push('UI_OfferCampaigns_ManageRevenueRules');
            this._service
                .setUserPermissions({
                user_id: this.userId,
                permissions: activePermissions,
            })
                .pipe(finalize(() => {
                this.loading = false;
                this._cdr.detectChanges();
            }), untilDestroyed(this))
                .subscribe((_) => {
                this._snack.success(this._translate.instant('Permission.Title.PermissionSettings'), this._translate.instant('Global.Notification.PermissionsUpdated.Success'));
                this.cachedUserPermissions = [...activePermissions];
                this.form.markAsPristine();
            }, (err) => {
                this._snack.error(this._translate.instant('Permission.Title.PermissionSettings'), this._translate.instant('Global.Notification.ReplaceDomain.Error'));
            });
        }
        onCancel() {
            this.permissionsList.forEach((rolePermission, key) => {
                this.permissionsList[key].permissions.forEach((permission) => {
                    const group = this.groupByIndex(key);
                    group.get(permission['key']).setValue(!!~this.cachedUserPermissions.indexOf(permission['key']));
                    group.markAsPristine();
                });
            });
        }
        ngOnDestroy() { }
        ngOnInit() {
            this.permissionsList = permissionsList
                .filter((item) => {
                return (((item.hasOwnProperty('feature') &&
                    this._settings.settings.workspace_features.includes(item.feature)) ||
                    !item.hasOwnProperty('feature')) &&
                    item.roles.includes(this.role));
            })
                .map((item) => {
                item.permissions = item.permissions.filter((permission) => !permission.hasOwnProperty('excludeRoles') ||
                    (permission.hasOwnProperty('excludeRoles') && !permission.excludeRoles.includes(this.role)));
                return item;
            });
        }
        setPermissionsForm() {
            this.loading = true;
            this._service
                .activeUserPermissions(this.userId)
                .pipe(finalize(() => {
                this.loading = false;
                this._cdr.detectChanges();
            }), untilDestroyed(this), pluck('data'))
                .subscribe((response) => {
                this._initForm(response);
            });
        }
        _initGroupedPermissionControlsSubscriptions(group, permissionsWIthChildren) {
            permissionsWIthChildren.forEach((permission) => {
                group
                    .get(permission['key'])
                    .valueChanges.pipe(untilDestroyed(this))
                    .subscribe((value) => {
                    permission.childrenPermissions.forEach((childPermissionKey) => {
                        if (group.get(childPermissionKey)) {
                            if (!value) {
                                group.get(childPermissionKey).setValue(value);
                            }
                            group.get(childPermissionKey)[value && this.hasPermissionToEdit ? 'enable' : 'disable']();
                        }
                    });
                });
                // FIXME  when dashboard for advertiser will be done
                if (this.role === UserWorkspaceRole.AD && permission['key'] === 'UI_OfferCampaigns_View') {
                    group.get(permission['key']).disable();
                    group.get(permission['key']).setValue(true);
                }
                group.get(permission['key']).updateValueAndValidity();
            });
        }
        _initForm(userPermissions) {
            this.cachedUserPermissions = [...userPermissions];
            this.form = this._fb.array([]);
            this.permissionsList.forEach((rolePermission, key) => {
                this.form.push(this._fb.group({}));
                const group = this.groupByIndex(key);
                const permissionsWIthChildren = [];
                rolePermission.permissions.forEach((permission) => {
                    group.addControl(permission['key'], new FormControl(userPermissions.includes(permission['key'])));
                    if (permission.childrenPermissions) {
                        permissionsWIthChildren.push(permission);
                    }
                    if (!this.hasPermissionToEdit) {
                        group.get(permission['key']).disable();
                    }
                });
                this._initGroupedPermissionControlsSubscriptions(group, permissionsWIthChildren);
            });
            this._cdr.detectChanges();
        }
    }
    PermissionsBlockListContainer.ɵfac = function PermissionsBlockListContainer_Factory(t) { return new (t || PermissionsBlockListContainer)(i0.ɵɵdirectiveInject(i1.PermissionSettingsService), i0.ɵɵdirectiveInject(i2.FormBuilder), i0.ɵɵdirectiveInject(i3.SnackbarService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i4.PermissionsService), i0.ɵɵdirectiveInject(i5.TranslateService), i0.ɵɵdirectiveInject(i6.SettingsService)); };
    PermissionsBlockListContainer.ɵcmp = i0.ɵɵdefineComponent({ type: PermissionsBlockListContainer, selectors: [["ho-permissions-block-list"]], inputs: { role: "role", userId: "userId" }, ngContentSelectors: _c1, decls: 4, vars: 4, consts: [[1, "round-item", "round-item_pb-10", "round-item_pb-0-mob"], [4, "ngFor", "ngForOf"], ["fxLayout", "row wrap", "fxLayoutGap", "10px", "fxLayoutAlign", "flex-end center", 4, "ngIf"], [1, "mb-15"], [3, "block", "permissionForm", 4, "ngIf"], ["class", "text-warning", 3, "hoQaAttributeWidget", 4, "ngIf"], [3, "block", "permissionForm"], [1, "text-warning", 3, "hoQaAttributeWidget"], ["fxLayout", "row wrap", "fxLayoutGap", "10px", "fxLayoutAlign", "flex-end center"], ["mat-button", "", 1, "btn", "btn_warning", "mb-15", 3, "hoQaAttributeWidget", "click"], [1, "icon-cross", "btn__icon"], ["mat-button", "", 1, "btn", "btn_success", "mb-15", 3, "hoQaAttributeWidget", "click"], [1, "icon-check-circle", "btn__icon"]], template: function PermissionsBlockListContainer_Template(rf, ctx) {
            if (rf & 1) {
                i0.ɵɵprojectionDef(_c0);
                i0.ɵɵelementStart(0, "div", 0);
                i0.ɵɵprojection(1);
                i0.ɵɵtemplate(2, PermissionsBlockListContainer_ng_container_2_Template, 4, 2, "ng-container", 1);
                i0.ɵɵtemplate(3, PermissionsBlockListContainer_div_3_Template, 9, 8, "div", 2);
                i0.ɵɵelementEnd();
            }
            if (rf & 2) {
                i0.ɵɵclassProp("loading", ctx.loading);
                i0.ɵɵadvance(2);
                i0.ɵɵproperty("ngForOf", ctx.permissionsList);
                i0.ɵɵadvance(1);
                i0.ɵɵproperty("ngIf", ctx.form.dirty);
            }
        }, styles: [""], changeDetection: 0 });
    return PermissionsBlockListContainer;
})();

