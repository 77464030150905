import { Query } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "./ui-settings-grid-cache.store";
export let UiSettingsGridCacheQuery = /*@__PURE__*/ (() => {
    class UiSettingsGridCacheQuery extends Query {
        constructor(gridCacheStore) {
            super(gridCacheStore);
            this.gridCacheStore = gridCacheStore;
        }
        getCache() {
            return cloneDeep(this.getValue().cache);
        }
        getPayload() {
            return cloneDeep(this.getValue().payload);
        }
    }
    UiSettingsGridCacheQuery.ɵfac = function UiSettingsGridCacheQuery_Factory(t) { return new (t || UiSettingsGridCacheQuery)(i0.ɵɵinject(i1.UiSettingsGridCacheStore)); };
    UiSettingsGridCacheQuery.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsGridCacheQuery, factory: UiSettingsGridCacheQuery.ɵfac });
    return UiSettingsGridCacheQuery;
})();

