export var SortDirection = /*@__PURE__*/ (function (SortDirection) {
    SortDirection["Top"] = "-";
    SortDirection["Bottom"] = "";
    return SortDirection;
})({});
export var DashboardWidgetView = /*@__PURE__*/ (function (DashboardWidgetView) {
    DashboardWidgetView["Table"] = "table";
    DashboardWidgetView["Chart"] = "chart";
    DashboardWidgetView["Map"] = "Map";
    return DashboardWidgetView;
})({});

