import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var TrafficChannelTypes = /*@__PURE__*/ (function (TrafficChannelTypes) {
    TrafficChannelTypes["Email"] = "email";
    TrafficChannelTypes["Native"] = "native";
    TrafficChannelTypes["Seo"] = "seo";
    TrafficChannelTypes["Push"] = "push";
    TrafficChannelTypes["Sms"] = "sms";
    TrafficChannelTypes["Bing"] = "bing";
    TrafficChannelTypes["Fb"] = "fb";
    TrafficChannelTypes["GoogleSearch"] = "google_search";
    TrafficChannelTypes["Pops"] = "pops";
    TrafficChannelTypes["Youtube"] = "youtube";
    TrafficChannelTypes["Mixed"] = "mixed";
    TrafficChannelTypes["Twitter"] = "twitter";
    TrafficChannelTypes["Tiktok"] = "tiktok";
    TrafficChannelTypes["Gdn"] = "gdn";
    return TrafficChannelTypes;
})({});
export const TrafficChannelTypesMap = new Map([
    [TrafficChannelTypes.Email, 'Global.Options.TrafficChannelTypes.Email'],
    [TrafficChannelTypes.Native, 'Global.Options.TrafficChannelTypes.Native'],
    [TrafficChannelTypes.Seo, 'Global.Options.TrafficChannelTypes.Seo'],
    [TrafficChannelTypes.Push, 'Global.Options.TrafficChannelTypes.Push'],
    [TrafficChannelTypes.Sms, 'Global.Options.TrafficChannelTypes.Sms'],
    [TrafficChannelTypes.Bing, 'Global.Options.TrafficChannelTypes.Bing'],
    [TrafficChannelTypes.Fb, 'Global.Options.TrafficChannelTypes.Fb'],
    [TrafficChannelTypes.GoogleSearch, 'Global.Options.TrafficChannelTypes.GoogleSearch'],
    [TrafficChannelTypes.Pops, 'Global.Options.TrafficChannelTypes.Pops'],
    [TrafficChannelTypes.Youtube, 'Global.Options.TrafficChannelTypes.Youtube'],
    [TrafficChannelTypes.Mixed, 'Global.Options.TrafficChannelTypes.Mixed'],
    [TrafficChannelTypes.Twitter, 'Global.Options.TrafficChannelTypes.Twitter'],
    [TrafficChannelTypes.Tiktok, 'Global.Options.TrafficChannelTypes.Tiktok'],
    [TrafficChannelTypes.Gdn, 'Global.Options.TrafficChannelTypes.Gdn'],
]);
export let TrafficChannelsTypesService = /*@__PURE__*/ (() => {
    class TrafficChannelsTypesService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TrafficChannelTypesMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    TrafficChannelsTypesService.ɵfac = function TrafficChannelsTypesService_Factory(t) { return new (t || TrafficChannelsTypesService)(i0.ɵɵinject(i1.TranslateService)); };
    TrafficChannelsTypesService.ɵprov = i0.ɵɵdefineInjectable({ token: TrafficChannelsTypesService, factory: TrafficChannelsTypesService.ɵfac });
    return TrafficChannelsTypesService;
})();

