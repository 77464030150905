import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { GridSettingsService } from './services/grid-settings.service';
import { UiSettingsApiService } from './services/ui-settings-api.service';
import { UiSettingsStore } from './+state/ui-settings.store';
import { UiSettingsQuery } from './+state/ui-settings.query';
import { DashboardSettingsService } from './services/dashboard-settings.service';
import { UiSettingsService } from './services/ui-settings.service';
import { DashboardVisibleConfigModalModule } from './widgets/dashboard-visible-config-modal/dashboard-visible-config-modal.module';
import { UiSettingsGridCacheStore } from './+state/ui-settings-grid-cache.store';
import { UiSettingsGridCacheQuery } from './+state/ui-settings-grid-cache.query';
import { UiSettingsQueueService } from './services/ui-settings-queue.service';
import { TutorialSettingsService } from './services/tutorial-settings.service';
import { ThemeSettingsService } from './services/theme-settings.service';
import * as i0 from "@angular/core";
export let DataAccessUiSettingsModule = /*@__PURE__*/ (() => {
    class DataAccessUiSettingsModule {
    }
    DataAccessUiSettingsModule.ɵmod = i0.ɵɵdefineNgModule({ type: DataAccessUiSettingsModule });
    DataAccessUiSettingsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DataAccessUiSettingsModule_Factory(t) { return new (t || DataAccessUiSettingsModule)(); }, providers: [
            GridSettingsService,
            UiSettingsApiService,
            UiSettingsStore,
            UiSettingsQuery,
            DashboardSettingsService,
            UiSettingsService,
            UiSettingsGridCacheStore,
            UiSettingsGridCacheQuery,
            UiSettingsQueueService,
            TutorialSettingsService,
            ThemeSettingsService,
        ], imports: [[CommonModule, HttpClientModule, DashboardVisibleConfigModalModule]] });
    return DataAccessUiSettingsModule;
})();

