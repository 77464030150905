export const STANDARD_BUTTON_COLORS = [
    {
        variable: '--btn-gradient-left-bg',
        value: '#5d58f7',
    },
    {
        variable: '--btn-gradient-center-bg',
        value: '#966df5',
    },
    {
        variable: '--btn-gradient-right-bg',
        value: '#543fdc',
    },
];
export const STANDARD_LAYOUT_COLORS = [
    {
        variable: '--layout-content-bg',
        value: '#ffffff',
    },
    {
        variable: '--layout-page-bg',
        value: '#f3f6fd',
    },
];
export const LIGHT_COLORS = [
    {
        variable: '--primary',
        value: '#577cd5',
        tooltip: 'usage in snack, input, links, buttons',
    },
    {
        variable: '--primary-light',
        value: '#7996dd',
    },
    {
        variable: '--primary-lighter',
        value: '#cbd7f2',
    },
    {
        variable: '--primary-dark',
        value: '#415da0',
    },
    {
        variable: '--blue-light',
        value: '#c7e3ff',
    },
    {
        variable: '--blue-lighter',
        value: '#e0f1ff',
    },
    {
        variable: '--success',
        value: '#29af61',
        tooltip: 'usage in snack, buttons, badge',
    },
    {
        variable: '--success-light',
        value: '#54bf81',
    },
    {
        variable: '--success-lighter',
        value: '#bde6ce',
    },
    {
        variable: '--success-dark',
        value: '#1f8349',
    },
    {
        variable: '--light-grey',
        value: '#e1e8fa',
    },
    {
        variable: '--accent-white',
        value: '#c3cfde',
    },
    {
        variable: '--grey',
        value: '#8892aa',
    },
    {
        variable: '--grey-dark',
        value: '#424f6c',
    },
    {
        variable: '--grey-light',
        value: '#8095c7',
    },
    {
        variable: '--black',
        value: '#363636',
    },
    {
        variable: '--danger',
        value: '#f54662',
        tooltip: 'usage in inputs, buttons, badge, snack',
    },
    {
        variable: '--danger-light',
        value: '#f76b81',
    },
    {
        variable: '--danger-lighter',
        value: '#fbc6cf',
    },
    {
        variable: '--danger-dark',
        value: '#b8354a',
    },
    {
        variable: '--warning',
        value: '#ff9e35',
        tooltip: 'usage in buttons, badge, snack',
    },
    {
        variable: '--warning-light',
        value: '#ffb15d',
    },
    {
        variable: '--warning-lighter',
        value: '#ffe1c1',
    },
    {
        variable: '--warning-dark',
        value: '#d37016',
    },
    {
        variable: '--accent',
        value: '#36a0f2',
    },
    {
        variable: '--accent-light',
        value: '#86c6f7',
    },
    {
        variable: '--accent-lighter',
        value: '#c1e2fb',
    },
    {
        variable: '--accent-dark',
        value: '#2978b5',
    },
    {
        variable: '--info',
        value: '#8171e7',
        tooltip: 'usage in buttons, badge, snack',
    },
    {
        variable: '--info-light',
        value: '#9a8dec',
    },
    {
        variable: '--info-lighter',
        value: '#d8d3f7',
    },
    {
        variable: '--info-dark',
        value: '#6155ad',
    },
    {
        variable: '--purple',
        value: '#7630dd',
    },
    {
        variable: '--white',
        value: '#ffffff',
        tooltip: 'usage in buttons/badge/snack... color text',
    },
    {
        variable: '--white-darker',
        value: '#e9e9e9',
    },
    {
        variable: '--light-hover',
        value: '#f2f7ff',
    },
    {
        variable: '--disabled-color',
        value: '#f5f7fd',
    },
    {
        variable: '--landing-bg',
        value: '#fcfdff',
    },
    {
        variable: '--loading-bg',
        value: 'rgba(255,255,255,0.7)',
    },
    {
        variable: '--sign-in-color-bg',
        value: '#eeeeee',
    },
    {
        variable: '--btn-link-hover-bg',
        value: 'rgba(87, 124, 213, 0.15)',
    },
    {
        variable: '--btn-link-disabled-color',
        value: 'rgba(0,0,0,.26)',
    },
];
export const STANDARD_ALL_COLORS = [
    ...LIGHT_COLORS,
    ...STANDARD_BUTTON_COLORS,
    ...STANDARD_LAYOUT_COLORS,
];
export const STANDARD_COLOR_SCHEME = {
    otherColors: LIGHT_COLORS,
    buttonColors: STANDARD_BUTTON_COLORS,
    layoutColors: STANDARD_LAYOUT_COLORS,
};

