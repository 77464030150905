import { CommonModule } from '@angular/common';
import * as i0 from "@angular/core";
export let MobileTooltipHandlerModule = /*@__PURE__*/ (() => {
    class MobileTooltipHandlerModule {
    }
    MobileTooltipHandlerModule.ɵmod = i0.ɵɵdefineNgModule({ type: MobileTooltipHandlerModule });
    MobileTooltipHandlerModule.ɵinj = i0.ɵɵdefineInjector({ factory: function MobileTooltipHandlerModule_Factory(t) { return new (t || MobileTooltipHandlerModule)(); }, imports: [[CommonModule]] });
    return MobileTooltipHandlerModule;
})();

