import { DashboardVisibleConfigModalContainer } from '../containers/dashboard-visible-config-modal/dashboard-visible-config-modal.container';
import { ModalHelper } from '@ho/shared/helpers/modal.helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export let DashboardVisibleConfigModalService = /*@__PURE__*/ (() => {
    class DashboardVisibleConfigModalService {
        constructor(dialog) {
            this.dialog = dialog;
            this._defaultOptions = {
                maxWidth: '368px',
                width: 'calc(100% - 30px)',
                minWidth: '290px',
                autoFocus: false,
                panelClass: 'modal-dialog',
            };
        }
        open(dashboardSettings, ids) {
            const ref = this.dialog.open(DashboardVisibleConfigModalContainer, Object.assign(Object.assign({}, this._defaultOptions), { data: { dashboardSettings, ids } }));
            ModalHelper.addClassToParent(ref);
            return ref;
        }
    }
    DashboardVisibleConfigModalService.ɵfac = function DashboardVisibleConfigModalService_Factory(t) { return new (t || DashboardVisibleConfigModalService)(i0.ɵɵinject(i1.MatDialog)); };
    DashboardVisibleConfigModalService.ɵprov = i0.ɵɵdefineInjectable({ token: DashboardVisibleConfigModalService, factory: DashboardVisibleConfigModalService.ɵfac });
    return DashboardVisibleConfigModalService;
})();

