import { __decorate, __metadata } from "tslib";
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { resetStores, Store, StoreConfig } from '@datorama/akita';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as storage from '../storage';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import * as i0 from "@angular/core";
const jwtHelper = new JwtHelperService();
let SessionStore = /*@__PURE__*/ (() => {
    let SessionStore = class SessionStore extends Store {
        constructor(environment) {
            super({});
            this.environment = environment;
            const token = this._value().token;
            if (token && jwtHelper.isTokenExpired(token)) {
                this.logout();
            }
        }
        login(token) {
            if (token) {
                this.update({ token });
                storage.set(this.environment.jwtTokenName, token);
                localStorage.setItem(this.environment.jwtTokenName, token);
            }
        }
        logout() {
            const exception = ['deployed_apps', 'language', 'show_welcome_page'];
            resetStores({ exclude: [StoreNames.Session] });
            this.reset();
            storage.remove(this.environment.jwtTokenName);
            Object.keys(localStorage).forEach((key) => {
                if (!exception.includes(key)) {
                    localStorage.removeItem(key);
                }
            });
        }
    };
    SessionStore.ɵfac = function SessionStore_Factory(t) { return new (t || SessionStore)(i0.ɵɵinject(AppEnvironment)); };
    SessionStore.ɵprov = i0.ɵɵdefineInjectable({ token: SessionStore, factory: SessionStore.ɵfac });
    SessionStore = __decorate([
        StoreConfig({ name: StoreNames.Session }),
        __metadata("design:paramtypes", [Object])
    ], SessionStore);
    return SessionStore;
})();
export { SessionStore };

