import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PixelCategoryType = /*@__PURE__*/ (function (PixelCategoryType) {
    PixelCategoryType["global"] = "global";
    PixelCategoryType["affiliate_campaign"] = "affiliate_campaign";
    PixelCategoryType["admin_campaign"] = "admin_campaign";
    PixelCategoryType["traffic_source"] = "traffic_source";
    PixelCategoryType["marketing_campaign"] = "marketing_campaign";
    return PixelCategoryType;
})({});
export const PixelCategoryTypeMap = new Map([
    [PixelCategoryType.global, 'Global.Options.PixelCategoryType.Global'],
    [PixelCategoryType.affiliate_campaign, 'Global.Options.PixelCategoryType.AffiliateCampaign'],
    [PixelCategoryType.admin_campaign, 'Global.Options.Offer'],
    [PixelCategoryType.traffic_source, 'Global.Options.PixelCategoryType.TrafficSource'],
    [PixelCategoryType.marketing_campaign, 'Global.Options.PixelCategoryType.MarketingCampaign'],
]);
export let PixelCategoryTypeService = /*@__PURE__*/ (() => {
    class PixelCategoryTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PixelCategoryTypeMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    PixelCategoryTypeService.ɵfac = function PixelCategoryTypeService_Factory(t) { return new (t || PixelCategoryTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    PixelCategoryTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: PixelCategoryTypeService, factory: PixelCategoryTypeService.ɵfac });
    return PixelCategoryTypeService;
})();

