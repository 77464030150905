import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var UserStatus = /*@__PURE__*/ (function (UserStatus) {
    UserStatus[UserStatus["New"] = 0] = "New";
    UserStatus[UserStatus["Active"] = 1] = "Active";
    UserStatus[UserStatus["Pending"] = 2] = "Pending";
    UserStatus[UserStatus["Banned"] = 3] = "Banned";
    return UserStatus;
})({});
export const UserStatusMap = new Map([
    [UserStatus.New, 'Global.Status.New'],
    [UserStatus.Active, 'Global.Status.Active'],
    [UserStatus.Pending, 'Global.Status.Pending'],
    [UserStatus.Banned, 'Global.UserStatus.Banned'],
]);
export let UserStatusService = /*@__PURE__*/ (() => {
    class UserStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = UserStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case UserStatus.Active:
                    return 'text-success';
                case UserStatus.Banned:
                    return 'text-danger';
                case UserStatus.Pending:
                case UserStatus.New:
                    return 'text-warning';
                default:
                    return '';
            }
        }
    }
    UserStatusService.ɵfac = function UserStatusService_Factory(t) { return new (t || UserStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    UserStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: UserStatusService, factory: UserStatusService.ɵfac });
    return UserStatusService;
})();

