import * as i0 from "@angular/core";
export let QaAttributeWidgetDirective = /*@__PURE__*/ (() => {
    class QaAttributeWidgetDirective {
        constructor(renderer, el) {
            this.renderer = renderer;
            this.el = el;
        }
        ngOnInit() {
            if (this.qaAttributeValue) {
                this.renderer.setAttribute(this.el.nativeElement, 'qa_id', this.qaAttributeValue);
            }
        }
    }
    QaAttributeWidgetDirective.ɵfac = function QaAttributeWidgetDirective_Factory(t) { return new (t || QaAttributeWidgetDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef)); };
    QaAttributeWidgetDirective.ɵdir = i0.ɵɵdefineDirective({ type: QaAttributeWidgetDirective, selectors: [["", "hoQaAttributeWidget", ""]], inputs: { qaAttributeValue: ["hoQaAttributeWidget", "qaAttributeValue"] } });
    return QaAttributeWidgetDirective;
})();

