import { DateTime } from 'luxon';
import { isPlainObject, isString } from 'lodash-es';
import { DateTimeHelper } from '@ho/shared/helpers/date.helper';
export function prepareBodyParams(body, offset = '+00:00') {
    if (!body) {
        return null;
    }
    function processingDateTime(datetime, offset = '+00:00') {
        return DateTime.fromFormat(datetime.substr(0, datetime.length - 6) + offset, DateTimeHelper.ISO_FORMAT, {
            setZone: true,
        }).toFormat(DateTimeHelper.ISO_FORMAT);
    }
    return Object.keys(body).reduce((acc, key) => {
        let v = body[key];
        if (DateTime.isDateTime(v)) {
            v = processingDateTime(v.toFormat(DateTimeHelper.ISO_FORMAT), offset);
        }
        else if (isPlainObject(v)) {
            v = prepareBodyParams(v, offset);
        }
        else if (isString(v) && DateTime.fromFormat(v, DateTimeHelper.ISO_FORMAT).isValid) {
            v = processingDateTime(v, offset);
        }
        acc[key] = v;
        return acc;
    }, {});
}

