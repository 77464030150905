import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var TransactionStatus = /*@__PURE__*/ (function (TransactionStatus) {
    TransactionStatus[TransactionStatus["Ok"] = 0] = "Ok";
    TransactionStatus[TransactionStatus["Voided"] = 1] = "Voided";
    TransactionStatus[TransactionStatus["Changed"] = 2] = "Changed";
    TransactionStatus[TransactionStatus["OnHold"] = 3] = "OnHold";
    return TransactionStatus;
})({});
export const TransactionStatusMap = new Map([
    [TransactionStatus.Ok, 'Global.Options.TransactionStatus.Ok'],
    [TransactionStatus.Changed, 'Global.Options.TransactionStatus.Changed'],
    [TransactionStatus.Voided, 'Global.Options.TransactionStatus.Voided'],
]);
export let TransactionStatusService = /*@__PURE__*/ (() => {
    class TransactionStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TransactionStatusMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        has(key) {
            return this._data.has(key);
        }
        getStatusClass(status) {
            switch (status) {
                case TransactionStatus.Ok:
                    return 'text-success';
                case TransactionStatus.Voided:
                    return 'text-danger';
                case TransactionStatus.Changed:
                case TransactionStatus.OnHold:
                    return 'text-warning';
                default:
                    return '';
            }
        }
    }
    TransactionStatusService.ɵfac = function TransactionStatusService_Factory(t) { return new (t || TransactionStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    TransactionStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: TransactionStatusService, factory: TransactionStatusService.ɵfac });
    return TransactionStatusService;
})();

