import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ForceBillingPeriodReason = /*@__PURE__*/ (function (ForceBillingPeriodReason) {
    ForceBillingPeriodReason[ForceBillingPeriodReason["PaymentTermsChanged"] = 0] = "PaymentTermsChanged";
    ForceBillingPeriodReason[ForceBillingPeriodReason["AffiliateRequest"] = 1] = "AffiliateRequest";
    ForceBillingPeriodReason[ForceBillingPeriodReason["BannedUser"] = 2] = "BannedUser";
    ForceBillingPeriodReason[ForceBillingPeriodReason["Other"] = 3] = "Other";
    return ForceBillingPeriodReason;
})({});
export const ForceBillingPeriodReasonMap = new Map([
    [
        ForceBillingPeriodReason.PaymentTermsChanged,
        'Global.Options.ForceBillingPeriodReason.PaymentTermsChanged',
    ],
    [ForceBillingPeriodReason.AffiliateRequest, 'Global.Options.ForceBillingPeriodReason.AffiliateRequest'],
    [ForceBillingPeriodReason.BannedUser, 'Global.Options.ForceBillingPeriodReason.BannedUser'],
    [ForceBillingPeriodReason.Other, 'Global.Options.ForceBillingPeriodReason.Other'],
]);
export let ForceBillingPeriodReasonService = /*@__PURE__*/ (() => {
    class ForceBillingPeriodReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ForceBillingPeriodReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    ForceBillingPeriodReasonService.ɵfac = function ForceBillingPeriodReasonService_Factory(t) { return new (t || ForceBillingPeriodReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    ForceBillingPeriodReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: ForceBillingPeriodReasonService, factory: ForceBillingPeriodReasonService.ɵfac });
    return ForceBillingPeriodReasonService;
})();

