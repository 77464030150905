import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../+state/data-source-handler.service";
// LeadDist Traffic Channels
export let LeadDistTrafficChannelsService = /*@__PURE__*/ (() => {
    class LeadDistTrafficChannelsService {
        constructor(_httpClient, _dataSourceHandlerService) {
            this._httpClient = _httpClient;
            this._dataSourceHandlerService = _dataSourceHandlerService;
            this._endpointUrl = '/dictionary';
        }
        list() {
            return this._dataSourceHandlerService
                .getData(`${this._endpointUrl}/traffic-channels`)
                .pipe(pluck('data'))
                .toPromise();
        }
    }
    LeadDistTrafficChannelsService.ɵfac = function LeadDistTrafficChannelsService_Factory(t) { return new (t || LeadDistTrafficChannelsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DataSourceHandlerService)); };
    LeadDistTrafficChannelsService.ɵprov = i0.ɵɵdefineInjectable({ token: LeadDistTrafficChannelsService, factory: LeadDistTrafficChannelsService.ɵfac });
    return LeadDistTrafficChannelsService;
})();

