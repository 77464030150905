import { throwError } from 'rxjs';
import { uniq } from 'lodash-es';
import * as i0 from "@angular/core";
export let FormHelper = /*@__PURE__*/ (() => {
    class FormHelper {
        static fakeMarkGroupAsTouched(form) {
            form.markAllAsTouched();
            Object.keys(form.controls).forEach((key) => {
                var _a;
                if (form.controls[key].controls) {
                    FormHelper.fakeMarkGroupAsTouched(((_a = form.controls[key].controls) === null || _a === void 0 ? void 0 : _a.length) ? form.controls[key].controls : form.controls[key]);
                }
                else {
                    form.controls[key].statusChanges['next'](form.controls[key].status);
                }
            });
        }
        /**
         * Mark each child control of group as dirty
         * Use case: before sending to server we can mark form as dirty and check validity
         */
        static markGroupAsDirty(form) {
            Object.keys(form.controls).forEach((key) => {
                form.controls[key].markAsDirty();
            });
        }
        /**
         * Mark each child control of group as pristine
         */
        static markGroupAsPristine(form) {
            Object.keys(form.controls).forEach((key) => {
                form.controls[key].markAsPristine();
            });
        }
        static errorVisible(control, errorName) {
            return (control.dirty || control.touched) && control.hasError(errorName);
        }
        /**
         * Validation response handling
         * http.post(form).catch((err) => this.handleValidationResponse(err))
         */
        static handleValidationResponse(form, errResponse, fieldPrefix = '') {
            if (errResponse.error.response.status === 422 && Array.isArray(errResponse.error.response.error.data)) {
                errResponse.error.response.error.data.forEach((msg) => {
                    const regex = new RegExp(`${fieldPrefix}.`, 'g');
                    const field = form.get(msg.field) || form.get(msg.field.replace(regex, ''));
                    if (field) {
                        const currentCtrlError = field.errors && field.errors['fromServer'];
                        const getMessage = () => {
                            if (currentCtrlError) {
                                const messages = currentCtrlError + ',<br>' + msg.message;
                                return uniq(messages.split(',<br>')).join(',<br>');
                            }
                            else {
                                return msg.message;
                            }
                        };
                        field.markAsTouched();
                        field.setErrors({
                            fromServer: getMessage(),
                        });
                    }
                });
            }
            return throwError(errResponse);
        }
        static updateControlValidators(control, validators) {
            if (validators && !!validators.length) {
                control.setValidators(validators);
            }
            else {
                control.clearValidators();
            }
            control.updateValueAndValidity();
        }
        static resetErrors(form) {
            Object.keys(form.controls).forEach((control) => {
                if (form.controls[control].errors) {
                    form.controls[control].setErrors(null);
                    form.controls[control].markAsUntouched();
                }
            });
        }
        static truncateFormArray(array) {
            const l = array.length;
            for (let i = 0; i < l; i++) {
                array.removeAt(0);
            }
        }
        static replaceFormArray(form, data = []) {
            this.truncateFormArray(form);
            if (data && Array.isArray(data) && data.length) {
                data.forEach((e) => form.push(e));
            }
        }
        static hasErrors(control) {
            return control.dirty && control.invalid;
        }
        static hasError(control, error) {
            return control.dirty && control.hasError(error);
        }
    }
    FormHelper.ɵfac = function FormHelper_Factory(t) { return new (t || FormHelper)(); };
    FormHelper.ɵprov = i0.ɵɵdefineInjectable({ token: FormHelper, factory: FormHelper.ɵfac });
    return FormHelper;
})();

