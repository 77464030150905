import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var UserLeadStatus = /*@__PURE__*/ (function (UserLeadStatus) {
    UserLeadStatus["New"] = "new";
    UserLeadStatus["SkypeContact"] = "skype_contact";
    UserLeadStatus["CallAgain"] = "call_again";
    UserLeadStatus["NotInterested"] = "not_interested";
    UserLeadStatus["NoAnswer"] = "no_answer";
    UserLeadStatus["Emailed"] = "emailed";
    UserLeadStatus["Declined"] = "declined";
    UserLeadStatus["Approved"] = "approved";
    return UserLeadStatus;
})({});
export const UserLeadStatusLabels = new Map([
    [UserLeadStatus.New, 'Global.Status.New'],
    [UserLeadStatus.SkypeContact, 'Global.UserLeadStatus.SkypeContact'],
    [UserLeadStatus.CallAgain, 'Global.UserLeadStatus.CallAgain'],
    [UserLeadStatus.NotInterested, 'Global.UserLeadStatus.NotInterested'],
    [UserLeadStatus.NoAnswer, 'Global.UserLeadStatus.NoAnswer'],
    [UserLeadStatus.Emailed, 'Global.UserLeadStatus.Emailed'],
    [UserLeadStatus.Declined, 'Global.UserLeadStatus.Declined'],
    [UserLeadStatus.Approved, 'Global.Status.Approved'],
]);
export let UserLeadStatusLabelsService = /*@__PURE__*/ (() => {
    class UserLeadStatusLabelsService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = UserLeadStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case UserLeadStatus.New:
                case UserLeadStatus.Approved:
                    return 'text-success';
                case UserLeadStatus.Declined:
                case UserLeadStatus.NotInterested:
                    return 'text-danger';
                case UserLeadStatus.CallAgain:
                case UserLeadStatus.Emailed:
                case UserLeadStatus.NoAnswer:
                case UserLeadStatus.SkypeContact:
                    return 'text-warning';
            }
        }
    }
    UserLeadStatusLabelsService.ɵfac = function UserLeadStatusLabelsService_Factory(t) { return new (t || UserLeadStatusLabelsService)(i0.ɵɵinject(i1.TranslateService)); };
    UserLeadStatusLabelsService.ɵprov = i0.ɵɵdefineInjectable({ token: UserLeadStatusLabelsService, factory: UserLeadStatusLabelsService.ɵfac });
    return UserLeadStatusLabelsService;
})();

