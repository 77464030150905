import { HttpParams } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export let SettingsApiService = /*@__PURE__*/ (() => {
    class SettingsApiService {
        constructor(_httpClient) {
            this._httpClient = _httpClient;
            this.endpointUrl = '/settings/config';
            this._systemEndpointUrl = '/system';
            this._settingsEndpointUrl = '/settings';
        }
        system() {
            let params = new HttpParams({
                fromObject: {
                    ws: location.origin,
                },
            });
            return this._httpClient
                .get(this._systemEndpointUrl, { params })
                .pipe(pluck('data'));
        }
        list(params = {}) {
            return this._httpClient.get(`${this.endpointUrl}`, params);
        }
        batchUpdate(section, body) {
            return this._httpClient.put(`${this.endpointUrl}/batch-update/${section}`, body);
        }
        updateRegistrationType(body) {
            return this._httpClient.put(`${this._settingsEndpointUrl}/workspace`, body);
        }
    }
    SettingsApiService.ɵfac = function SettingsApiService_Factory(t) { return new (t || SettingsApiService)(i0.ɵɵinject(i1.HttpClient)); };
    SettingsApiService.ɵprov = i0.ɵɵdefineInjectable({ token: SettingsApiService, factory: SettingsApiService.ɵfac, providedIn: 'root' });
    return SettingsApiService;
})();

