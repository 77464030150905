import { TranslateModule } from '@ngx-translate/core';
import { ContactTodayPriorityService } from './services/contact-today-priority.service';
import { ContactTodayStatusService } from './services/contact-today-status.service';
import { ContactTodayReasonService } from './services/contact-today-reasons.service';
import { CommonModule } from '@angular/common';
import { BounceReasonService } from './services/bounce-reasons.service';
import { CampaignStatusService } from './services/campaign-status.service';
import { CampaignTypeService } from './services/campaign-type.service';
import { CampaignUserRuleStatusService } from './services/campaign-user-rule-status.service';
import { CampaignAccessService } from './services/campaign-access.service';
import { CancelInvoiceReasonService } from './services/cancel-invoice-reasons.service';
import { ChangeAmountReasonService } from './services/change-amount-reasons.service';
import { CommissionTypesService } from './services/commission-types.service';
import { DynamicParametersService } from './services/dynamic-parameters.service';
import { FlowStatusService } from './services/flow-status.service';
import { ForceBillingPeriodReasonService } from './services/force-billing-period-reasons.service';
import { InvoiceStatusService } from './services/invoice-status.service';
import { LanguageService } from './services/language.service';
import { MarketplaceCategoryService } from './services/marketplace-category.service';
import { NotPayableSalesReasonsService } from './services/not-payable-sales-reasons.service';
import { PaymentMethodService } from './services/payment-method.service';
import { PixelCategoryTypeService } from './services/pixel-category-types.service';
import { RedirectTypesService } from './services/redirect-types.service';
import { SalesStatusService } from './services/sales-status.service';
import { TrackingDomainRulesService } from './services/tracking-domain-rules.service';
import { TrackingDomainsService } from './services/tracking-domains.service';
import { TrafficChannelsTypesService } from './services/traffic-channels-types.service';
import { TransactionStatusService } from './services/transactions-status.service';
import { UnvoidReasonService } from './services/unvoid-reasons.service';
import { UserRegistrationStatusService } from './services/user-registration-status.service';
import { UserStatusService } from './services/user-status.service';
import { UserTypeService } from './services/user-type.service';
import { VoidReasonService } from './services/void-reasons.service';
import { PayoutStatusService } from './services/payout-status.service';
import { PaidForTypeService } from './services/payment-paid-for-type.service';
import { CampaignRequestStatusesService } from './services/campaign-request-status.service';
import { UserLeadStatusLabelsService } from './services/user-lead-status.service';
import { UserIntegrationStatusService } from './services/user-integration-status.service';
import { DeepLinkService } from './services/deep-link.service';
import { RewardTypesLabelsService } from './services/reward-types.service';
import { PerformanceByProfitService } from './services/performance-by-profit.service';
import { ReportVisibilityService } from './services/report-visibility.service';
import { PayoutRollbackStatusService } from './services/payout-rollback-statuses.service';
import { AutologinStatusService, CommissionThresholdReachedTypesService, EmailStatusService, IntegrationStatusService, ReferralStatusService, TrackingTypeService, TestStatusService, } from './services';
import { PaymentStatusService } from './services/payment-status.service';
import { LeadDistTrafficChannelsService } from './services/lead-dist-traffic-channels.service';
import { TrafficSafetyService } from './services/traffic-safety.service';
import { LanguageStatusService } from './services/language-status.service';
import { ContactTodayReasonAdminATLService } from './services/contact-today-reasons-admin-atl.service';
import { LogActionTypeService } from './services/log-action-type.service';
import { ResponseStatusService } from './services/response-status.service';
import { DrillDownReportTypeService } from './services/drill-down-report-type.service';
import { DataSourceHandlerService } from './+state/data-source-handler.service';
import { DictionariesQuery } from './+state/dictionaries.query';
import { DictionariesStore } from './+state/dictionaries.store';
import { WorkspaceRoleTypeservice } from './services/workspace-role-types.service';
import * as i0 from "@angular/core";
export let DictionaryDataAccessModule = /*@__PURE__*/ (() => {
    class DictionaryDataAccessModule {
    }
    DictionaryDataAccessModule.ɵmod = i0.ɵɵdefineNgModule({ type: DictionaryDataAccessModule });
    DictionaryDataAccessModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DictionaryDataAccessModule_Factory(t) { return new (t || DictionaryDataAccessModule)(); }, providers: [
            BounceReasonService,
            CampaignStatusService,
            CampaignTypeService,
            CampaignUserRuleStatusService,
            CampaignAccessService,
            CancelInvoiceReasonService,
            ChangeAmountReasonService,
            CommissionTypesService,
            DynamicParametersService,
            FlowStatusService,
            ForceBillingPeriodReasonService,
            InvoiceStatusService,
            LanguageService,
            MarketplaceCategoryService,
            PaymentMethodService,
            RedirectTypesService,
            TrackingDomainRulesService,
            TrackingDomainsService,
            TrafficChannelsTypesService,
            TransactionStatusService,
            UnvoidReasonService,
            UserRegistrationStatusService,
            CampaignRequestStatusesService,
            UserStatusService,
            UserTypeService,
            VoidReasonService,
            PayoutStatusService,
            UserLeadStatusLabelsService,
            UserIntegrationStatusService,
            ContactTodayReasonService,
            ContactTodayStatusService,
            ContactTodayPriorityService,
            RewardTypesLabelsService,
            PerformanceByProfitService,
            PaidForTypeService,
            ReportVisibilityService,
            PayoutRollbackStatusService,
            CommissionThresholdReachedTypesService,
            PaymentStatusService,
            LeadDistTrafficChannelsService,
            TrafficSafetyService,
            LanguageStatusService,
            ContactTodayReasonAdminATLService,
            LogActionTypeService,
            PixelCategoryTypeService,
            ResponseStatusService,
            DrillDownReportTypeService,
            DataSourceHandlerService,
            DictionariesQuery,
            DictionariesStore,
            WorkspaceRoleTypeservice,
            IntegrationStatusService,
            EmailStatusService,
            DeepLinkService,
            ReferralStatusService,
            SalesStatusService,
            NotPayableSalesReasonsService,
            TrackingTypeService,
            AutologinStatusService,
            TestStatusService,
        ], imports: [[CommonModule, TranslateModule]] });
    return DictionaryDataAccessModule;
})();

