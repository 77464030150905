import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ChangeAmountReason = /*@__PURE__*/ (function (ChangeAmountReason) {
    ChangeAmountReason["Fraud"] = "fraud";
    ChangeAmountReason["DuplicateTransaction"] = "duplicate_transaction";
    ChangeAmountReason["TestSale"] = "test_sale";
    ChangeAmountReason["MisfiredPostback"] = "misfired_pixel";
    ChangeAmountReason["Other"] = "other";
    return ChangeAmountReason;
})({});
export const ChangeAmountReasonMap = new Map([
    [ChangeAmountReason.Fraud, 'Global.Options.ChangeAmountReason.Fraud'],
    [ChangeAmountReason.DuplicateTransaction, 'Global.Options.ChangeAmountReason.DuplicateTransaction'],
    [ChangeAmountReason.TestSale, 'Global.Options.ChangeAmountReason.TestSale'],
    [ChangeAmountReason.MisfiredPostback, 'Global.Options.ChangeAmountReason.MisfiredPostback'],
    [ChangeAmountReason.Other, 'Global.Options.ChangeAmountReason.Other'],
]);
export let ChangeAmountReasonService = /*@__PURE__*/ (() => {
    class ChangeAmountReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ChangeAmountReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    ChangeAmountReasonService.ɵfac = function ChangeAmountReasonService_Factory(t) { return new (t || ChangeAmountReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    ChangeAmountReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: ChangeAmountReasonService, factory: ChangeAmountReasonService.ɵfac });
    return ChangeAmountReasonService;
})();

