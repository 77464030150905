import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { enableAkitaProdMode, persistState, akitaConfig } from '@datorama/akita';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import * as __NgCli_bootstrap_1 from "./app/app.module";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}
persistState({
    include: [StoreNames.Session, StoreNames.UiSettings],
    preStorageUpdate: (storeName, state) => state,
    preStoreUpdate(storeName, state) {
        return state;
    },
});
akitaConfig({
    resettable: true,
});
__NgCli_bootstrap_2.platformBrowser().bootstrapModule(__NgCli_bootstrap_1.AppModule)
    .catch((err) => console.error(err));

