export var PixelStatus = /*@__PURE__*/ (function (PixelStatus) {
    PixelStatus[PixelStatus["Deleted"] = -1] = "Deleted";
    PixelStatus[PixelStatus["Active"] = 1] = "Active";
    return PixelStatus;
})({});
export var PixelType = /*@__PURE__*/ (function (PixelType) {
    PixelType[PixelType["S2S"] = 1] = "S2S";
    PixelType[PixelType["IFrame"] = 2] = "IFrame";
    PixelType[PixelType["JS"] = 3] = "JS";
    return PixelType;
})({});
export const PixelTypeMap = new Map([
    [PixelType.S2S, 'Global.Options.PixelType.S2S'],
    [PixelType.IFrame, 'Global.Options.PixelType.IFrame'],
    [PixelType.JS, 'Global.Options.PixelType.JS'],
]);
export var PixelActionTypeString = /*@__PURE__*/ (function (PixelActionTypeString) {
    PixelActionTypeString["OptIn"] = "opt-in";
    PixelActionTypeString["Lead"] = "lead";
    PixelActionTypeString["FTD"] = "ftd";
    PixelActionTypeString["Revshare"] = "revshare";
    PixelActionTypeString["Sale"] = "sale";
    return PixelActionTypeString;
})({});
export const PixelActionTypeStringMap = new Map([
    [PixelActionTypeString.OptIn, 'Global.Options.PixelActionType.OptIn'],
    [PixelActionTypeString.Lead, 'Global.Options.PixelActionType.Lead'],
    [PixelActionTypeString.FTD, 'Global.Options.PixelActionType.FTD'],
    [PixelActionTypeString.Revshare, 'Global.Options.Revshare'],
    [PixelActionTypeString.Sale, 'Global.Options.PixelActionType.Sale'],
]);
export const PixelActionTypeStringForInfoModalIdMap = new Map([
    [PixelActionTypeString.OptIn, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalId.OptIn'],
    [PixelActionTypeString.Lead, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalId.Lead'],
    [PixelActionTypeString.FTD, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalId.FTD'],
    [
        PixelActionTypeString.Revshare,
        'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalId.Revshare',
    ],
    [PixelActionTypeString.Sale, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalId.Sale'],
]);
export const PixelActionTypeStringForInfoModalDateMap = new Map([
    [PixelActionTypeString.OptIn, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalDate.OptIn'],
    [PixelActionTypeString.Lead, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalDate.Lead'],
    [PixelActionTypeString.FTD, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalDate.FTD'],
    [
        PixelActionTypeString.Revshare,
        'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalDate.Revshare',
    ],
    [PixelActionTypeString.Sale, 'ActionInfoModal.Options.PixelActionTypeStringForActionInfoModalDate.Sale'],
]);
export var PixelMethod = /*@__PURE__*/ (function (PixelMethod) {
    PixelMethod[PixelMethod["POST"] = 1] = "POST";
    PixelMethod[PixelMethod["GET"] = 2] = "GET";
    return PixelMethod;
})({});
// this map is international words
export const PixelMethodMap = new Map([
    [PixelMethod.POST, 'Post'],
    [PixelMethod.GET, 'Get'],
]);
export var PixelActionType = /*@__PURE__*/ (function (PixelActionType) {
    PixelActionType[PixelActionType["OptIn"] = 1] = "OptIn";
    PixelActionType[PixelActionType["Lead"] = 2] = "Lead";
    PixelActionType[PixelActionType["FTD"] = 3] = "FTD";
    PixelActionType[PixelActionType["Revshare"] = 4] = "Revshare";
    PixelActionType[PixelActionType["Sale"] = 5] = "Sale";
    return PixelActionType;
})({});
export const PixelActionTypeMap = new Map([
    [PixelActionType.OptIn, 'Global.Options.PixelActionType.OptIn'],
    [PixelActionType.Lead, 'Global.Options.PixelActionType.Lead'],
    [PixelActionType.FTD, 'Global.Options.PixelActionType.FTD'],
    [PixelActionType.Revshare, 'Global.Options.Revshare'],
    [PixelActionType.Sale, 'Global.Options.PixelActionType.Sale'],
]);

