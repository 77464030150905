export var AlertPriority = /*@__PURE__*/ (function (AlertPriority) {
    AlertPriority[AlertPriority["Low"] = 0] = "Low";
    AlertPriority[AlertPriority["Medium"] = 1] = "Medium";
    AlertPriority[AlertPriority["High"] = 2] = "High";
    return AlertPriority;
})({});
export var AlertType = /*@__PURE__*/ (function (AlertType) {
    AlertType[AlertType["Notification"] = 1] = "Notification";
    return AlertType;
})({});
export var AlertStatus = /*@__PURE__*/ (function (AlertStatus) {
    AlertStatus[AlertStatus["New"] = 0] = "New";
    AlertStatus[AlertStatus["Read"] = 1] = "Read";
    return AlertStatus;
})({});

