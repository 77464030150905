import { intersection } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ho/shared/data-access/session";
export let GoogleAnalyticsService = /*@__PURE__*/ (() => {
    class GoogleAnalyticsService {
        constructor(_route, _router, _authQuery, _sessionUserTypeService) {
            this._route = _route;
            this._router = _router;
            this._authQuery = _authQuery;
            this._sessionUserTypeService = _sessionUserTypeService;
            this.triggerOnPages = ['reports', 'dashboard', 'billing', 'campaigns', 'referral-program'];
        }
        emitEvent(event, eventCategory, eventLabel) {
            const url = this._router.url.match('^[^?]+')[0].split('/');
            // TODO: ugly solution because of AFS-4145
            if (typeof gtag !== 'undefined' &&
                intersection(url, this.triggerOnPages).length &&
                (this._sessionUserTypeService.isATL() || this._sessionUserTypeService.isAdmin())) {
                gtag('event', event, {
                    event_category: eventCategory,
                    event_label: eventLabel
                });
            }
        }
    }
    GoogleAnalyticsService.ɵfac = function GoogleAnalyticsService_Factory(t) { return new (t || GoogleAnalyticsService)(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SessionQuery), i0.ɵɵinject(i2.SessionUserTypeService)); };
    GoogleAnalyticsService.ɵprov = i0.ɵɵdefineInjectable({ token: GoogleAnalyticsService, factory: GoogleAnalyticsService.ɵfac });
    return GoogleAnalyticsService;
})();

