import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
// On API used boolean type, only for select converting to enum. Use only for select
export var AutologinStatus = /*@__PURE__*/ (function (AutologinStatus) {
    AutologinStatus[AutologinStatus["Used"] = 1] = "Used";
    AutologinStatus[AutologinStatus["NotUsed"] = 0] = "NotUsed";
    return AutologinStatus;
})({});
// Use only for select
export const AutologinStatusMap = new Map([
    [AutologinStatus.Used, 'Global.AutologinStatus.Used'],
    [AutologinStatus.NotUsed, 'Global.AutologinStatus.NotUsed'],
]);
export let AutologinStatusService = /*@__PURE__*/ (() => {
    class AutologinStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = AutologinStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(+status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            return status ? 'text-success' : 'text-danger';
        }
    }
    AutologinStatusService.ɵfac = function AutologinStatusService_Factory(t) { return new (t || AutologinStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    AutologinStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: AutologinStatusService, factory: AutologinStatusService.ɵfac });
    return AutologinStatusService;
})();

