import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var TrafficSafety = /*@__PURE__*/ (function (TrafficSafety) {
    TrafficSafety[TrafficSafety["Safe"] = 1] = "Safe";
    TrafficSafety[TrafficSafety["Mixed"] = 2] = "Mixed";
    TrafficSafety[TrafficSafety["Risky"] = 3] = "Risky";
    return TrafficSafety;
})({});
export const TrafficSafetyMap = new Map([
    [TrafficSafety.Safe, 'Global.Options.TrafficSafety.Safe'],
    [TrafficSafety.Mixed, 'Global.Options.TrafficSafety.Mixed'],
    [TrafficSafety.Risky, 'Global.Options.TrafficSafety.Risky'],
]);
export let TrafficSafetyService = /*@__PURE__*/ (() => {
    class TrafficSafetyService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TrafficSafetyMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    TrafficSafetyService.ɵfac = function TrafficSafetyService_Factory(t) { return new (t || TrafficSafetyService)(i0.ɵɵinject(i1.TranslateService)); };
    TrafficSafetyService.ɵprov = i0.ɵɵdefineInjectable({ token: TrafficSafetyService, factory: TrafficSafetyService.ɵfac });
    return TrafficSafetyService;
})();

