import { UserWorkspaceRole } from '../../../../data-access/session/src/lib/state/session-user-type-service.service';
export const permissionsList = [
    {
        title: 'Permission.Title.OfferGroup',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'offer_management',
        permissions: [
            {
                key: 'UI_OfferCampaigns_View',
                name: 'Permission.Name.View',
                childrenPermissions: [
                    'UI_OfferCampaigns_Manage',
                    'UI_OfferCampaigns_ManagePromotionRules',
                    'UI_OfferCampaigns_ManageBlockingRules',
                    // 'UI_OfferCampaigns_ManageRevenueRules',
                    'UI_OfferCampaigns_Suspend',
                    'UI_OfferCampaigns_ApproveRequest',
                ],
            },
            {
                key: 'UI_OfferCampaigns_Manage',
                name: 'Permission.Name.ManageCampaigns',
            },
            {
                key: 'UI_OfferCampaigns_ManagePromotionRules',
                name: 'Permission.Name.ManageOfferAccess',
            },
            {
                key: 'UI_OfferCampaigns_ManageBlockingRules',
                name: 'Permission.Name.ManageBlockingRules',
            },
            // {
            //   key: 'UI_OfferCampaigns_ManageRevenueRules',
            //   name: 'Permission.Name.ManageRevenueRules',
            // },
            {
                key: 'UI_OfferCampaigns_Suspend',
                name: 'Permission.Name.Suspend',
            },
            {
                key: 'UI_OfferCampaigns_ApproveRequest',
                name: 'Permission.Name.ApproveRequest',
            },
        ],
        tooltip: 'Permission.Tooltip.OfferGroup',
    },
    {
        title: 'Permission.Title.OfferGroup',
        roles: [UserWorkspaceRole.AD],
        feature: 'offer_management',
        permissions: [
            {
                key: 'UI_OfferCampaigns_View',
                name: 'Permission.Name.View',
            },
        ],
        tooltip: 'Permission.Tooltip.OfferGroup2',
    },
    {
        title: 'Permission.Title.UserOperations',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'user_operations',
        permissions: [
            {
                key: 'UI_UserOperations_View',
                name: 'Permission.Name.View',
                childrenPermissions: [
                    'UI_UserOperations_Manage',
                    'UI_UserOperations_Approve',
                    'UI_UserOperations_Ban',
                    'UI_UserOperations_CreateUser',
                ],
            },
            {
                key: 'UI_UserOperations_Manage',
                name: 'Permission.Name.Manage',
            },
            {
                key: 'UI_UserOperations_Approve',
                name: 'Permission.Name.ApproveOwnUsers',
                excludeRoles: [UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
            },
            {
                key: 'UI_UserOperations_Approve',
                name: 'Permission.Name.ApproveAllUsers',
                excludeRoles: [UserWorkspaceRole.AM],
            },
            {
                key: 'UI_UserOperations_Ban',
                name: 'Permission.Name.Ban',
            },
            {
                key: 'UI_UserOperations_CreateUser',
                name: 'Permission.Name.AddNewUser',
            },
        ],
        tooltip: 'Permission.Tooltip.UserOperations',
    },
    {
        title: 'Permission.Title.Postbacks',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.ATL, UserWorkspaceRole.Admin, UserWorkspaceRole.Aff],
        feature: 'postback_management',
        permissions: [
            {
                key: 'UI_Postback_View',
                name: 'Permission.Name.ViewPostback',
                childrenPermissions: ['UI_Postback_Manage'],
            },
            {
                key: 'UI_Postback_Manage',
                name: 'Permission.Name.ManagePostback',
            },
        ],
        tooltip: 'Permission.Tooltip.Postbacks',
    },
    /* ho:start */
    // {
    //   title: 'Permission.Title.ContactTodayAlerts',
    //   roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
    //   permissions: [
    //     {
    //       key: 'UI_ContactToday_View',
    //       name: 'Permission.Name.View',
    //       childrenPermissions: ['UI_ContactToday_Manage'],
    //     },
    //     {
    //       key: 'UI_ContactToday_Manage',
    //       name: 'Permission.Name.Manage',
    //     },
    //   ],
    //   tooltip: 'Permission.Tooltip.ContactTodayAlerts',
    // },
    /* ho:end */
    {
        title: 'Permission.Title.AffiliateBilling',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.Aff, UserWorkspaceRole.ATL],
        feature: 'affiliate_billing',
        permissions: [
            {
                key: 'UI_Billing_View',
                name: 'Permission.Name.View',
                childrenPermissions: [
                    'UI_Billing_ManagePayouts',
                    'UI_Billing_Create',
                    'UI_Billing_ManageInvoices',
                    'UI_Billing_ManageTransactions',
                    'UI_Billing_RestorePayments',
                ],
            },
            {
                key: 'UI_Billing_ManagePayouts',
                name: 'Permission.Name.ManagePayouts',
                excludeRoles: [UserWorkspaceRole.Aff],
            },
            {
                key: 'UI_Billing_Create',
                name: 'Permission.Name.CreatePayout',
                excludeRoles: [UserWorkspaceRole.Aff],
            },
            {
                key: 'UI_Billing_ManageInvoices',
                name: 'Permission.Name.ManageInvoices',
            },
            {
                key: 'UI_Billing_ManageTransactions',
                name: 'Permission.Name.ManageTransactions',
                excludeRoles: [UserWorkspaceRole.Aff],
            },
            {
                key: 'UI_Billing_RestorePayments',
                name: 'Permission.Name.RestorePayments',
                excludeRoles: [UserWorkspaceRole.Aff],
            },
        ],
        tooltip: 'Permission.Tooltip.AffiliateBilling',
    },
    {
        title: 'Permission.Title.CRG',
        roles: [UserWorkspaceRole.Admin, UserWorkspaceRole.ATL, UserWorkspaceRole.AM, UserWorkspaceRole.Aff],
        feature: 'crg',
        permissions: [
            {
                key: 'UI_CRG_View',
                name: 'Permission.Name.View',
            },
        ],
    },
    {
        title: 'Permission.Title.EPL',
        roles: [UserWorkspaceRole.Admin, UserWorkspaceRole.ATL, UserWorkspaceRole.AM, UserWorkspaceRole.Aff],
        feature: 'epl',
        permissions: [
            {
                key: 'UI_EPL_View',
                name: 'Permission.Name.View',
            },
        ],
    },
    {
        title: 'Permission.Title.AdvertiserBilling',
        roles: [UserWorkspaceRole.Admin],
        feature: 'advertiser_billing',
        permissions: [
            {
                key: 'UI_AdvertiserBilling_View',
                name: 'Permission.Name.View',
                childrenPermissions: [
                    'UI_AdvertiserBilling_ManagePayments',
                    'UI_AdvertiserBilling_ManageInvoices',
                    'UI_AdvertiserBilling_ManageTransactions',
                ],
            },
            {
                key: 'UI_AdvertiserBilling_ManagePayments',
                name: 'Permission.Name.ManagePayments',
            },
            {
                key: 'UI_AdvertiserBilling_ManageInvoices',
                name: 'Permission.Name.ManageInvoices',
            },
            {
                key: 'UI_AdvertiserBilling_ManageTransactions',
                name: 'Permission.Name.ManageTransactions',
            },
        ],
        tooltip: 'Permission.Tooltip.AdvertiserBilling',
    },
    {
        title: 'Permission.Title.AdvertiserBilling',
        roles: [UserWorkspaceRole.AD],
        feature: 'advertiser_billing',
        permissions: [
            {
                key: 'UI_AdvertiserBilling_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_AdvertiserBilling_ManageInvoices'],
            },
            {
                key: 'UI_AdvertiserBilling_ManageInvoices',
                name: 'Permission.Name.ManageInvoices',
            },
        ],
        tooltip: 'Permission.Tooltip.AdvertiserBilling2',
    },
    {
        title: 'Global.Navigation.PayoutRules',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.Aff, UserWorkspaceRole.ATL],
        feature: 'payout_rules',
        permissions: [
            {
                key: 'UI_PayoutRules_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_PayoutRules_Manage'],
            },
            {
                key: 'UI_PayoutRules_Manage',
                name: 'Permission.Name.Manage',
                excludeRoles: [UserWorkspaceRole.Aff],
            },
        ],
        tooltip: 'Permission.Tooltip.PayoutRules',
    },
    {
        title: 'Permission.Title.RevenueRules',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL, UserWorkspaceRole.AD],
        feature: 'revenue_rules',
        permissions: [
            {
                key: 'UI_RevenueRules_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_RevenueRules_Manage'],
            },
            {
                key: 'UI_RevenueRules_Manage',
                name: 'Permission.Name.Manage',
                excludeRoles: [UserWorkspaceRole.AD],
            },
        ],
        tooltip: 'Permission.Tooltip.RevenueRules',
    },
    {
        title: 'Global.Navigation.MarketingCampaigns',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL, UserWorkspaceRole.Aff],
        feature: 'marketing_campaigns',
        permissions: [
            {
                key: 'UI_SmartLinks_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_SmartLinks_Manage', 'UI_SmartLinks_Suspend'],
            },
            {
                key: 'UI_SmartLinks_Manage',
                name: 'Permission.Name.Manage',
            },
            {
                key: 'UI_SmartLinks_Suspend',
                name: 'Permission.Name.Suspend',
            },
        ],
        tooltip: 'Permission.Tooltip.MarketingCampaigns',
    },
    /* ho:start */
    {
        title: 'Permission.Title.Capacity',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'capacity',
        permissions: [
            {
                key: 'UI_Capacity_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_Capacity_Manage'],
            },
            {
                key: 'UI_Capacity_Manage',
                name: 'Permission.Name.Manage',
            },
        ],
        tooltip: 'Permission.Tooltip.Capacity',
    },
    /* ho:end */
    {
        title: 'Permission.Title.ReferralProgram',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.Aff, UserWorkspaceRole.ATL],
        feature: 'referral_program',
        permissions: [
            {
                key: 'UI_ReferralProgram_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_ReferralProgram_Manage'],
            },
            {
                key: 'UI_ReferralProgram_Manage',
                name: 'Permission.Name.Manage',
                excludeRoles: [UserWorkspaceRole.Aff],
            },
        ],
        tooltip: 'Permission.Tooltip.ReferralProgram',
    },
    {
        title: 'Permission.Title.PushBrokersRequests',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'push_brokers',
        permissions: [
            {
                key: 'UI_PushBrokers_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_PushBrokers_Manage'],
            },
            {
                key: 'UI_PushBrokers_Manage',
                name: 'Permission.Name.Manage',
            },
        ],
        tooltip: 'Permission.Tooltip.PushBrokersRequests',
    },
    {
        title: 'Global.Navigation.Reports',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'reports',
        permissions: [
            {
                key: 'UI_Reports_Traffic',
                name: 'Permission.Name.TrafficReport',
            },
            {
                key: 'UI_Reports_Global',
                name: 'Permission.Name.GlobalReport',
                excludeRoles: [UserWorkspaceRole.Admin],
            },
            {
                key: 'UI_Reports_Referrer',
                name: 'Permission.Name.ReferrerReport',
            },
            {
                key: 'UI_Reports_Basic',
                name: 'Permission.Name.ViewReport',
                excludeRoles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
            },
            {
                key: 'UI_LdsLeadInfo_View',
                name: 'Global.Text.LeadsEmail',
                excludeRoles: [UserWorkspaceRole.AM, UserWorkspaceRole.ATL],
            },
            {
                key: 'UI_LdsEmailInfo_View',
                name: 'Global.Text.EmailInfo',
                excludeRoles: [UserWorkspaceRole.AM, UserWorkspaceRole.ATL],
            },
        ],
        /* ho:start */
        description: 'Permission.Description.Reports',
        /* ho:end */
        tooltip: 'Permission.Tooltip.Reports',
    },
    {
        title: 'Permission.Name.ViewOrderSum',
        roles: [UserWorkspaceRole.Aff],
        feature: 'hide_sale_order_sum',
        permissions: [
            {
                key: 'UI_Order_Sum',
                name: 'Permission.Name.ViewOrderSum',
            },
        ],
    },
    {
        title: 'Global.Navigation.Reports',
        roles: [UserWorkspaceRole.AD],
        feature: 'reports',
        permissions: [
            {
                key: 'UI_Reports_Traffic',
                name: 'Permission.Name.TrafficReport',
            },
        ],
        tooltip: 'Permission.Tooltip.Reports2',
    },
    /* ho:start */
    {
        title: 'Global.Navigation.Reports',
        roles: [UserWorkspaceRole.Aff],
        feature: 'reports',
        permissions: [
            {
                key: 'UI_Reports_Basic',
                name: 'Permission.Name.ViewReport',
            },
            {
                key: 'UI_Billing_CommissionView',
                name: 'Permission.Name.CommissionReport',
            },
        ],
        tooltip: 'Permission.Tooltip.Reports3',
    },
    /* ho:end */
    {
        title: 'Global.Navigation.AutomationRules',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'automation_rules',
        permissions: [
            {
                key: 'UI_AutomationRules_View',
                name: 'Permission.Name.View',
                childrenPermissions: ['UI_AutomationRules_Manage'],
            },
            {
                key: 'UI_AutomationRules_Manage',
                name: 'Permission.Name.Manage',
            },
        ],
        tooltip: 'Permission.AutomationRules.Tooltip',
    },
    {
        title: 'Global.Navigation.PermissionSettings',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        permissions: [
            {
                key: 'UI_PermissionSettings_View',
                name: 'Permission.Name.View',
                childrenPermissions: [
                    'UI_PermissionSettings_ManageAdmin',
                    'UI_PermissionSettings_ManageTL',
                    'UI_PermissionSettings_ManageManager',
                    'UI_PermissionSettings_ManageAdvertiser',
                    'UI_PermissionSettings_ManageAffiliate',
                ],
            },
            {
                key: 'UI_PermissionSettings_ManageAdmin',
                name: 'Permission.Name.ManageAdmin',
                excludeRoles: [
                    UserWorkspaceRole.Aff,
                    UserWorkspaceRole.AD,
                    UserWorkspaceRole.AM,
                    UserWorkspaceRole.ATL,
                ],
            },
            /* ho:start */
            {
                key: 'UI_PermissionSettings_ManageTL',
                name: 'Permission.Name.ManageTeamLead',
                excludeRoles: [
                    UserWorkspaceRole.Aff,
                    UserWorkspaceRole.AD,
                    UserWorkspaceRole.AM,
                    UserWorkspaceRole.ATL,
                ],
            },
            /* ho:end */
            {
                key: 'UI_PermissionSettings_ManageManager',
                name: 'Permission.Name.ManageManager',
                excludeRoles: [UserWorkspaceRole.Aff, UserWorkspaceRole.AD, UserWorkspaceRole.AM],
            },
            {
                key: 'UI_PermissionSettings_ManageAdvertiser',
                name: 'Permission.Name.ManageAdvertiser',
                excludeRoles: [
                    UserWorkspaceRole.Aff,
                    UserWorkspaceRole.AD,
                    UserWorkspaceRole.ATL,
                    UserWorkspaceRole.AM,
                ],
            },
            {
                key: 'UI_PermissionSettings_ManageAffiliate',
                name: 'Permission.Name.ManageAffiliate',
                excludeRoles: [UserWorkspaceRole.Aff, UserWorkspaceRole.AD],
            },
        ],
        tooltip: 'Permission.Tooltip.PermissionSettings',
    },
    {
        title: 'Permission.Title.Logs',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'logs',
        permissions: [
            {
                key: 'UI_Logs_View',
                name: 'Permission.Name.View',
            },
        ],
    },
    {
        title: 'Permission.Title.Archivation',
        roles: [UserWorkspaceRole.AM, UserWorkspaceRole.Admin, UserWorkspaceRole.ATL],
        feature: 'archives',
        permissions: [
            {
                key: 'UI_Archivation_Manage',
                name: 'Permission.Name.Manage',
            },
        ],
    },
    {
        title: 'UI_FraudDetection_View',
        roles: [],
        permissions: [
            {
                key: 'UI_FraudDetection_View',
                name: 'Permission.Name.View',
            },
        ],
    },
    {
        title: 'UI_FraudDetection_Explain',
        roles: [],
        permissions: [
            {
                key: 'UI_FraudDetection_Explain',
                name: 'UI_FraudDetection_Explain',
            },
        ],
    },
    {
        title: 'UI_Analyzer_View',
        roles: [],
        feature: 'analyzer',
        permissions: [
            {
                key: 'UI_Analyzer_View',
                name: 'Permission.Name.View',
            },
        ],
    },
    {
        title: 'UI_Enable_Advertiser',
        roles: [],
        permissions: [
            {
                key: 'UI_Enable_Advertiser',
                name: 'UI_Enable_Advertiser',
            },
        ],
    },
    {
        title: 'Permission.Title.LeadStatuses',
        roles: [],
        feature: 'lead_status',
        permissions: [
            {
                key: 'UI_LeadStatus_View',
                name: 'Permission.Name.View',
            },
        ],
    },
];

