import { map, pluck } from 'rxjs/operators';
import { map as _map } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
export var MarketplaceCategoryStatus = /*@__PURE__*/ (function (MarketplaceCategoryStatus) {
    MarketplaceCategoryStatus[MarketplaceCategoryStatus["Deactivated"] = -1] = "Deactivated";
    MarketplaceCategoryStatus[MarketplaceCategoryStatus["Active"] = 1] = "Active";
    return MarketplaceCategoryStatus;
})({});
export const MarketplaceCategoryStatusMap = new Map([
    [MarketplaceCategoryStatus.Deactivated, 'Global.Options.MarketplaceCategoryStatus.Deactivated'],
    [MarketplaceCategoryStatus.Active, 'Global.Status.Active'],
]);
export let MarketplaceCategoryService = /*@__PURE__*/ (() => {
    class MarketplaceCategoryService {
        constructor(_httpClient, _translate) {
            this._httpClient = _httpClient;
            this._translate = _translate;
            this.endpointUrl = `/dictionary/marketplace-categories`;
            this._data = MarketplaceCategoryStatusMap;
        }
        gridList(params) {
            return this._httpClient
                .post(`${this.endpointUrl}/list`, params, {
                observe: 'response',
            })
                .pipe(map((response) => {
                response.body['data'] = _map(response.body['data'], (item) => {
                    return Object.assign(Object.assign({}, item), { status_label: this.getLabel(item.status), statusClass: this._getStatusClass(item.status) });
                });
                return response;
            }));
        }
        post(body, options) {
            return this._httpClient.post(this.endpointUrl, body, options).pipe(pluck('data'));
        }
        put(id, body, options) {
            return this._httpClient.put(`${this.endpointUrl}/${id}`, body, options).pipe(pluck('data'));
        }
        save(body, options) {
            return body.id ? this.put(body.id, body, options) : this.post(body, options);
        }
        get(id, options) {
            return this._httpClient.get(`${this.endpointUrl}/${id}`, options).pipe(pluck('data'));
        }
        restore(id, options) {
            return this._httpClient.patch(`${this.endpointUrl}/${id}/restore`, options);
        }
        delete(id, options) {
            return this._httpClient
                .delete(`${this.endpointUrl}/${id}`, options)
                .pipe(map((r) => r.success === true));
        }
        getLabel(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        _getStatusClass(status) {
            switch (status) {
                case MarketplaceCategoryStatus.Active:
                    return 'text-success';
                case MarketplaceCategoryStatus.Deactivated:
                    return 'text-danger';
                default:
                    return '';
            }
        }
    }
    MarketplaceCategoryService.ɵfac = function MarketplaceCategoryService_Factory(t) { return new (t || MarketplaceCategoryService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService)); };
    MarketplaceCategoryService.ɵprov = i0.ɵɵdefineInjectable({ token: MarketplaceCategoryService, factory: MarketplaceCategoryService.ɵfac });
    return MarketplaceCategoryService;
})();

