import { CommonModule } from '@angular/common';
import { PermissionsBlockComponent } from './components/permissions-block/permissions-block.component';
import { PermissionsBlockListContainer } from './containers/permissions-block-list/permissions-block-list.container';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PermissionSettingsService } from './services/permission-settings.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
import { MobileTooltipHandlerModule } from '@ho/shared/ui/directives';
import { TranslateModule } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../qa-attribute-widget/src/lib/directives/qa-attribute-widget.directive";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/material/button";
import * as i6 from "@ngx-translate/core";
export let PermissionSettingsModule = /*@__PURE__*/ (() => {
    class PermissionSettingsModule {
    }
    PermissionSettingsModule.ɵmod = i0.ɵɵdefineNgModule({ type: PermissionSettingsModule });
    PermissionSettingsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function PermissionSettingsModule_Factory(t) { return new (t || PermissionSettingsModule)(); }, providers: [PermissionSettingsService], imports: [[
                CommonModule,
                ReactiveFormsModule,
                MatButtonModule,
                MatCheckboxModule,
                FlexLayoutModule,
                MatFormFieldModule,
                MatTooltipModule,
                MobileTooltipHandlerModule,
                NgxPermissionsModule.forChild(),
                QaAttributeWidgetModule,
                TranslateModule,
            ]] });
    return PermissionSettingsModule;
})();
i0.ɵɵsetComponentScope(PermissionsBlockListContainer, [i2.NgForOf, i2.NgIf, PermissionsBlockComponent, i3.QaAttributeWidgetDirective, i4.DefaultLayoutDirective, i4.DefaultLayoutGapDirective, i4.DefaultLayoutAlignDirective, i5.MatButton], [i6.TranslatePipe]);

