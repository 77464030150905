import { Query } from '@datorama/akita';
import * as i0 from "@angular/core";
import * as i1 from "./ui-settings.store";
export let UiSettingsQuery = /*@__PURE__*/ (() => {
    class UiSettingsQuery extends Query {
        constructor(gridSettingsStore) {
            super(gridSettingsStore);
            this.gridSettingsStore = gridSettingsStore;
        }
        get queryGridSettings() {
            return JSON.parse(JSON.stringify(this.getValue().grid_settings || {}));
        }
        get queryDashboardSettings() {
            return JSON.parse(JSON.stringify(this.getValue().dashboard_settings || {}));
        }
        get queryModalSettings() {
            return JSON.parse(JSON.stringify(this.getValue().modal_settings || {}));
        }
        get queryThemeSettings() {
            return JSON.parse(JSON.stringify(this.getValue().theme_settings || {}));
        }
        get queryTutorialSettings() {
            return JSON.parse(JSON.stringify(this.getValue().tutorial_settings || {}));
        }
        get querySkipTutorial() {
            var _a, _b;
            return JSON.parse(JSON.stringify(((_b = (_a = this.getValue()) === null || _a === void 0 ? void 0 : _a.tutorial_settings) === null || _b === void 0 ? void 0 : _b.skip_tutorial) || false));
        }
        get queryConfigSettings() {
            return JSON.parse(JSON.stringify(this.getValue().config_settings || {}));
        }
    }
    UiSettingsQuery.ɵfac = function UiSettingsQuery_Factory(t) { return new (t || UiSettingsQuery)(i0.ɵɵinject(i1.UiSettingsStore)); };
    UiSettingsQuery.ɵprov = i0.ɵɵdefineInjectable({ token: UiSettingsQuery, factory: UiSettingsQuery.ɵfac });
    return UiSettingsQuery;
})();

