import { map, pluck } from 'rxjs/operators';
import { isArray } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../+state/data-source-handler.service";
import * as i3 from "@ngx-translate/core";
export var CommissionType = /*@__PURE__*/ (function (CommissionType) {
    CommissionType[CommissionType["CPA"] = 1] = "CPA";
    CommissionType[CommissionType["CPLLead"] = 2] = "CPLLead";
    CommissionType[CommissionType["CPLOpt"] = 3] = "CPLOpt";
    CommissionType[CommissionType["Revshare"] = 4] = "Revshare";
    CommissionType[CommissionType["Click"] = 5] = "Click";
    CommissionType[CommissionType["CRG"] = 6] = "CRG";
    CommissionType[CommissionType["EPL"] = 7] = "EPL";
    return CommissionType;
})({});
export const CommissionLabels = new Map([
    [CommissionType.CPA, 'Global.Options.CommissionLabels.CPA'],
    [CommissionType.CPLLead, 'Global.Options.CommissionLabels.CPLLead'],
    [CommissionType.CPLOpt, 'Global.Options.CommissionLabels.CPLOpt'],
    [CommissionType.Revshare, 'Global.Options.Revshare'],
    [CommissionType.Click, 'Global.Options.Click'],
    [CommissionType.CRG, 'Global.Options.CRG'],
    [CommissionType.EPL, 'Global.Options.EPL'],
]);
export let CommissionTypesService = /*@__PURE__*/ (() => {
    class CommissionTypesService {
        constructor(_httpClient, _dataSourceHandlerService, _translate) {
            this._httpClient = _httpClient;
            this._dataSourceHandlerService = _dataSourceHandlerService;
            this._translate = _translate;
            this._endpointUrl = '/dictionary';
            this._data = CommissionLabels;
        }
        list(deprecatedIds = []) {
            return this._dataSourceHandlerService.getData(`${this._endpointUrl}/commission-types`).pipe(map((r) => {
                if (!isArray(deprecatedIds)) {
                    return r;
                }
                r.data = r.data.filter((item) => !deprecatedIds.includes(item.id));
                return r;
            }));
        }
        get(id) {
            return this._httpClient
                .get(`${this._endpointUrl}/commission-types/${id}`)
                .pipe(pluck('data'));
        }
        getLanguages(body) {
            return this._httpClient
                .post(`${this._endpointUrl}/languages/list`, body)
                .pipe(pluck('data'))
                .toPromise();
        }
        getName(type) {
            const t = this._data.get(type);
            return t ? this._translate.instant(t) : t;
        }
    }
    CommissionTypesService.ɵfac = function CommissionTypesService_Factory(t) { return new (t || CommissionTypesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DataSourceHandlerService), i0.ɵɵinject(i3.TranslateService)); };
    CommissionTypesService.ɵprov = i0.ɵɵdefineInjectable({ token: CommissionTypesService, factory: CommissionTypesService.ɵfac });
    return CommissionTypesService;
})();

