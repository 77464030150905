import { Validators } from '@angular/forms';
import { isArray } from 'lodash-es';
export function isPresent(obj) {
    return obj !== undefined && obj !== null;
}
export let CustomValidator = /*@__PURE__*/ (() => {
    class CustomValidator {
        static get number() {
            return (c) => {
                if (isPresent(Validators.required(c))) {
                    return null;
                }
                const v = c.value;
                return CustomValidator.numberRegex.test(v) ? null : { number: true };
            };
        }
        static get integer() {
            return (c) => {
                if (isPresent(Validators.required(c))) {
                    return null;
                }
                const v = c.value;
                return CustomValidator.integerRegex.test(v) ? null : { integer: true };
            };
        }
        static rangeLength(min, max) {
            return (c) => {
                if (c.value && (isNaN(c.value) || c.value.length < min || c.value.length > max)) {
                    return { rangeLength: true };
                }
                return null;
            };
        }
        static get url() {
            return (c) => {
                if (isPresent(Validators.required(c)))
                    return null;
                const v = c.value;
                return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(v)
                    ? null
                    : { url: true };
            };
        }
        static get phoneNumber() {
            return (c) => {
                if (isPresent(Validators.required(c))) {
                    return null;
                }
                return intlTelInputUtils.isValidNumber(c.value) ? null : { mobile_phone: true };
            };
        }
        static pattern(patternRegExp) {
            return (c) => {
                if (isPresent(Validators.required(c)))
                    return null;
                return new RegExp(patternRegExp).test(c.value) ? { pattern: true } : null;
            };
        }
        static regexp(patternRegExp) {
            return (c) => {
                if (isPresent(Validators.required(c)))
                    return null;
                const newRegExp = new RegExp(patternRegExp);
                newRegExp.lastIndex = 0;
                return newRegExp.test(c.value) ? null : { pattern: true };
            };
        }
        static get email() {
            return (c) => {
                if (isPresent(Validators.required(c)))
                    return null;
                return isArray(c.value)
                    ? c.value.every((value) => {
                        return CustomValidator.emailRegex.test(value);
                    })
                        ? null
                        : { email: true }
                    : CustomValidator.emailRegex.test(c.value)
                        ? null
                        : { email: true };
            };
        }
        static atLeastOneRequired(keys = [], customKeyError = 'atLeastOneRequired') {
            return (group) => {
                if (!keys.length) {
                    return null;
                }
                const controls = keys.map((key) => group.get(key));
                if (controls.every((c) => !c.value)) {
                    controls.forEach((c) => c.setErrors({ [customKeyError]: true }));
                    return { [customKeyError]: true };
                }
                else if (controls.some((c) => c.value)) {
                    controls.forEach((c) => {
                        if (c.value && c.errors) {
                            c.markAsTouched({ onlySelf: true });
                        }
                        else {
                            c.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                        }
                    });
                    return null;
                }
                return null;
            };
        }
        static get skype() {
            return (c) => {
                var _a, _b;
                if (isPresent(Validators.required(c)))
                    return null;
                if (CustomValidator.skypeRegex.test(c.value) || ((_a = c.value) === null || _a === void 0 ? void 0 : _a.length) < 5 || ((_b = c.value) === null || _b === void 0 ? void 0 : _b.length) > 31) {
                    c.setErrors({ skype: true }, { emitEvent: false });
                    return { skype: true };
                }
                else {
                    return null;
                }
            };
        }
        static get holdPeriod() {
            return (c) => {
                if (isPresent(Validators.required(c)))
                    return null;
                if (c.value &&
                    (isNaN(c.value) ||
                        +c.value < 0 ||
                        +c.value > 60 ||
                        !CustomValidator.numberRegex.test(c.value) ||
                        !CustomValidator.integerRegex.test(c.value))) {
                    return { holdPeriod: true };
                }
                return null;
            };
        }
    }
    CustomValidator.emailRegex = /^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
    CustomValidator.skypeRegex = /^[^a-z]|[^\w\.\,\-\:]/gi;
    CustomValidator.numberRegex = /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/;
    CustomValidator.integerRegex = /^(?:-?\d+|-?\d{1,3}(?:\d{3})+)?(?:\d+)?$/;
    return CustomValidator;
})();

