import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var UserIntegrationStatus = /*@__PURE__*/ (function (UserIntegrationStatus) {
    UserIntegrationStatus["New"] = "new";
    UserIntegrationStatus["Negotiation"] = "negotiation";
    UserIntegrationStatus["Integrating"] = "integrating";
    UserIntegrationStatus["WaitingForLaunch"] = "waiting_for_launch";
    UserIntegrationStatus["Live"] = "live";
    UserIntegrationStatus["Paused"] = "paused";
    UserIntegrationStatus["Banned"] = "banned";
    UserIntegrationStatus["Approved"] = "approved";
    return UserIntegrationStatus;
})({});
export const UserIntegrationStatusLabels = new Map([
    [UserIntegrationStatus.New, 'Global.Status.New'],
    [UserIntegrationStatus.Negotiation, 'Global.UserIntegrationStatus.Negotiation'],
    [UserIntegrationStatus.Integrating, 'Global.UserIntegrationStatus.Integrating'],
    [UserIntegrationStatus.WaitingForLaunch, 'Global.UserIntegrationStatus.WaitingForLaunch'],
    [UserIntegrationStatus.Live, 'Global.UserIntegrationStatus.Live'],
    [UserIntegrationStatus.Paused, 'Global.UserIntegrationStatus.Paused'],
    [UserIntegrationStatus.Banned, 'Global.UserIntegrationStatus.Banned'],
    [UserIntegrationStatus.Approved, 'Global.Status.Approved'],
]);
export let UserIntegrationStatusService = /*@__PURE__*/ (() => {
    class UserIntegrationStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = UserIntegrationStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case UserIntegrationStatus.Live:
                    return 'text-success';
                case UserIntegrationStatus.Banned:
                    return 'text-danger';
                case UserIntegrationStatus.Paused:
                case UserIntegrationStatus.WaitingForLaunch:
                case UserIntegrationStatus.Integrating:
                case UserIntegrationStatus.Negotiation:
                case UserIntegrationStatus.New:
                    return 'text-warning';
                default:
                    return '';
            }
        }
    }
    UserIntegrationStatusService.ɵfac = function UserIntegrationStatusService_Factory(t) { return new (t || UserIntegrationStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    UserIntegrationStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: UserIntegrationStatusService, factory: UserIntegrationStatusService.ɵfac });
    return UserIntegrationStatusService;
})();

