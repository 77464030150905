import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PaidForType = /*@__PURE__*/ (function (PaidForType) {
    PaidForType["Lead"] = "lead";
    PaidForType["Ftd"] = "ftd";
    PaidForType["ReferralBonus"] = "referral_bonus";
    PaidForType["OptIn"] = "opt-in";
    PaidForType["Revshare"] = "revshare";
    PaidForType["CPA"] = "cpa";
    PaidForType["CPL"] = "cpl";
    PaidForType["CRG"] = "crg";
    PaidForType["EPL"] = "epl";
    return PaidForType;
})({});
export const PaidForTypeLabels = new Map([
    [PaidForType.Lead, 'Global.Options.PaidForTypeLabels.Lead'],
    [PaidForType.Ftd, 'Global.Options.PaidForTypeLabels.Ftd'],
    [PaidForType.ReferralBonus, 'Global.Options.PaidForTypeLabels.ReferralBonus'],
    [PaidForType.OptIn, 'Global.Options.PaidForTypeLabels.OptIn'],
    [PaidForType.Revshare, 'Global.Options.Revshare'],
    [PaidForType.CPA, 'Global.Options.PaidForTypeLabels.CPA'],
    [PaidForType.CPL, 'Global.Options.PaidForTypeLabels.CPL'],
    [PaidForType.CRG, 'Global.Options.PaidForTypeLabels.CRG'],
    [PaidForType.EPL, 'Global.Options.PaidForTypeLabels.EPL'],
]);
export let PaidForTypeService = /*@__PURE__*/ (() => {
    class PaidForTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PaidForTypeLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            return status
                ? status
                    .split(/, ?/)
                    .map((key) => {
                    const k = this._data.get(key);
                    return k ? this._translate.instant(k) : k;
                })
                    .filter((item) => item)
                    .join(', ')
                : null;
        }
    }
    PaidForTypeService.ɵfac = function PaidForTypeService_Factory(t) { return new (t || PaidForTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    PaidForTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: PaidForTypeService, factory: PaidForTypeService.ɵfac });
    return PaidForTypeService;
})();

