import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var EmailStatus = /*@__PURE__*/ (function (EmailStatus) {
    EmailStatus[EmailStatus["Unconfirmed"] = 0] = "Unconfirmed";
    EmailStatus[EmailStatus["Confirmed"] = 1] = "Confirmed";
    return EmailStatus;
})({});
export const EmailStatusMap = new Map([
    [EmailStatus.Unconfirmed, 'Global.EmailStatus.Unconfirmed'],
    [EmailStatus.Confirmed, 'Global.EmailStatus.Confirmed'],
]);
export let EmailStatusService = /*@__PURE__*/ (() => {
    class EmailStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = EmailStatusMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case EmailStatus.Confirmed:
                    return 'text-success';
                case EmailStatus.Unconfirmed:
                    return 'text-danger';
                default:
                    return 'text-warning';
            }
        }
    }
    EmailStatusService.ɵfac = function EmailStatusService_Factory(t) { return new (t || EmailStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    EmailStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: EmailStatusService, factory: EmailStatusService.ɵfac });
    return EmailStatusService;
})();

