import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PaymentStatuses = /*@__PURE__*/ (function (PaymentStatuses) {
    PaymentStatuses[PaymentStatuses["Pending"] = 0] = "Pending";
    PaymentStatuses[PaymentStatuses["AwaitingInvoice"] = 1] = "AwaitingInvoice";
    PaymentStatuses[PaymentStatuses["Completed"] = 2] = "Completed";
    PaymentStatuses[PaymentStatuses["Bounced"] = 3] = "Bounced";
    PaymentStatuses[PaymentStatuses["Cancelled"] = 4] = "Cancelled";
    PaymentStatuses[PaymentStatuses["InvoiceReceived"] = 5] = "InvoiceReceived";
    PaymentStatuses[PaymentStatuses["InvoiceConfirmed"] = 7] = "InvoiceConfirmed";
    PaymentStatuses[PaymentStatuses["PaymentSent"] = 8] = "PaymentSent";
    PaymentStatuses[PaymentStatuses["Moderated"] = 9] = "Moderated";
    return PaymentStatuses;
})({});
export const PaymentStatusesMap = new Map([
    [PaymentStatuses.Pending, 'Global.Status.Pending'],
    [PaymentStatuses.InvoiceConfirmed, 'Global.Options.PaymentStatuses.InvoiceConfirmed'],
    [PaymentStatuses.InvoiceReceived, 'Global.Options.PaymentStatuses.InvoiceReceived'],
    [PaymentStatuses.PaymentSent, 'Global.Options.PaymentStatuses.PaymentSent'],
    [PaymentStatuses.Completed, 'Global.Options.PaymentStatuses.Completed'],
    [PaymentStatuses.Cancelled, 'Global.Options.PaymentStatuses.Cancelled'],
    [PaymentStatuses.Bounced, 'Global.Options.PaymentStatuses.Bounced'],
    [PaymentStatuses.AwaitingInvoice, 'Global.Options.PaymentStatuses.AwaitingInvoice'],
]);
export let PaymentStatusService = /*@__PURE__*/ (() => {
    class PaymentStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PaymentStatusesMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStatusClass(status) {
            switch (+status) {
                case PaymentStatuses.InvoiceReceived:
                case PaymentStatuses.InvoiceConfirmed:
                case PaymentStatuses.PaymentSent:
                case PaymentStatuses.Completed:
                    return 'text-success';
                case PaymentStatuses.Cancelled:
                case PaymentStatuses.Bounced:
                    return 'text-danger';
                case PaymentStatuses.Pending:
                case PaymentStatuses.AwaitingInvoice:
                case PaymentStatuses.Moderated:
                    return 'text-warning';
                default:
                    return '';
            }
        }
    }
    PaymentStatusService.ɵfac = function PaymentStatusService_Factory(t) { return new (t || PaymentStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    PaymentStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: PaymentStatusService, factory: PaymentStatusService.ɵfac });
    return PaymentStatusService;
})();

