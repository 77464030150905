import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export let RegistrationApiService = /*@__PURE__*/ (() => {
    class RegistrationApiService {
        constructor(httpClient) {
            this.httpClient = httpClient;
        }
        register(body, options) {
            return this.httpClient.post(`/registration/first-time?th-lang=${localStorage.getItem('language') || 'en'}&ws=${location.origin}`, Object.assign(Object.assign({}, body), { ws: location.origin }), options);
        }
        verifyEmail(body, options) {
            return this.httpClient.post(`/registration/verify-email?th-lang=${localStorage.getItem('language') || 'en'}`, body, options);
        }
    }
    RegistrationApiService.ɵfac = function RegistrationApiService_Factory(t) { return new (t || RegistrationApiService)(i0.ɵɵinject(i1.HttpClient)); };
    RegistrationApiService.ɵprov = i0.ɵɵdefineInjectable({ token: RegistrationApiService, factory: RegistrationApiService.ɵfac });
    return RegistrationApiService;
})();

