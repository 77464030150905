import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CampaignStatus = /*@__PURE__*/ (function (CampaignStatus) {
    CampaignStatus[CampaignStatus["Draft"] = -2] = "Draft";
    CampaignStatus[CampaignStatus["Deleted"] = -1] = "Deleted";
    CampaignStatus[CampaignStatus["Active"] = 1] = "Active";
    CampaignStatus[CampaignStatus["Inactive"] = 2] = "Inactive";
    CampaignStatus[CampaignStatus["Suspended"] = 3] = "Suspended";
    CampaignStatus[CampaignStatus["Archived"] = 4] = "Archived";
    return CampaignStatus;
})({});
export const CampaignStatusLabels = new Map([
    [CampaignStatus.Draft, 'Global.Options.CampaignStatusLabels.Draft'],
    [CampaignStatus.Deleted, 'Global.Status.Deleted'],
    [CampaignStatus.Active, 'Global.Status.Active'],
    [CampaignStatus.Inactive, 'Global.Options.CampaignStatusLabels.Inactive'],
    [CampaignStatus.Suspended, 'Global.Options.CampaignStatusLabels.Suspended'],
    [CampaignStatus.Archived, 'Global.Options.CampaignStatusLabels.Archived'],
]);
export let CampaignStatusService = /*@__PURE__*/ (() => {
    class CampaignStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CampaignStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
        getStyleClass(status) {
            switch (status) {
                case CampaignStatus.Active:
                    return 'text-success';
                case CampaignStatus.Archived:
                case CampaignStatus.Deleted:
                    return 'text-danger';
                case CampaignStatus.Inactive:
                case CampaignStatus.Suspended:
                case CampaignStatus.Draft:
                    return 'text-warning';
            }
        }
    }
    CampaignStatusService.ɵfac = function CampaignStatusService_Factory(t) { return new (t || CampaignStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    CampaignStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: CampaignStatusService, factory: CampaignStatusService.ɵfac });
    return CampaignStatusService;
})();

