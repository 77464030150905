import { DOCUMENT } from '@angular/common';
import { SERVER, TypeServer } from '@ho/shared/constants/server';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Observable, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ho/shared/data-access/session";
import * as i2 from "@angular/router";
export let GuestUserGuard = /*@__PURE__*/ (() => {
    class GuestUserGuard {
        constructor(_authQuery, _authService, _router, environment, document) {
            this._authQuery = _authQuery;
            this._authService = _authService;
            this._router = _router;
            this.environment = environment;
            this.document = document;
        }
        canActivate(route, state) {
            return Observable.create((observer) => {
                const isAuthApp = !this.environment.role.length; // it's a separate application for Production's build mode
                if (this.environment.production && !isAuthApp && SERVER.type === TypeServer.NotLocalhost) {
                    // redirect to auth-app
                    this.document.location.href = `${location.origin}/v3/auth`;
                }
                else {
                    if (this._authQuery.isLoggedIn() && this._authQuery.userInfo()) {
                        this._navigateUserToAppropriateApp(isAuthApp, observer);
                        return of(true);
                    }
                    if (localStorage.getItem(this.environment.jwtTokenName)) {
                        return this._authService
                            .autologin(localStorage.getItem(this.environment.jwtTokenName))
                            .subscribe(() => {
                            this._navigateUserToAppropriateApp(isAuthApp, observer);
                        });
                    }
                }
                observer.next(true);
                observer.complete();
            });
        }
        _navigateUserToAppropriateApp(isAuthApp, observer) {
            if (!isAuthApp) {
                this._router.navigate(['/d']);
                observer.next(false);
                observer.complete();
            }
            else {
                this._authService.switchApp(observer);
            }
        }
    }
    GuestUserGuard.ɵfac = function GuestUserGuard_Factory(t) { return new (t || GuestUserGuard)(i0.ɵɵinject(i1.SessionQuery), i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(AppEnvironment), i0.ɵɵinject(DOCUMENT)); };
    GuestUserGuard.ɵprov = i0.ɵɵdefineInjectable({ token: GuestUserGuard, factory: GuestUserGuard.ɵfac });
    return GuestUserGuard;
})();

