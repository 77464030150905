import { map, pluck } from 'rxjs/operators';
import { map as _map } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./language-status.service";
export let LanguageService = /*@__PURE__*/ (() => {
    class LanguageService {
        constructor(_httpClient, _languageStatusService) {
            this._httpClient = _httpClient;
            this._languageStatusService = _languageStatusService;
            this.endpointUrl = `/dictionary/languages`;
        }
        list(params) {
            return this._httpClient
                .post(`${this.endpointUrl}/list`, params, { observe: 'response' })
                .pipe(map((response) => {
                response.body['data'] = _map(response.body['data'], (item) => {
                    return Object.assign(Object.assign({}, item), { system_name: item.system_name.toUpperCase(), status_label: this._languageStatusService.get(item.status), statusClass: this._languageStatusService.getStyleClass(item.status) });
                });
                return response;
            }));
        }
        post(body, options) {
            return this._httpClient.post(this.endpointUrl, body, options).pipe(pluck('data'));
        }
        put(id, body, options) {
            return this._httpClient.put(`${this.endpointUrl}/${id}`, body, options).pipe(pluck('data'));
        }
        save(body, options) {
            return body.id ? this.put(body.id, body, options) : this.post(body, options);
        }
        get(id, options) {
            return this._httpClient.get(`${this.endpointUrl}/${id}`, options).pipe(pluck('data'));
        }
        delete(id, options) {
            return this._httpClient
                .delete(`${this.endpointUrl}/${id}`, options)
                .pipe(map((r) => r.success === true));
        }
    }
    LanguageService.ɵfac = function LanguageService_Factory(t) { return new (t || LanguageService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LanguageStatusService)); };
    LanguageService.ɵprov = i0.ɵɵdefineInjectable({ token: LanguageService, factory: LanguageService.ɵfac });
    return LanguageService;
})();

