import { Query } from '@datorama/akita';
import * as i0 from "@angular/core";
import * as i1 from "./dictionaries.store";
export let DictionariesQuery = /*@__PURE__*/ (() => {
    class DictionariesQuery extends Query {
        constructor(store) {
            super(store);
            this.store = store;
        }
        get query() {
            return this.getValue().query;
        }
    }
    DictionariesQuery.ɵfac = function DictionariesQuery_Factory(t) { return new (t || DictionariesQuery)(i0.ɵɵinject(i1.DictionariesStore)); };
    DictionariesQuery.ɵprov = i0.ɵɵdefineInjectable({ token: DictionariesQuery, factory: DictionariesQuery.ɵfac });
    return DictionariesQuery;
})();

