import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var PayoutRollbackStatus = /*@__PURE__*/ (function (PayoutRollbackStatus) {
    PayoutRollbackStatus[PayoutRollbackStatus["AwaitingInvoice"] = 1] = "AwaitingInvoice";
    PayoutRollbackStatus[PayoutRollbackStatus["Bounced"] = 3] = "Bounced";
    PayoutRollbackStatus[PayoutRollbackStatus["InvoiceConfirmed"] = 7] = "InvoiceConfirmed";
    PayoutRollbackStatus[PayoutRollbackStatus["PaymentSent"] = 8] = "PaymentSent";
    return PayoutRollbackStatus;
})({});
export const PayoutRollbackStatusLabels = new Map([
    [PayoutRollbackStatus.AwaitingInvoice, 'Statuses.PayoutRollbackStatusLabels.AwaitingInvoice'],
    [PayoutRollbackStatus.Bounced, 'Statuses.PayoutRollbackStatusLabels.Bounced'],
    [PayoutRollbackStatus.InvoiceConfirmed, 'Statuses.PayoutRollbackStatusLabels.InvoiceConfirmed'],
    [PayoutRollbackStatus.PaymentSent, 'Statuses.PayoutRollbackStatusLabels.PaymentSent'],
]);
export let PayoutRollbackStatusService = /*@__PURE__*/ (() => {
    class PayoutRollbackStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = PayoutRollbackStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    PayoutRollbackStatusService.ɵfac = function PayoutRollbackStatusService_Factory(t) { return new (t || PayoutRollbackStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    PayoutRollbackStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: PayoutRollbackStatusService, factory: PayoutRollbackStatusService.ɵfac });
    return PayoutRollbackStatusService;
})();

