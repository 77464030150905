import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { DOCUMENT } from '@angular/common';
import * as i0 from "@angular/core";
export let GoogleAnalyticsComponent = /*@__PURE__*/ (() => {
    class GoogleAnalyticsComponent {
        constructor(_document, _environment, renderer) {
            this._document = _document;
            this._environment = _environment;
            this.renderer = renderer;
            if (this._environment.hasOwnProperty('gaUID') && this._environment.gaUID) {
                const scriptInitGTM = this._document.createElement('script');
                const scriptInitGA = this._document.createElement('script');
                const noScriptInitGTM = this._document.createElement('noscript');
                const iframeGtm = this._document.createElement('iframe');
                const scriptImportGTM = this.renderer.createElement('script');
                scriptImportGTM.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this._environment.gaUID}`);
                scriptImportGTM.setAttribute('async', '');
                iframeGtm.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${this._environment.gtmUID}`);
                iframeGtm.setAttribute('height', '0');
                iframeGtm.setAttribute('width', '0');
                iframeGtm.setAttribute('style', 'display:none;visibility:hidden');
                noScriptInitGTM.setAttribute('class', 'gtagNoscript');
                scriptInitGA.innerHTML = `
          window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this._environment.gaUID}')`;
                scriptInitGTM.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this._environment.gtmUID}');
      `;
                this._document.head.appendChild(scriptInitGTM);
                this._document.body.appendChild(scriptImportGTM);
                this._document.body.appendChild(scriptInitGA);
                this._document.body.appendChild(noScriptInitGTM);
                this._document.getElementsByClassName('gtagNoscript')[0].appendChild(iframeGtm);
            }
        }
        ngOnInit() { }
    }
    GoogleAnalyticsComponent.ɵfac = function GoogleAnalyticsComponent_Factory(t) { return new (t || GoogleAnalyticsComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(AppEnvironment), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    GoogleAnalyticsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: GoogleAnalyticsComponent, selectors: [["ho-google-analytics"]], decls: 0, vars: 0, template: function GoogleAnalyticsComponent_Template(rf, ctx) { }, styles: [""] });
    return GoogleAnalyticsComponent;
})();

