import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-permissions";
export let PermissionsService = /*@__PURE__*/ (() => {
    class PermissionsService {
        constructor(_ngxPermissionsService) {
            this._ngxPermissionsService = _ngxPermissionsService;
        }
        hasPermissions(permissions) {
            return this._hasPermissions(permissions);
        }
        hasPermissions$(permissions) {
            return of(this._hasPermissions(permissions));
        }
        _hasPermissions(permissions) {
            if (!(permissions === null || permissions === void 0 ? void 0 : permissions.length)) {
                return [];
            }
            return permissions.reduce((acc, permission) => {
                const permissionObject = this._ngxPermissionsService.getPermission(permission);
                acc.push((permissionObject === null || permissionObject === void 0 ? void 0 : permissionObject.validationFunction) ? permissionObject.validationFunction() : false);
                return acc;
            }, []);
        }
    }
    PermissionsService.ɵfac = function PermissionsService_Factory(t) { return new (t || PermissionsService)(i0.ɵɵinject(i1.NgxPermissionsService)); };
    PermissionsService.ɵprov = i0.ɵɵdefineInjectable({ token: PermissionsService, factory: PermissionsService.ɵfac, providedIn: 'root' });
    return PermissionsService;
})();

