import { ConfirmModalContainer } from '../containers/confirm-modal/confirm-modal.container';
import { ModalHelper } from '@ho/shared/helpers/modal.helper';
import { ConfirmActionModalContainer } from '../containers/confirm-action-modal/confirm-action-modal.container';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export let ConfirmModalService = /*@__PURE__*/ (() => {
    class ConfirmModalService {
        constructor(dialog) {
            this.dialog = dialog;
            this._defaultOptions = {
                maxWidth: '590px',
                width: 'calc(100% - 30px)',
                minWidth: '290px',
                autoFocus: false,
                panelClass: 'modal-dialog',
            };
        }
        open(data) {
            const ref = this.dialog.open(ConfirmModalContainer, Object.assign(Object.assign({}, this._defaultOptions), { data: Object.assign({}, data) }));
            ModalHelper.addClassToParent(ref);
            return ref;
        }
        openAction(data) {
            const ref = this.dialog.open(ConfirmActionModalContainer, Object.assign(Object.assign({}, this._defaultOptions), { data: Object.assign({}, data) }));
            ModalHelper.addClassToParent(ref);
            return ref;
        }
    }
    ConfirmModalService.ɵfac = function ConfirmModalService_Factory(t) { return new (t || ConfirmModalService)(i0.ɵɵinject(i1.MatDialog)); };
    ConfirmModalService.ɵprov = i0.ɵɵdefineInjectable({ token: ConfirmModalService, factory: ConfirmModalService.ɵfac });
    return ConfirmModalService;
})();

