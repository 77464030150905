import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var UnvoidReason = /*@__PURE__*/ (function (UnvoidReason) {
    UnvoidReason["VoidByMistake"] = "void_by_mistake";
    UnvoidReason["Other"] = "other";
    return UnvoidReason;
})({});
export const UnvoidReasonMap = new Map([
    [UnvoidReason.VoidByMistake, 'Global.Options.UnvoidReason.VoidByMistake'],
    [UnvoidReason.Other, 'Global.Options.UnvoidReason.Other'],
]);
export let UnvoidReasonService = /*@__PURE__*/ (() => {
    class UnvoidReasonService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = UnvoidReasonMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        has(key) {
            return this._data.has(key);
        }
    }
    UnvoidReasonService.ɵfac = function UnvoidReasonService_Factory(t) { return new (t || UnvoidReasonService)(i0.ɵɵinject(i1.TranslateService)); };
    UnvoidReasonService.ɵprov = i0.ɵɵdefineInjectable({ token: UnvoidReasonService, factory: UnvoidReasonService.ɵfac });
    return UnvoidReasonService;
})();

