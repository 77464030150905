import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../+state/data-source-handler.service";
export let RedirectTypesService = /*@__PURE__*/ (() => {
    class RedirectTypesService {
        constructor(_httpClient, _dataSourceHandlerService) {
            this._httpClient = _httpClient;
            this._dataSourceHandlerService = _dataSourceHandlerService;
            this._endpointUrl = '/dictionary';
        }
        /**
         *
         * @returns {Observable<T>}
         */
        list() {
            return this._dataSourceHandlerService
                .getData(`${this._endpointUrl}/redirect-types`)
                .pipe(pluck('data'))
                .toPromise();
        }
        /**
         *
         * @returns {Observable<T>}
         */
        get(id) {
            return this._httpClient
                .get(`${this._endpointUrl}/redirect-types/${id}`)
                .pipe(pluck('data'));
        }
    }
    RedirectTypesService.ɵfac = function RedirectTypesService_Factory(t) { return new (t || RedirectTypesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DataSourceHandlerService)); };
    RedirectTypesService.ɵprov = i0.ɵɵdefineInjectable({ token: RedirectTypesService, factory: RedirectTypesService.ɵfac });
    return RedirectTypesService;
})();

