import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../../../widgets/qa-attribute-widget/src/lib/directives/qa-attribute-widget.directive";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/material/button";
import * as i5 from "@ngx-translate/core";
export let ConfirmModalContainer = /*@__PURE__*/ (() => {
    class ConfirmModalContainer {
        constructor(_dialogRef, modalData) {
            this._dialogRef = _dialogRef;
            this.modalData = modalData;
        }
        onSubmit(key) {
            this._dialogRef.close(key);
        }
    }
    ConfirmModalContainer.ɵfac = function ConfirmModalContainer_Factory(t) { return new (t || ConfirmModalContainer)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA)); };
    ConfirmModalContainer.ɵcmp = i0.ɵɵdefineComponent({ type: ConfirmModalContainer, selectors: [["ho-confirm-modal-container"]], decls: 15, vars: 15, consts: [[1, "top-modal-panel"], ["mat-dialog-title", "", 3, "hoQaAttributeWidget"], ["mat-dialog-close", "", 1, "modal-dialog__close-btn", 3, "hoQaAttributeWidget"], [1, "icon-close"], ["mat-dialog-content", ""], [3, "innerHTML", "hoQaAttributeWidget"], ["fxLayout", "row", "fxLayoutAlign", "flex-end", "fxLayoutGap", "10px", 1, "mt-15"], ["mat-button", "", 1, "btn", "btn_success", 3, "hoQaAttributeWidget", "click"], ["mat-button", "", 1, "btn", "btn_danger", 3, "hoQaAttributeWidget", "click"]], template: function ConfirmModalContainer_Template(rf, ctx) {
            if (rf & 1) {
                i0.ɵɵelementStart(0, "div", 0);
                i0.ɵɵelementStart(1, "h1", 1);
                i0.ɵɵtext(2);
                i0.ɵɵpipe(3, "translate");
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(4, "button", 2);
                i0.ɵɵelement(5, "span", 3);
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(6, "div", 4);
                i0.ɵɵelement(7, "div", 5);
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(8, "div", 6);
                i0.ɵɵelementStart(9, "button", 7);
                i0.ɵɵlistener("click", function ConfirmModalContainer_Template_button_click_9_listener() { return ctx.onSubmit(true); });
                i0.ɵɵtext(10);
                i0.ɵɵpipe(11, "translate");
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(12, "button", 8);
                i0.ɵɵlistener("click", function ConfirmModalContainer_Template_button_click_12_listener() { return ctx.onSubmit(false); });
                i0.ɵɵtext(13);
                i0.ɵɵpipe(14, "translate");
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
            }
            if (rf & 2) {
                i0.ɵɵadvance(1);
                i0.ɵɵproperty("hoQaAttributeWidget", "title_modal");
                i0.ɵɵadvance(1);
                i0.ɵɵtextInterpolate1(" ", (ctx.modalData == null ? null : ctx.modalData.hideTitleText) ? " " : (ctx.modalData == null ? null : ctx.modalData.title) || i0.ɵɵpipeBind1(3, 9, "ConfirmModal.Title.AreYouSure"), " ");
                i0.ɵɵadvance(2);
                i0.ɵɵproperty("hoQaAttributeWidget", "button_close-modal");
                i0.ɵɵadvance(3);
                i0.ɵɵproperty("innerHTML", ctx.modalData == null ? null : ctx.modalData.body, i0.ɵɵsanitizeHtml)("hoQaAttributeWidget", "confirm-modal_text");
                i0.ɵɵadvance(2);
                i0.ɵɵproperty("hoQaAttributeWidget", "button-confirm");
                i0.ɵɵadvance(1);
                i0.ɵɵtextInterpolate1(" ", (ctx.modalData == null ? null : ctx.modalData.resolveText) || i0.ɵɵpipeBind1(11, 11, "Global.Text.Yes"), " ");
                i0.ɵɵadvance(2);
                i0.ɵɵproperty("hoQaAttributeWidget", "button-reject");
                i0.ɵɵadvance(1);
                i0.ɵɵtextInterpolate1(" ", (ctx.modalData == null ? null : ctx.modalData.rejectText) || i0.ɵɵpipeBind1(14, 13, "Global.Text.No"), " ");
            }
        }, directives: [i1.MatDialogTitle, i2.QaAttributeWidgetDirective, i1.MatDialogClose, i1.MatDialogContent, i3.DefaultLayoutDirective, i3.DefaultLayoutAlignDirective, i3.DefaultLayoutGapDirective, i4.MatButton], pipes: [i5.TranslatePipe], styles: [".mat-dialog-title[_ngcontent-%COMP%]{padding-bottom:25px;margin-bottom:0!important}"] });
    return ConfirmModalContainer;
})();

