import { tap } from 'rxjs/operators';
import { fromEvent, of } from 'rxjs';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import * as i0 from "@angular/core";
import * as i1 from "../services/ui-settings-api.service";
import * as i2 from "../+state/ui-settings.store";
export let ThemeSettingsService = /*@__PURE__*/ (() => {
    class ThemeSettingsService {
        constructor(_uiSettingsApiService, _uiSettingsStore) {
            this._uiSettingsApiService = _uiSettingsApiService;
            this._uiSettingsStore = _uiSettingsStore;
            this.root = document === null || document === void 0 ? void 0 : document.documentElement;
        }
        getSelectedTheme() { }
        setProperty(variable, value) {
            this.root.style.setProperty(variable, value);
        }
        updateColorSchemes(schemes) {
            return this._uiSettingsApiService.updateColorSchemes(schemes);
        }
        patchProperties(properties) {
            this.root.setAttribute('style', properties);
        }
        initColorSchemeWatcher() {
            if (this._isColorSchemeWatcherTurnOn) {
                return;
            }
            this._isColorSchemeWatcherTurnOn = true;
            fromEvent(window, 'storage').subscribe((evt) => {
                if (evt.key !== StoreNames.ColorScheme) {
                    return;
                }
                this.applyThemes(JSON.parse(evt.newValue));
            });
        }
        applyThemes(colorSchemes) {
            const colorScheme = colorSchemes.color_schemes.find((item) => item.name === colorSchemes.selected_color_scheme).color_scheme;
            this.patchProperties(Object.keys(colorScheme).reduce((accScheme, key) => {
                accScheme += colorScheme[key].reduce((acc, item) => {
                    acc += `${item.variable}:${item.value};`;
                    return acc;
                }, '');
                return accScheme;
            }, ''));
            localStorage.setItem('selected_color_scheme', colorSchemes.selected_color_scheme);
        }
        updateThemeConfig(schemes) {
            return (localStorage.getItem('user_view_type')
                ? of({})
                : this._uiSettingsApiService.updateColorSchemes(schemes)).pipe(tap(() => {
                this._uiSettingsStore.updateThemeSettings({
                    selected_color_scheme: schemes.selected_color_scheme,
                });
            }));
        }
    }
    ThemeSettingsService.ɵfac = function ThemeSettingsService_Factory(t) { return new (t || ThemeSettingsService)(i0.ɵɵinject(i1.UiSettingsApiService), i0.ɵɵinject(i2.UiSettingsStore)); };
    ThemeSettingsService.ɵprov = i0.ɵɵdefineInjectable({ token: ThemeSettingsService, factory: ThemeSettingsService.ɵfac });
    return ThemeSettingsService;
})();

