import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./ui-settings-api.service";
export let TutorialSettingsService = /*@__PURE__*/ (() => {
    class TutorialSettingsService {
        constructor(_uiSettingsApiService) {
            this._uiSettingsApiService = _uiSettingsApiService;
        }
        skipTutorial() {
            return this._uiSettingsApiService.skipTutorial().pipe(pluck('data'));
        }
    }
    TutorialSettingsService.ɵfac = function TutorialSettingsService_Factory(t) { return new (t || TutorialSettingsService)(i0.ɵɵinject(i1.UiSettingsApiService)); };
    TutorialSettingsService.ɵprov = i0.ɵɵdefineInjectable({ token: TutorialSettingsService, factory: TutorialSettingsService.ɵfac });
    return TutorialSettingsService;
})();

