import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var CampaignAccess = /*@__PURE__*/ (function (CampaignAccess) {
    CampaignAccess[CampaignAccess["Unlisted"] = 1] = "Unlisted";
    CampaignAccess[CampaignAccess["Private"] = 2] = "Private";
    CampaignAccess[CampaignAccess["ApplyToPromote"] = 3] = "ApplyToPromote";
    CampaignAccess[CampaignAccess["Public"] = 4] = "Public";
    return CampaignAccess;
})({});
export const CampaignAccessLabels = new Map([
    [CampaignAccess.Unlisted, 'Global.Options.CampaignAccessLabels.Unlisted'],
    [CampaignAccess.Private, 'Global.Options.CampaignAccessLabels.Private'],
    [CampaignAccess.ApplyToPromote, 'Global.Options.CampaignAccessLabels.ApplyToPromote'],
    [CampaignAccess.Public, 'Global.Options.CampaignAccessLabels.Public'],
]);
export let CampaignAccessService = /*@__PURE__*/ (() => {
    class CampaignAccessService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = CampaignAccessLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    CampaignAccessService.ɵfac = function CampaignAccessService_Factory(t) { return new (t || CampaignAccessService)(i0.ɵɵinject(i1.TranslateService)); };
    CampaignAccessService.ɵprov = i0.ɵɵdefineInjectable({ token: CampaignAccessService, factory: CampaignAccessService.ɵfac });
    return CampaignAccessService;
})();

