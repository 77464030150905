import * as i0 from "@angular/core";
import * as i1 from "./registration-api.service";
export let RegistrationService = /*@__PURE__*/ (() => {
    class RegistrationService {
        constructor(apiService) {
            this.apiService = apiService;
        }
        register(body, options) {
            return this.apiService.register(body, options);
        }
        storeEmail(email) {
            localStorage.setItem('userEmail', email);
        }
        getRegistrationEmail() {
            return localStorage.getItem('userEmail');
        }
        verifyEmail(body, options) {
            return this.apiService.verifyEmail(body, options);
        }
    }
    RegistrationService.ɵfac = function RegistrationService_Factory(t) { return new (t || RegistrationService)(i0.ɵɵinject(i1.RegistrationApiService)); };
    RegistrationService.ɵprov = i0.ɵɵdefineInjectable({ token: RegistrationService, factory: RegistrationService.ɵfac });
    return RegistrationService;
})();

