import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var NotPayableSalesReasonsStatus = /*@__PURE__*/ (function (NotPayableSalesReasonsStatus) {
    NotPayableSalesReasonsStatus["commission_type_not_matched"] = "commission_type_not_matched";
    NotPayableSalesReasonsStatus["recurrent_duration_exceeded"] = "recurrent_duration_exceeded";
    NotPayableSalesReasonsStatus["recurrent_cpa_sale"] = "recurrent_cpa_sale";
    NotPayableSalesReasonsStatus["click_session_lifespan_exceeded"] = "click_session_lifespan_exceeded";
    return NotPayableSalesReasonsStatus;
})({});
export const NotPayableSalesReasonsStatusMap = new Map([
    [
        NotPayableSalesReasonsStatus.commission_type_not_matched,
        'ActionInfoModal.Payable.Tooltip.CommissionTypeNotMatched',
    ],
    [
        NotPayableSalesReasonsStatus.recurrent_duration_exceeded,
        'ActionInfoModal.Payable.Tooltip.RecurrentDurationsExceeded',
    ],
    [NotPayableSalesReasonsStatus.recurrent_cpa_sale, 'ActionInfoModal.Payable.Tooltip.RecurrentCPASale'],
    [
        NotPayableSalesReasonsStatus.click_session_lifespan_exceeded,
        'ActionInfoModal.Payable.Tooltip.ClickSessionLifespanExceeded',
    ],
]);
export let NotPayableSalesReasonsService = /*@__PURE__*/ (() => {
    class NotPayableSalesReasonsService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = NotPayableSalesReasonsStatusMap;
        }
        list() {
            return this._data;
        }
        get(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
    }
    NotPayableSalesReasonsService.ɵfac = function NotPayableSalesReasonsService_Factory(t) { return new (t || NotPayableSalesReasonsService)(i0.ɵɵinject(i1.TranslateService)); };
    NotPayableSalesReasonsService.ɵprov = i0.ɵɵdefineInjectable({ token: NotPayableSalesReasonsService, factory: NotPayableSalesReasonsService.ɵfac });
    return NotPayableSalesReasonsService;
})();

