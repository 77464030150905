export function parseQueryParams(queryParamsString) {
    if (!queryParamsString) {
        return {};
    }
    const pl = /\+/g; // Regex for replacing addition symbol with a space
    const search = /([^&=]+)=?([^&]*)/g;
    const decode = (s) => {
        return decodeURIComponent(s.replace(pl, '%2B'));
    };
    const query = decodeURIComponent(queryParamsString.replace('?', ''));
    let match;
    const urlParams = {};
    while ((match = search.exec(query))) {
        if (Object.keys(urlParams).includes(decode(match[1]))) {
            if (typeof urlParams[decode(match[1])] === 'string') {
                urlParams[decode(match[1])] = [urlParams[decode(match[1])], decode(match[2])];
            }
            else {
                urlParams[decode(match[1])].push(decode(match[2]));
            }
        }
        else {
            urlParams[decode(match[1])] = decode(match[2]);
        }
    }
    return urlParams;
}
export function prepareQueryParams(obj) {
    const params = Object.keys(obj).reduce((acc, key) => {
        if (obj[key] && typeof obj[key] !== 'string') {
            acc += obj[key].reduce((acc2, key2) => {
                acc2 += `&${key}=${key2}`;
                return acc2;
            }, '');
        }
        else if (obj[key]) {
            acc += `&${key}=${obj[key]}`;
        }
        return acc;
    }, '');
    return encodeURIComponent(params.replace(/^\&/, ''));
}
export function navigateByRequestUrl(location, router) {
    const href = location.href;
    if (href.includes('%') && href.includes('/d/')) {
        const url = '/d/' + location.pathname.replace(/^[\/].+\/d\//, '');
        const queryParams = parseQueryParams(location.search);
        router.navigate([url], { queryParams });
    }
}

