import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var TransactionLogActionType = /*@__PURE__*/ (function (TransactionLogActionType) {
    TransactionLogActionType["commission_void"] = "commission_void";
    TransactionLogActionType["commission_change"] = "commission_change";
    TransactionLogActionType["commission_unvoid"] = "commission_unvoid";
    return TransactionLogActionType;
})({});
export const TransactionLogActionTypeMap = new Map([
    [TransactionLogActionType.commission_void, 'Global.Options.TransactionLogActionType.VoidTransaction'],
    [TransactionLogActionType.commission_change, 'Global.Options.TransactionLogActionType.ChangeTransaction'],
    [TransactionLogActionType.commission_unvoid, 'Global.Options.TransactionLogActionType.UnvoidTransaction'],
]);
export let LogActionTypeService = /*@__PURE__*/ (() => {
    class LogActionTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TransactionLogActionTypeMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    LogActionTypeService.ɵfac = function LogActionTypeService_Factory(t) { return new (t || LogActionTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    LogActionTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: LogActionTypeService, factory: LogActionTypeService.ɵfac });
    return LogActionTypeService;
})();

