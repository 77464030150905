import { TranslateModule } from '@ngx-translate/core';
import { RequestInterceptorService } from './services/http-interceptor.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionDataAccessModule } from '@ho/shared/data-access/session';
import { AuthGuardService } from './guards/auth-guard.service';
import { GuestUserGuard } from '@ho/shared/data-access/core/lib/guards/guest-user.guard';
import { SnackBarModule } from '@ho/shared/ui/snack-bar';
import { AuthDataAccessModule } from '@ho/auth/data-access';
import { NgxPermissionsModule } from 'ngx-permissions';
import * as i0 from "@angular/core";
export let CoreDataAccessModule = /*@__PURE__*/ (() => {
    class CoreDataAccessModule {
    }
    CoreDataAccessModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreDataAccessModule });
    CoreDataAccessModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreDataAccessModule_Factory(t) { return new (t || CoreDataAccessModule)(); }, providers: [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: RequestInterceptorService,
                multi: true,
            },
            AuthGuardService,
            GuestUserGuard,
        ], imports: [[
                CommonModule,
                SessionDataAccessModule.forRoot(),
                SnackBarModule,
                NgxPermissionsModule.forRoot(),
                AuthDataAccessModule,
                TranslateModule,
            ]] });
    return CoreDataAccessModule;
})();

