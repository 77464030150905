import { DateTime } from 'luxon';
import * as i0 from "@angular/core";
export let DateTimeHelper = /*@__PURE__*/ (() => {
    class DateTimeHelper {
        static parseISO(date) {
            return DateTime.fromISO(date).toISO({
                suppressMilliseconds: true
            });
        }
        static parseFormat(date, format) {
            return DateTime.fromFormat(date, format);
        }
    }
    /**
     * Acceptable by backend server ISO format
     * It's a bit different from moment.js ISO format
     * @type {string}
     */
    DateTimeHelper.ISO_FORMAT = "y-LL-dd'T'HH:mm:ssZZ";
    DateTimeHelper.ɵfac = function DateTimeHelper_Factory(t) { return new (t || DateTimeHelper)(); };
    DateTimeHelper.ɵprov = i0.ɵɵdefineInjectable({ token: DateTimeHelper, factory: DateTimeHelper.ɵfac });
    return DateTimeHelper;
})();

