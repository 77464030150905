import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var Tags = /*@__PURE__*/ (function (Tags) {
    Tags["AllowAffiliate"] = "allow_affiliate";
    Tags["AllowOffer"] = "allow_offer";
    Tags["AllowAdvertiser"] = "allow_advertiser";
    return Tags;
})({});
export const TagsMap = new Map([
    [Tags.AllowAffiliate, 'Global.Options.Tags.AllowAffiliate'],
    [Tags.AllowOffer, 'Global.Options.Offer'],
    [Tags.AllowAdvertiser, 'Global.Options.Tags.AllowAdvertiser'],
]);
export let TagsService = /*@__PURE__*/ (() => {
    class TagsService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = TagsMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    TagsService.ɵfac = function TagsService_Factory(t) { return new (t || TagsService)(i0.ɵɵinject(i1.TranslateService)); };
    TagsService.ɵprov = i0.ɵɵdefineInjectable({ token: TagsService, factory: TagsService.ɵfac });
    return TagsService;
})();

