import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import * as i0 from "@angular/core";
import * as i1 from "./session.query";
export var UserWorkspaceRole = /*@__PURE__*/ (function (UserWorkspaceRole) {
    UserWorkspaceRole[UserWorkspaceRole["Guest"] = -1] = "Guest";
    UserWorkspaceRole[UserWorkspaceRole["Admin"] = 1] = "Admin";
    UserWorkspaceRole[UserWorkspaceRole["ATL"] = 2] = "ATL";
    UserWorkspaceRole[UserWorkspaceRole["AM"] = 3] = "AM";
    UserWorkspaceRole[UserWorkspaceRole["Aff"] = 4] = "Aff";
    UserWorkspaceRole[UserWorkspaceRole["AD"] = 5] = "AD";
    UserWorkspaceRole[UserWorkspaceRole["ADM"] = 6] = "ADM";
    return UserWorkspaceRole;
})({});
export const UserWorkspaceMap = new Map([
    [UserWorkspaceRole.Guest, 'guest'],
    [UserWorkspaceRole.Admin, 'admin'],
    [UserWorkspaceRole.ATL, 'atl'],
    [UserWorkspaceRole.AM, 'manager'],
    [UserWorkspaceRole.Aff, 'affiliate'],
    [UserWorkspaceRole.AD, 'customer'],
    [UserWorkspaceRole.ADM, 'adm'],
]);
export let SessionUserTypeService = /*@__PURE__*/ (() => {
    class SessionUserTypeService {
        constructor(environment, _sessionQuery) {
            this.environment = environment;
            this._sessionQuery = _sessionQuery;
            this.userRole = UserWorkspaceRole;
        }
        isAdmin() {
            return this.environment.role.includes(UserWorkspaceRole.Admin);
        }
        isATL() {
            return this.environment.role.includes(UserWorkspaceRole.ATL);
        }
        isAffiliate() {
            return this.environment.role.includes(UserWorkspaceRole.Aff);
        }
        isManager() {
            return this.environment.role.includes(UserWorkspaceRole.AM);
        }
        isADM() {
            return this.environment.role.includes(UserWorkspaceRole.ADM);
        }
        isAD() {
            return this.environment.role.includes(UserWorkspaceRole.AD);
        }
        isAdminRole() {
            return this._sessionQuery.userInfo().workspace_role_id === this.userRole.Admin;
        }
        isATLRole() {
            return this._sessionQuery.userInfo().workspace_role_id === this.userRole.ATL;
        }
        isAffiliateRole() {
            return this._sessionQuery.userInfo().workspace_role_id === this.userRole.Aff;
        }
        isManagerRole() {
            return this._sessionQuery.userInfo().workspace_role_id === this.userRole.AM;
        }
        isADRole() {
            return this._sessionQuery.userInfo().workspace_role_id === this.userRole.AD;
        }
        getAPIUserRoleUrl() {
            const userInfo = this._sessionQuery.userInfo();
            const workspace_role_id = userInfo ? userInfo.workspace_role_id : this.userRole.Guest;
            return UserWorkspaceMap.get(workspace_role_id);
        }
    }
    SessionUserTypeService.ɵfac = function SessionUserTypeService_Factory(t) { return new (t || SessionUserTypeService)(i0.ɵɵinject(AppEnvironment), i0.ɵɵinject(i1.SessionQuery)); };
    SessionUserTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: SessionUserTypeService, factory: SessionUserTypeService.ɵfac });
    return SessionUserTypeService;
})();

