import { MatTooltip } from '@angular/material/tooltip';
import { DEVICE, TypeDevice } from '@ho/shared/constants/device';
import * as i0 from "@angular/core";
export let MobileTooltipHandlerDirective = /*@__PURE__*/ (() => {
    class MobileTooltipHandlerDirective {
        constructor(_vcRef, _renderer, _el) {
            this._vcRef = _vcRef;
            this._renderer = _renderer;
            this._el = _el;
        }
        ngOnInit() {
            this._tooltip = this._vcRef.injector.get(MatTooltip);
            this._isMobile = DEVICE.type === TypeDevice.Mobile;
            if (this._isMobile) {
                this._renderer.listen(this._el.nativeElement, 'mouseenter', () => this._tooltip.show());
                this._renderer.listen(this._el.nativeElement, 'mouseleave', () => this._tooltip.hide());
            }
        }
        ngOnDestroy() {
            if (this._isMobile) {
                this._renderer.destroy();
            }
        }
    }
    MobileTooltipHandlerDirective.ɵfac = function MobileTooltipHandlerDirective_Factory(t) { return new (t || MobileTooltipHandlerDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef)); };
    MobileTooltipHandlerDirective.ɵdir = i0.ɵɵdefineDirective({ type: MobileTooltipHandlerDirective, selectors: [["", "mobileTooltipHandler", ""]] });
    return MobileTooltipHandlerDirective;
})();

