import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var ContactTodayStatus = /*@__PURE__*/ (function (ContactTodayStatus) {
    ContactTodayStatus[ContactTodayStatus["New"] = 1] = "New";
    ContactTodayStatus[ContactTodayStatus["CallAgain"] = 2] = "CallAgain";
    ContactTodayStatus[ContactTodayStatus["Resolved"] = 3] = "Resolved";
    return ContactTodayStatus;
})({});
export const ContactTodayStatusLabels = new Map([
    [ContactTodayStatus.New, 'Global.Status.New'],
    [ContactTodayStatus.CallAgain, 'Global.Options.ContactTodayStatus.CallAgain'],
    [ContactTodayStatus.Resolved, 'Global.Options.ContactTodayStatus.Resolved'],
]);
export let ContactTodayStatusService = /*@__PURE__*/ (() => {
    class ContactTodayStatusService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = ContactTodayStatusLabels;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    ContactTodayStatusService.ɵfac = function ContactTodayStatusService_Factory(t) { return new (t || ContactTodayStatusService)(i0.ɵɵinject(i1.TranslateService)); };
    ContactTodayStatusService.ɵprov = i0.ɵɵdefineInjectable({ token: ContactTodayStatusService, factory: ContactTodayStatusService.ɵfac });
    return ContactTodayStatusService;
})();

