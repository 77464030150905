import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export var UserTypes = /*@__PURE__*/ (function (UserTypes) {
    UserTypes[UserTypes["Guest"] = -1] = "Guest";
    UserTypes[UserTypes["Admin"] = 0] = "Admin";
    UserTypes[UserTypes["AffiliateManager"] = 1] = "AffiliateManager";
    UserTypes[UserTypes["Affiliate"] = 2] = "Affiliate";
    UserTypes[UserTypes["MediaBuyer"] = 3] = "MediaBuyer";
    return UserTypes;
})({});
export const UserTypesMap = new Map([
    [UserTypes.Guest, 'Global.UserRole.Guest'],
    [UserTypes.Admin, 'Global.UserRole.Admin'],
    [UserTypes.AffiliateManager, 'Global.UserRole.Manager'],
    [UserTypes.Affiliate, 'Global.UserRole.Affiliate'],
    [UserTypes.MediaBuyer, 'Global.UserRole.MediaBuyer'],
]);
export let UserTypeService = /*@__PURE__*/ (() => {
    class UserTypeService {
        constructor(_translate) {
            this._translate = _translate;
            this._data = UserTypesMap;
        }
        list() {
            return this._data;
        }
        get(status) {
            const s = this._data.get(status);
            return s ? this._translate.instant(s) : s;
        }
    }
    UserTypeService.ɵfac = function UserTypeService_Factory(t) { return new (t || UserTypeService)(i0.ɵɵinject(i1.TranslateService)); };
    UserTypeService.ɵprov = i0.ɵɵdefineInjectable({ token: UserTypeService, factory: UserTypeService.ɵfac });
    return UserTypeService;
})();

