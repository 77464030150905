import { map, pluck } from 'rxjs/operators';
import { map as _map } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../+state/data-source-handler.service";
import * as i3 from "@ngx-translate/core";
export var TrackingDomainStatus = /*@__PURE__*/ (function (TrackingDomainStatus) {
    TrackingDomainStatus[TrackingDomainStatus["Deactivated"] = -1] = "Deactivated";
    TrackingDomainStatus[TrackingDomainStatus["Active"] = 1] = "Active";
    return TrackingDomainStatus;
})({});
export const TrackingDomainStatusMap = new Map([
    [TrackingDomainStatus.Deactivated, 'Global.Options.TrackingDomainStatus.Deactivated'],
    [TrackingDomainStatus.Active, 'Global.Status.Active'],
]);
export let TrackingDomainsService = /*@__PURE__*/ (() => {
    class TrackingDomainsService {
        constructor(_httpClient, _dataSourceHandlerService, _translate) {
            this._httpClient = _httpClient;
            this._dataSourceHandlerService = _dataSourceHandlerService;
            this._translate = _translate;
            this.endpointUrl = `/tracking-domains/tracking-domains`;
            this._data = TrackingDomainStatusMap;
        }
        gridList(params) {
            return this._httpClient
                .post(`${this.endpointUrl}/list`, params, {
                observe: 'response',
            })
                .pipe(map((response) => {
                response.body['data'] = _map(response.body['data'], (item) => {
                    return Object.assign(Object.assign({}, item), { status_label: this.getLabel(item.status), statusClass: this._getStatusClass(item.status) });
                });
                return response;
            }));
        }
        post(body, options) {
            return this._httpClient.post(this.endpointUrl, body, options).pipe(pluck('data'));
        }
        put(id, body, options) {
            return this._httpClient.put(`${this.endpointUrl}/${id}`, body, options).pipe(pluck('data'));
        }
        save(body, options) {
            return body.id ? this.put(body.id, body, options) : this.post(body, options);
        }
        get(id, options) {
            return this._httpClient.get(`${this.endpointUrl}/${id}`, options).pipe(pluck('data'));
        }
        getLabel(key) {
            const k = this._data.get(key);
            return k ? this._translate.instant(k) : k;
        }
        _getStatusClass(status) {
            switch (status) {
                case TrackingDomainStatus.Active:
                    return 'text-success';
                case TrackingDomainStatus.Deactivated:
                    return 'text-danger';
                default:
                    return '';
            }
        }
    }
    TrackingDomainsService.ɵfac = function TrackingDomainsService_Factory(t) { return new (t || TrackingDomainsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DataSourceHandlerService), i0.ɵɵinject(i3.TranslateService)); };
    TrackingDomainsService.ɵprov = i0.ɵɵdefineInjectable({ token: TrackingDomainsService, factory: TrackingDomainsService.ɵfac });
    return TrackingDomainsService;
})();

