import { WarningSnackBarComponent } from '../components/warning-snack-bar/warning-snack-bar.component';
import { RemoveSnackBarComponent } from '../components/remove-snack-bar/remove-snack-bar.component';
import { ErrorSnackBarComponent } from '../components/error-snack-bar/error-snack-bar.component';
import { InfoSnackBarComponent } from '../components/info-snack-bar/info-snack-bar.component';
import { AccentSnackBarComponent } from '../components/accent-snack-bar/accent-snack-bar.component';
import { SuccessSnackBarComponent } from '../components/success-snack-bar/success-snack-bar.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export let SnackbarService = /*@__PURE__*/ (() => {
    class SnackbarService {
        constructor(_snackBar) {
            this._snackBar = _snackBar;
            this._durationInMs = 5000;
        }
        error(title, text, duration) {
            this._showSnackBar(title, text, ErrorSnackBarComponent, duration);
        }
        success(title, text, duration) {
            this._showSnackBar(title, text, SuccessSnackBarComponent, duration);
        }
        info(title, text, duration) {
            this._showSnackBar(title, text, InfoSnackBarComponent, duration);
        }
        warning(title, text, duration) {
            this._showSnackBar(title, text, WarningSnackBarComponent, duration);
        }
        accent(title, text, duration) {
            this._showSnackBar(title, text, AccentSnackBarComponent, duration);
        }
        remove(title, text, duration) {
            this._showSnackBar(title, text, RemoveSnackBarComponent, duration);
        }
        _showSnackBar(title, text, internalComponent, duration) {
            this._snackBar.openFromComponent(internalComponent, {
                duration: duration || this._durationInMs,
                data: {
                    title: title,
                    message: text,
                },
                verticalPosition: 'top',
                horizontalPosition: 'right',
            });
        }
    }
    SnackbarService.ɵfac = function SnackbarService_Factory(t) { return new (t || SnackbarService)(i0.ɵɵinject(i1.MatSnackBar)); };
    SnackbarService.ɵprov = i0.ɵɵdefineInjectable({ token: SnackbarService, factory: SnackbarService.ɵfac });
    return SnackbarService;
})();

