import { map, pluck } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { map as _map } from 'lodash-es';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
export var TrackingDomainRuleStatus = /*@__PURE__*/ (function (TrackingDomainRuleStatus) {
    TrackingDomainRuleStatus[TrackingDomainRuleStatus["Deleted"] = -1] = "Deleted";
    TrackingDomainRuleStatus[TrackingDomainRuleStatus["Active"] = 1] = "Active";
    return TrackingDomainRuleStatus;
})({});
export const TrackingDomainRuleStatusMap = new Map([
    [TrackingDomainRuleStatus.Deleted, 'Global.Status.Deleted'],
    [TrackingDomainRuleStatus.Active, 'Global.Status.Active'],
]);
export var IsPrimary = /*@__PURE__*/ (function (IsPrimary) {
    IsPrimary[IsPrimary["NotPrimary"] = 0] = "NotPrimary";
    IsPrimary[IsPrimary["Primary"] = 1] = "Primary";
    return IsPrimary;
})({});
export let TrackingDomainRulesService = /*@__PURE__*/ (() => {
    class TrackingDomainRulesService {
        constructor(_httpClient, _translate) {
            this._httpClient = _httpClient;
            this._translate = _translate;
            this._statusLabelsData = TrackingDomainRuleStatusMap;
            this._endpointUrl = `/tracking-domains/tracking-domain-rules`;
            this._tooltipForActionDelete = this._translate.instant('TrackingDomain.Tooltip.ActionDelete');
        }
        getStatusLabel(status) {
            return this._translate.instant(this._statusLabelsData.get(status));
        }
        setStatusClass(status) {
            switch (status) {
                case TrackingDomainRuleStatus.Active:
                    return 'text-success';
                case TrackingDomainRuleStatus.Deleted:
                    return 'text-danger';
            }
        }
        listByPost(body, options) {
            return this._httpClient
                .post(`${this._endpointUrl}/list`, body, { observe: 'response' })
                .pipe(map((response) => {
                response.body['data'] = _map(response.body['data'], (trackingDomainRule) => {
                    return Object.assign(Object.assign({}, trackingDomainRule), { isDisabled: trackingDomainRule.status === TrackingDomainRuleStatus.Deleted ||
                            trackingDomainRule.campaign_name, tooltip: this._getTooltip(trackingDomainRule), isDisabledActionDelete: trackingDomainRule.is_primary === IsPrimary.Primary, tooltipForActionDelete: trackingDomainRule.is_primary === IsPrimary.Primary ? this._tooltipForActionDelete : '', status_label: this.getStatusLabel(trackingDomainRule.status), statusClass: this.setStatusClass(trackingDomainRule.status) });
                });
                return response;
            }));
        }
        list(queryParams) {
            let params = new HttpParams();
            Object.keys(queryParams).forEach((key) => {
                params = params.append(key, queryParams[key]);
            });
            return this._httpClient.get(`${this._endpointUrl}`, {
                params,
                observe: 'response',
            });
        }
        post(body, options) {
            return this._httpClient.post(`${this._endpointUrl}`, body, options).pipe(pluck('data'));
        }
        put(id, body, options) {
            return this._httpClient.put(`${this._endpointUrl}/${id}`, body, options).pipe(pluck('data'));
        }
        save(body, options) {
            return body.id ? this.put(body.id, body, options) : this.post(body, options);
        }
        get(id, options) {
            return this._httpClient.get(`${this._endpointUrl}/${id}`, options).pipe(pluck('data'));
        }
        delete(id, options) {
            let params = new HttpParams();
            if (options) {
                Object.keys(options).forEach((key) => {
                    params = params.append(key, options[key]);
                });
            }
            return this._httpClient
                .delete(`${this._endpointUrl}/${id}`, { params })
                .pipe(map((r) => r['success'] === true));
        }
        setAsPrimary(id) {
            return this._httpClient.patch(`${this._endpointUrl}/${id}/set-as-primary`, {});
        }
        _getTooltip(trackingDomainRule) {
            const tooltipForDeleted = this._translate.instant('TrackingDomain.Tooltip.Deleted');
            const tooltipForNotAny = this._translate.instant('TrackingDomain.Tooltip.NotAny');
            if (trackingDomainRule.status === TrackingDomainRuleStatus.Deleted) {
                return tooltipForDeleted;
            }
            else if (trackingDomainRule.campaign_name) {
                return tooltipForNotAny;
            }
            return '';
        }
    }
    TrackingDomainRulesService.ɵfac = function TrackingDomainRulesService_Factory(t) { return new (t || TrackingDomainRulesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService)); };
    TrackingDomainRulesService.ɵprov = i0.ɵɵdefineInjectable({ token: TrackingDomainRulesService, factory: TrackingDomainRulesService.ɵfac });
    return TrackingDomainRulesService;
})();

