import { RegistrationTypePermissions } from '../../../../../../data-access/core/src/lib/+state/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "@ho/libs/shared/services/permissions.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../../ui/directives/src/lib/mobile-tooltip-handler/mobile-tooltip-handler.directive";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "../../../../../qa-attribute-widget/src/lib/directives/qa-attribute-widget.directive";
import * as i8 from "@angular/material/checkbox";
import * as i9 from "@ngx-translate/core";
function PermissionsBlockComponent_label_4_Template(rf, ctx) {
    if (rf & 1) {
        i0.ɵɵelementStart(0, "label", 4);
        i0.ɵɵpipe(1, "translate");
        i0.ɵɵelement(2, "i", 5);
        i0.ɵɵelementEnd();
    }
    if (rf & 2) {
        const ctx_r0 = i0.ɵɵnextContext();
        i0.ɵɵproperty("matTooltipClass", "tooltip tooltip_above tooltip_dark tooltip_big tooltip_text-left")("matTooltip", i0.ɵɵpipeBind1(1, 3, ctx_r0.block == null ? null : ctx_r0.block.tooltip))("hoQaAttributeWidget", "tooltip_permission");
    }
}
function PermissionsBlockComponent_ng_container_6_Template(rf, ctx) {
    if (rf & 1) {
        i0.ɵɵelementContainerStart(0);
        i0.ɵɵelementStart(1, "div", 6);
        i0.ɵɵelementStart(2, "mat-checkbox", 7);
        i0.ɵɵtext(3);
        i0.ɵɵpipe(4, "translate");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementContainerEnd();
    }
    if (rf & 2) {
        const permission_r2 = ctx.$implicit;
        const ctx_r1 = i0.ɵɵnextContext();
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("hidden", permission_r2.key === "UI_UserOperations_Approve" && !ctx_r1.hasPermissionUIRegistrationTypeModerated);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("formControlName", permission_r2.key)("hoQaAttributeWidget", "checkbox_permission");
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 4, permission_r2.name), "");
    }
}
export let PermissionsBlockComponent = /*@__PURE__*/ (() => {
    class PermissionsBlockComponent {
        constructor(_permissionsService, _cdr) {
            this._cdr = _cdr;
            const [hasPermission] = _permissionsService.hasPermissions([RegistrationTypePermissions.Moderated]);
            this.hasPermissionUIRegistrationTypeModerated = hasPermission;
        }
        ngOnInit() {
            setTimeout(() => {
                this._cdr.detectChanges();
            }, 2000);
        }
    }
    PermissionsBlockComponent.ɵfac = function PermissionsBlockComponent_Factory(t) { return new (t || PermissionsBlockComponent)(i0.ɵɵdirectiveInject(i1.PermissionsService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
    PermissionsBlockComponent.ɵcmp = i0.ɵɵdefineComponent({ type: PermissionsBlockComponent, selectors: [["ho-permissions-block"]], inputs: { permissionForm: "permissionForm", block: "block" }, decls: 7, vars: 6, consts: [[1, "round-item__title", "round-item__title_small"], ["matTooltipPosition", "right", "mobileTooltipHandler", "", 3, "matTooltipClass", "matTooltip", "hoQaAttributeWidget", 4, "ngIf"], ["fxLayout", "row wrap", "fxLayoutAlign", "flex-start flex-start", "fxLayoutGap", "15px", "fxLayoutGap.lt-sm", "20px", 3, "formGroup"], [4, "ngFor", "ngForOf"], ["matTooltipPosition", "right", "mobileTooltipHandler", "", 3, "matTooltipClass", "matTooltip", "hoQaAttributeWidget"], [1, "icon-question-circle", "tooltip__icon"], [3, "hidden"], [1, "permission-checkbox", 3, "formControlName", "hoQaAttributeWidget"]], template: function PermissionsBlockComponent_Template(rf, ctx) {
            if (rf & 1) {
                i0.ɵɵelementStart(0, "div");
                i0.ɵɵelementStart(1, "div", 0);
                i0.ɵɵtext(2);
                i0.ɵɵpipe(3, "translate");
                i0.ɵɵtemplate(4, PermissionsBlockComponent_label_4_Template, 3, 5, "label", 1);
                i0.ɵɵelementEnd();
                i0.ɵɵelementStart(5, "div", 2);
                i0.ɵɵtemplate(6, PermissionsBlockComponent_ng_container_6_Template, 5, 6, "ng-container", 3);
                i0.ɵɵelementEnd();
                i0.ɵɵelementEnd();
            }
            if (rf & 2) {
                i0.ɵɵadvance(2);
                i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 4, ctx.block == null ? null : ctx.block.title), " ");
                i0.ɵɵadvance(2);
                i0.ɵɵproperty("ngIf", ctx.block == null ? null : ctx.block.tooltip);
                i0.ɵɵadvance(1);
                i0.ɵɵproperty("formGroup", ctx.permissionForm);
                i0.ɵɵadvance(1);
                i0.ɵɵproperty("ngForOf", ctx.block.permissions);
            }
        }, directives: [i2.NgIf, i3.DefaultLayoutDirective, i3.DefaultLayoutAlignDirective, i3.DefaultLayoutGapDirective, i4.NgControlStatusGroup, i4.FormGroupDirective, i2.NgForOf, i5.MobileTooltipHandlerDirective, i6.MatTooltip, i7.QaAttributeWidgetDirective, i8.MatCheckbox, i4.NgControlStatus, i4.FormControlName], pipes: [i9.TranslatePipe], styles: [""] });
    return PermissionsBlockComponent;
})();

